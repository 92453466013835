import { YMaps, Polygon, Map, Placemark } from "@pbe/react-yandex-maps"
import { Card, Col, Container, Row } from "react-bootstrap"
import { Link } from "react-router-dom"

export const Delivery = () => {
    return (
        <Container>
            <h3>Оплат и доставка</h3>
            <h4>Оплата:</h4>
            <p>
                <strong>1) Наличными или переводом на карту при получении заказа </strong><br />
                - Оплата производится курьеру, доставившему Ваш заказ <br />
                - Переводом по номеру телефона на номер, который вам сообщит менеджер при подтверждении заказа<br />
                - Предоплата 50%*
            </p>
            <p>
                <strong>2) На сайте онлайн</strong><br />
                - После подтверждения заказа менеджером, Вам будет выслана ссылка на наш сайт, где вы сможете убедиться в правильности подтвержденного заказа и оплатить заказ
            </p>
            {/* <p>Заказ вы может оплатить с использованием банковской карты на сайте или при получении.</p> */}
            <h4>Доставка:</h4>

            <p>
                <b>Доставка:</b>
                <br />
                Минимальная сумма заказа от 2000р. Интервал доставки 2 часа.
                <br />
                Ознокомиться со стоимостями и зоны доставки можно на <a target="_blank" href={'https://yandex.ru/maps/2/saint-petersburg/?ll=29.700263%2C60.039799&mode=usermaps&source=constructorLink&um=constructor%3Ac28245fb5f81d4c4d9f8ab552c8138df5dcc5976780865af3e10c24abf55ae44&z=10'}>
                    карте
                </a>
            </p>
            <p>
                <b>Возможные доплаты:</b>
                <br />

                Доставка к точному времени +500р к стоимости заказа.
                У вас объемный заказ, потребуется грузовая машина ( не зависимо доставка в пределах КАД или за КАД)
            </p>
            {/* <p>
                <b>Доставка за пределы КАД:</b>
                <br />
                Платная, рассчитывается индивидуально и зависит от точного адреса получателя.
                Заказы перевозятся в специальных транспортировочных пакетах.
            </p> */}
            <p>
                <b><u>Самовывоза нет</u></b>
            </p>

            <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Ac28245fb5f81d4c4d9f8ab552c8138df5dcc5976780865af3e10c24abf55ae44&amp;source=constructor" width="100%" height="411" frameborder="0"></iframe>
        </Container>
    )
}