import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { ThreeDots } from 'react-bootstrap-icons';
import { OrderStatus } from "./OrderStatus";
import { format } from "date-fns";

export const OrdersList = ({ orders, totalPages, navigate, pageIndex, setPageIndex }) => {
    return (
        <div>
            <div className="table-responsive">
                <table className="w-100 table table-hover">
                    <thead>
                        <tr>
                            <th>Заказ</th>
                            <th>Дата доставки</th>
                            <th>Покупатель</th>
                            <th style={{
                                verticalAlign: "middle",
                                textAlign: "center"
                            }}>Статус</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders && orders.length > 0 ?
                            orders.map(order =>
                                <tr key={order.id}>
                                    <td style={{
                                        verticalAlign: "middle",
                                        // textAlign: "center"
                                    }}>
                                        <div>#{order.id}</div>
                                        <div>{order.orderDate}</div>
                                    </td>
                                    <td>{format(new Date(order.deliveryDate), "dd.MM.yy HH:mm")}</td>
                                    <td>
                                        <div>{order.client.lastName} {order.client.firstName} </div>
                                        <div className="text-muted">{order.client.email}</div>
                                    </td>
                                    <td style={{
                                        verticalAlign: "middle",
                                        textAlign: "center"
                                    }}>
                                        <div><OrderStatus status={order.status} /></div>
                                    </td>

                                    <td style={{
                                        verticalAlign: "middle",
                                        textAlign: "center"
                                    }}>
                                        <UncontrolledDropdown>
                                            <DropdownToggle color="light" >
                                                <ThreeDots className='' size='14' />
                                            </DropdownToggle>
                                            <DropdownMenu container="body">
                                                <DropdownItem onClick={() => navigate('details/' + order.id)}>Подробнее</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </td>
                                </tr>
                            )
                            : <tr><td colSpan={5} className={"text-center"}>Список пуст</td></tr>
                        }
                    </tbody>
                </table>
            </div>
            <div className="d-flex flex-row justify-content-center align-content-center mt-3 mb-3">
                <div className="btn-group">
                    <button
                        className={pageIndex == 1 ? `btn btn-primary disabled` : `btn btn-primary`}
                        onClick={() => {
                            if (pageIndex > 1) {
                                setPageIndex(pageIndex - 1)
                            }
                        }}>Назад</button>
                    <button
                        className={(pageIndex == totalPages || totalPages == 0) ? `btn btn-primary disabled` : `btn btn-primary`}
                        onClick={
                            () => {
                                if (pageIndex <= totalPages) {
                                    setPageIndex(pageIndex + 1)
                                }
                            }}
                    >Вперед</button>
                </div>
            </div>
        </div>
    )
}