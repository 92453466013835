import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Container } from "reactstrap";
import { ProductAdd } from "./ProductAdd";
import LoadScreen from "../../UI/LoadScreen";
import { ProductList } from "./ProductsList";
import { useGetCategoriesSelectQuery, useGetProductsQuery } from "../../../features/CallApi";
import { FloatingLabel, Form } from "react-bootstrap";
import axios from "axios";

export const Products = ({ token }) => {
    const navigate = useNavigate();
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [selCat, setSelCat] = useState("");
    const [copyProduct, setCopyProduct] = useState()
    // const [totalPages, setTotalPages] = useState()
    const [stringFilter, setStringFilter] = useState("");
    const [filtering, setFiltering] = useState();
    const [fetching, setFetching] = useState(false);
    const [data, setData] = useState([]);
    const { isLoading, data: dataResponse, refetch, error, isFetching, } = useGetProductsQuery({ pageIndex, pageSize, stringFilter, selCat });
    const { isLoading: loadingCat, data: productParams, refetch: refetchParams } = useGetCategoriesSelectQuery({});

    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        const index = searchParams.get("pageNum")
        if (index) {
            setPageIndex(parseInt(index))
        }
        refetchParams();
        setData([])
        setTimeout(() => {
            refetch()
        }, 500)
    }, [])

    useEffect(() => {
        console.log(searchParams)
        if (pageIndex > 1) {
            setSearchParams(`pageNum=${pageIndex}`)
            console.log(pageIndex)
            sessionStorage.setItem("lastPageNumProducts", pageIndex)
        }
        else {
            setSearchParams()
            sessionStorage.removeItem("lastPageNumProducts")
        }
    }, [pageIndex])

    // useEffect(() => {
    //     console.log(data)
    //     if (data.length > 0) {
    //         setData([...data, ...dataResponse.getProducts])
    //         console.log("Add data")
    //     } else if (dataResponse && !isLoading) {

    //         setData(dataResponse.getProducts)
    //     }
    //     setFetching(false)
    //     setFiltering(false)
    // }, [dataResponse])

    // useEffect(() => {
    //     if (dataResponse && !fetching) {
    //         const scrollHandeler = (e) => {
    //             if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 15 && pageIndex < dataResponse.totalPages) {
    //                 console.log("loading...")
    //                 setFetching(true)
    //                 setPageIndex(pageIndex => pageIndex + 1)
    //             }
    //         }
    //         document.addEventListener('scroll', scrollHandeler)
    //         return function () {
    //             document.removeEventListener('scroll', scrollHandeler)
    //         }

    //     }
    // }, [dataResponse, fetching])


    useEffect(() => {
        console.log((isLoading == false && setFiltering == true))
        if ((isLoading == false && setFiltering == true)) {
            setFiltering(false)
        }
    }, [isLoading])

    const refetchAftPost = () => {
        setData([])
        refetch()
    }

    const exportToExcel = () => {
        fetch(`/api/products/exportproductsexcel`, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }).then(response => response.blob())
            .then(blob => {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = "ProductList.xlsx";
                link.click();
            })
            .catch(console.error);
    }

    useEffect(() => { console.log(copyProduct) }, [copyProduct])
    return (
        <Container fluid={"lg"}>
            <h3>Товаров</h3>
            <Button color="success" className="mb-3" size="sm" onClick={() => { exportToExcel() }}>Скачать в Excel</Button>
            <ProductAdd refetch={refetchAftPost} refetchParams={refetchParams} copyProduct={copyProduct} setCopyProduct={setCopyProduct} />
            {error ?
                <div></div>
                :
                <div>
                    <FloatingLabel controlId="floatingInput" label="Поиск.." className="mb-3">
                        <Form.Control onChange={
                            (e) => {
                                setFiltering(true)
                                setData([])
                                setTimeout(() => {
                                    setStringFilter(e.target.value)
                                    setPageIndex(1)
                                }, 1000)
                            }} type="text" placeholder="Поиск.." />
                    </FloatingLabel>
                    {isLoading ?
                        <LoadScreen />
                        :
                        <div>
                            {!loadingCat &&
                                <FloatingLabel controlId="floatingSelect" className="mb-3" label="Фильтр по категория">
                                    <Form.Select defaultValue={null} onChange={(e) => {
                                        setData([])
                                        setPageIndex(1)
                                        setSelCat(e.target.value)
                                    }} className="form-select" placeholder={`Выберите категорию`} aria-label="Floating label select example">
                                        <option value={""}>--- Категория не выбрана ---</option>
                                        {productParams ?
                                            productParams.map(category =>
                                                <option key={category.id} value={category.id}>{category.name} {category.parentCategoryName && "(" + category.parentCategoryName + ")"}</option>
                                            ) : <option disabled>Нет доступных категорий</option>}
                                    </Form.Select>
                                </FloatingLabel>
                            }
                            {dataResponse?.getProducts &&
                                <ProductList refetch={refetch} pageIndex={pageIndex} setPageIndex={setPageIndex} navigate={navigate} products={dataResponse?.getProducts} fetching={fetching} totalPages={dataResponse.totalPages} setCopyProduct={setCopyProduct} />
                            }
                        </div>
                    }
                    {fetching &&
                        <LoadScreen height={35} />
                    }
                </div>
            }
        </Container>
    )
}