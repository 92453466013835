import React, { useRef } from "react"
import { useParams, useNavigate } from 'react-router-dom';
import { Container } from 'reactstrap'
import { useForm } from "react-hook-form"
import LoadScreen from "../../UI/LoadScreen";
import { useChengePasswordUserMutation, useGetUserByIdQuery } from "../../../features/CallApi";

export const UserChangePassword = () => {
    const { id } = useParams();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { data: userData, isError, error,isLoading: getingDate } = useGetUserByIdQuery(id)

    const [chengePassword, { isLoading: isUpdating }] = useChengePasswordUserMutation();
    const password = useRef({});
    const navigate = useNavigate();

    const onSubmit = (data) => {
        chengePassword(data).unwrap().then(navigate('/admin/users')).catch((error) => console.log(error))
    }


    return (
        <Container>
            {(isUpdating || getingDate) ? <LoadScreen></LoadScreen> : <div>
                {isError && <h4>Ошибка запроса: {error.status} (код: {error.originalStatus})</h4>}
                {getingDate ? <LoadScreen /> :
                    <form className="" onSubmit={handleSubmit(onSubmit)}>
                        <h4>Смена пароля для {userData.email}</h4>
                        <h5>{userData.firstname} {userData.lastname}</h5>
                        <input hidden value={id} {...register("id", {})} />
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">Новый пароль</label>
                            <input id="password" name="password" placeholder="Укажите Пароль" type="password" className="form-control"
                                {...register("password", {
                                    required: { value: true, message: "Поле \"Пароль\" не может быть пустым" },
                                    minLength: { value: 8, message: "Длина пароля должна быть не менее 8 символов" }
                                })} autoComplete="off" />
                            {errors.password && <span className='text-danger'>{errors.password?.message}</span>}
                        </div>
                        {/* <div className="mb-3">
                        <label htmlFor="repleypassword" className="form-label">confirmPassword</label>
                        <input id="repleypassword" name="repleypassword" placeholder="Укажите Пароль" type="password" className="form-control"
                            {...register("repleypassword", {
                                required: { value: value => value == password, message: "Поле \"Пароль\" не может быть пустым" }
                            })} autoComplete="off" />
                        {errors.repleypassword && <span className='text-danger'>{errors.repleypassword?.message}</span>}
                    </div> */}
                        <div className="mb-3">
                            <div role="group" className="btn-group-sm btn-group">
                                <input className="btn btn-success btn btn-secondary" type="submit" value={'Сменить пароль'} />
                                <button type="button" className="btn btn-secondary btn btn-secondary" onClick={() => navigate('/admin/users')}>Назад</button>
                            </div>
                        </div>
                    </form>
                }
            </div>}
        </Container>
    )
}
// export default UserChangePassword;