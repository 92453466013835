import React, { Component } from 'react';
import { Clock, Telegram, TelephoneFill, Whatsapp } from 'react-bootstrap-icons';
import { NavMenu } from './NavMenu';
import { Outlet } from 'react-router-dom';
import { Footer } from './Footer';
import { Basket } from './Orders/Basket';
import { Metrik } from '../features/Metrik';
import { NavBarMobile } from './NavBarMobile';
import { HeaderContactLine } from './UI/HeaderContactLine';

export const LayoutClient = ({ basket, updateBasket, removeItem, resetBasket, addItemStatus, showBasket, setShowBasket, updateItem }) => {
    return (
        <div className='sticky-top'>
            <Metrik />
            <HeaderContactLine />
            <NavMenu />
            <Outlet />
            <Footer />
            <NavBarMobile
                removeItem={removeItem}
                basket={basket}
                updateBasket={updateBasket}
                resetBasket={resetBasket}
                addItemStatus={addItemStatus}
                showBasket={showBasket}
                setShowBasket={setShowBasket}
            />
        </div>
    );
}
