import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import './custom.css';
import useToken from './features/useToken'
import LoadScreen from './components/UI/LoadScreen'


import { AdminLayout } from './components/Admin/AdminLayout';
import { AdminHome } from './components/Admin/AdminHome';

import { Layout } from './components/Layout';
import { Home } from './components/Home';

import { UserLogin } from './components/Admin/Users/UserLogin';
import { Logout } from './features/Logout';
import { Users } from './components/Admin/Users/Users';
import { UserEdit } from './components/Admin/Users/UserEdit';
import { UserChangePassword } from './components/Admin/Users/UserChangePassword';
import { UserDetails } from './components/Admin/Users/UserDetails';
import { Categories } from './components/Admin/Products/Categories/Categories';
import { CategoriesEdit } from './components/Admin/Products/Categories/CategoriesEdit';
import { Products } from './components/Admin/Products/Products';
import { ProductEdit } from './components/Admin/Products/ProductEdit';
import { ProductDetails } from './components/Admin/Products/ProductDetails';
import { ProductCatalog } from './components/Admin/Products/ProductCatalog';
import { Orders } from './components/Admin/Orders/Orders';
import { OrderDetails } from './components/Admin/Orders/OrderDetails'
import { Product } from './components/Product/Product';
import { LayoutCatalog } from './components/LayoutCatalog';
import { Catalog } from './components/Product/Catalog';
import useBasket from './features/useBasket';
import { OrderCreate } from './components/Orders/OrderCreate';
import { LayoutClient } from './components/LayoutClient';
import { Contacts } from './components/Contacts';
import { WarrantyRules } from './components/Home/WarrantyRules';
import { Delivery } from './components/Delivery';
import { About } from './components/Home/About';
import { OrderClientDetails } from './components/Orders/OrderClientDetails';
import { KeywordsList } from './components/Keywords/KeywordsList';



export const App = () => {
  const { token, setTokenData, loadingSite, role, getRefreshToken, fullName } = useToken();
  const { basket, addItem, removeItem, resetBasket, updateBasket, updateItem, addItemStatus, showBasket, setShowBasket } = useBasket();

  const [showCatalog, setShowCatalog] = useState()
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <>
      {/* <Metrik /> */}
      {!loadingSite ?
        <Routes>
          <Route path='/admin' element={<AdminLayout token={token} role={role} getRefreshToken={getRefreshToken} />}>
            <Route index element={<AdminHome fullName={fullName} />} />
            <Route path='users' element={<Users token={token} />} />
            <Route path='users/details/:id' element={<UserDetails />} />
            <Route path='users/edit/:id' element={<UserEdit />} />
            <Route path='users/changepassword/:id' element={<UserChangePassword />} />
            <Route path='categories' element={<Categories token={token} />} />
            <Route path='categories/edit/:id' element={<CategoriesEdit token={token} />} />
            <Route path='products' element={<Products token={token} />} />
            <Route path='products/details/:id' element={<ProductDetails token={token} />} />
            <Route path='products/edit/:id' element={<ProductEdit token={token} />} />
            <Route path='catalog' element={<ProductCatalog />} />
            <Route path='orders' element={<Orders />} />
            <Route path='orders/details/:id' element={<OrderDetails token={token} />} />
            <Route path='keywords' element={<KeywordsList />} />
          </Route>
          <Route path='/' element={
            <Layout
              basket={basket}
              updateItem={updateItem}
              removeItem={removeItem}
              updateBasket={updateBasket}
              resetBasket={resetBasket}
              addItemStatus={addItemStatus}
              showBasket={showBasket}
              setShowBasket={setShowBasket}
              showCatalog={showCatalog}
              setShowCatalog={setShowCatalog}
            />}>
            <Route index element={<Home addItem={addItem} />} title="Главная" />
            <Route path='login' element={<UserLogin setTokenData={setTokenData} />} />
            <Route path='logout' element={<Logout />} />
            <Route path='create-order' element={<OrderCreate basket={basket}
              updateItem={updateItem}
              removeItem={removeItem}
              updateBasket={updateBasket}
              resetBasket={resetBasket} />} />
            <Route path='contacts' element={<Contacts />} />
            <Route path='warranty-rules' element={<WarrantyRules />} />
            <Route path='delivery' element={<Delivery />} />
            <Route path='about' element={<About />} />
            <Route path='orders/details/:id' element={<OrderClientDetails />} />
            <Route path='*' element={<Home addItem={addItem} />} title="Главная" />
          </Route>
          <Route path='/client' element={<LayoutClient
            showCatalog={showCatalog}
            setShowCatalog={setShowCatalog}
          />}>
            <Route path='create-order' element={
              <OrderCreate basket={basket}
                updateItem={updateItem}
                removeItem={removeItem}
                updateBasket={updateBasket}
                resetBasket={resetBasket} />}
              showBasket={showBasket}
              setShowBasket={setShowBasket}
            />
          </Route>
          <Route path='catalog' element={<LayoutCatalog
            basket={basket}
            updateItem={updateItem}
            removeItem={removeItem}
            updateBasket={updateBasket}
            resetBasket={resetBasket}
            addItemStatus={addItemStatus}
            showBasket={showBasket}
            setShowBasket={setShowBasket}
            showCatalog={showCatalog}
            setShowCatalog={setShowCatalog} />}>
            <Route index element={<Catalog addItem={addItem} removeItem={removeItem} />} />
            <Route path='product/:seoUrl' element={<Product addItem={addItem} />} />
          </Route>
        </Routes> :
        <LoadScreen />}
    </>
  );
}

export default App;
