import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "reactstrap";
import { useGetOrdersQuery } from "../../../features/CallApi";
import LoadScreen from "../../UI/LoadScreen";
import { OrdersList } from "./OrdersList";
import { addDays, addYears, format } from "date-fns";
import { Col, Row } from "react-bootstrap";

export const Orders = () => {

    const navigate = useNavigate();
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [stringFilter, setStringFilter] = useState("");
    const [selStatus, setSelStatus] = useState(-1)
    const [startDate, setStartDate] = useState(format(new Date(Date.now()), "yyyy-MM-dd"))
    const [endDate, setEndDate] = useState(format(new Date(addYears(Date.now(), 2)), "yyyy-MM-dd"))
    const { isLoading, data: orders, refetch, error, isFetching } = useGetOrdersQuery({ pageIndex, pageSize, stringFilter, startDate, endDate, selStatus });

    return (
        <Container>
            <h3>Заказы</h3>
            {error ?
                <div>
                    {error && <div>
                        <h1>Error code: {error.status}</h1>
                        <p>{error.data}</p>
                    </div>
                    }
                </div> :
                <div>
                    <div>
                        <Row className="row g-2 mb-2">
                            <Col xs={6} lg={4}>
                                <div>
                                    <div className="form-floating">
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="startDate"
                                            name="startDate"
                                            onChange={
                                                (e) => {
                                                    setStartDate(e.target.value)
                                                }
                                            }
                                            defaultValue={startDate} />
                                        <label htmlFor="startDate">С</label>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={6} lg={4}>
                                <div>
                                    <div className="form-floating">
                                        <input
                                            type="date"
                                            className="form-control"
                                            id="endDate"
                                            name="endDate"
                                            onChange={
                                                (e) => {
                                                    setEndDate(e.target.value)
                                                }
                                            }
                                            defaultValue={endDate} />
                                        <label htmlFor="endDate">По</label>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} lg={4}>
                                <div className="form-floating">
                                    <select className="form-select" onChange={(e) => { setSelStatus(e.target.value) }} id="status" aria-label="Статус заказ">
                                        <option value={-1}>Все заказы</option>
                                        <option value={0}>Новый заказ</option>
                                        <option value={7}>Подтвержден</option>
                                        <option value={1}>В процессе оплаты</option>
                                        <option value={2}>Оплачен</option>
                                        <option value={5}>В процессе возврата</option>
                                        <option value={6}>Возаращен</option>
                                        <option value={8}>Завершен</option>
                                    </select>
                                    <label htmlFor="typeDelivery">Статус заказ</label>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="d-flex">
                        <input type={`text`} id="stringFilter" onChange={
                            (e) => setTimeout(() => { setStringFilter(e.target.value) }, 2000)}
                            placeholder="Поиск.." name="stringFilter" className="form-control" />
                    </div>
                    <div>
                        {isLoading ?
                            <LoadScreen></LoadScreen> :
                            <OrdersList pageIndex={pageIndex} setPageIndex={setPageIndex} navigate={navigate} orders={orders.getOrder} totalPages={orders.totalPages} />
                        }
                    </div>
                </div>
            }
        </Container>
    )
}