import { useEffect, useState } from 'react';
import {
  Container,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Nav,
  NavLink,
  NavItem,
} from 'reactstrap';
import { Outlet, useNavigate, Link } from 'react-router-dom';
import { AdminNavMenu } from './AdminNavMenu';

export const AdminLayout = ({ role, token }) => {
  const navigate = useNavigate();
  const [offcanvasIsOpen, setOffcanvasIsOpen] = useState(false);
  useEffect(() => {
    if (!token) {
      navigate('/login')
    }
  }, [token])


  return (
    <div>
      <AdminNavMenu role={role} offcanvasIsOpen={offcanvasIsOpen} setOffcanvasIsOpen={setOffcanvasIsOpen} />
      <Container fluid>
        <div>
          <Offcanvas
            isOpen={offcanvasIsOpen}
            toggle={() => { setOffcanvasIsOpen(!offcanvasIsOpen) }}
          >
            <OffcanvasHeader className='border-bottom' toggle={() => { setOffcanvasIsOpen(!offcanvasIsOpen) }}>
              Системное меню
            </OffcanvasHeader>
            <OffcanvasBody>
              <Nav vertical>
                {(role == "Admins") &&
                  <NavItem>
                    <NavLink tag={Link} className="text-dark" onClick={() => {
                      setOffcanvasIsOpen(false)
                    }} to={'/admin/users'}>Пользователи</NavLink>
                  </NavItem>
                }
              </Nav>
            </OffcanvasBody>
          </Offcanvas>
        </div>
        <Outlet />
      </Container>
      <footer className='text-center'>
        <p>By @Maxim Borodin {new Date().getFullYear()} ©</p>
      </footer>
    </div>
  );
}
