
export const TypeOfPay = ({typeOfPay}) => {
    let result;
    switch (typeOfPay) {
        case 0: result = <span className="text-success">Банковской картой на сайте</span>;
        break;
        case 1: result = <span className="text-success">Наличными</span>;
        break;
        case 2: result = <span className="text-success">Перевод на карту</span>;
        break;
        case 3: result = <span className="text-success">Банковский перевод</span>;
        break;
        case null: result = <span>Не выбран</span>
        break;
        default: result = <span>Некорректные данные</span>
    }
    return result;
} 