import { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { UsersList } from "./UsersList";
import { FormAddUser } from './FormAddUser';
import LoadScreen from "../../UI/LoadScreen";
// import { useNavigate } from "react-router-dom";
import { useGetUsersQuery } from "../../../features/CallApi";

const Users = ({token}) => {
    // const navigate = useNavigate();
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [stringFilter, setStringFilter] = useState("");
    const { isLoading, data, refetch, error, isFetching } = useGetUsersQuery({ pageIndex, pageSize, stringFilter });
    useEffect(() => {
        refetch()
    }, [])

    return (

        <Container>
            {isLoading ? <LoadScreen></LoadScreen> :
                <div>
                    <div>
                        <FormAddUser refeach={refetch} token={token} />
                        <div>
                            <div className="d-flex">
                                {/* <label htmlFor="stringFilter" className="form-label">E-Mail</label> */}
                                <input type={`text`} id="stringFilter" onChange={
                                    (e) => setTimeout(() => { setStringFilter(e.target.value) }, 2000)}
                                    placeholder="Поиск.." name="stringFilter" className="form-control" />
                            </div>
                            {isFetching ? <LoadScreen></LoadScreen> :
                                <UsersList users={data?.getUsers} title="Список пользователей" />
                            }
                            <div className="d-flex flex-row justify-content-center align-content-center mt-3">
                                <div className="btn-group">
                                    <button
                                        className={pageIndex == 1 ? `btn btn-primary disabled` : `btn btn-primary`}
                                        onClick={() => {
                                            if (pageIndex > 1) {
                                                setPageIndex(pageIndex - 1)
                                            }
                                        }}>Назад</button>
                                    <button
                                        className={pageIndex == data?.totalPages ? `btn btn-primary disabled` : `btn btn-primary`}
                                        onClick={
                                            () => {
                                                if (pageIndex <= data?.totalPages) {
                                                    setPageIndex(pageIndex + 1)
                                                }
                                            }}
                                    >Вперед</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </Container>

    );
}


export { Users };