import { Map, Placemark, YMaps } from "@pbe/react-yandex-maps"
import { Card, Col, Container, Row } from "react-bootstrap"
import { EnvelopeAtFill, GeoAlt, Telegram, TelephoneFill, Whatsapp } from "react-bootstrap-icons"


export const Contacts = () => {
    return (
        <Container>
            <h2 className="mt-3 mb-3 h2">Контакты</h2>
            <div>
                <p className="fs-4">
                    «balloon.spb» доставляем воздушные шары на любое мероприятие!
                </p>
                <p>
                    Выбирайте и заказывайте воздушные шары с доставкой на нашем сайте или через социальные сети.
                    Если не найдёте нужный товар, наш мастер создаст для вас уникальную композицию, соответствующую вашим пожеланиям.
                    В ассортименте интернет магазина представлены воздушные шары, товары для праздника.
                    Мы используем только высококачественные и безопасные материалы, включая латексные биоразлагаемые шары.
                    Каждый шарик обрабатывается средством для увеличения времени полёта. Наша доставка быстрая, надёжная и удобная. Вы можете выбрать время доставки утром, днём или вечером. По запросу мы доставляем заказы даже ночью. Мы всегда рады ответить на ваши вопросы и помочь выбрать идеальное оформление для вашего праздника.
                </p>
            </div>
            <Row className="mt-3 mb-3">
                <Col>
                    <Card className="shadow mb-3">
                        <Card.Body>
                            <h5>Телефон:</h5>
                            <div className="mb-3"><TelephoneFill className='me-2' size={20} /> <a className="link-offset-2 link-underline link-underline-opacity-0" href="tel:+7(921)7752571" style={{ fontSize: "20px" }}>+7 (921) 775 2571</a></div>
                            <h5>Мессенджеры:</h5>
                            <div className="mb-3">
                                <div> <a className='text-decoration-none align-self-center header-link' href='https://wa.me/+79217752571'><Whatsapp size={20} className='me-2' fill="#499e00" />WhatsApp</a></div>
                                <div><a className='text-decoration-none align-self-center header-link' href='https://t.me/balloon_spb'><Telegram size={20} className='me-2' fill="#5395df" />Telegram</a></div>
                            </div>
                            <h5>Электронный адрес:</h5>
                            <div><EnvelopeAtFill fill="#ffe786" className='me-2' size={20} /> <a href="mailto:order@deltaplanes.ru">order@deltaplanes.ru</a></div>
                            <h5>Адрес:</h5>
                            <div><GeoAlt fill="#ffaf71" size={20} />г. Санкт-Петербург, ул. Композиторов 26/3
                                <br />
                                (Шоурума нет, мы предоставляем только услугу доставки.)
                            </div>
                            <h5>Реквизиты:</h5>
                            <div>
                                Самозанятый: Бородина Анастасия Марковна
                                <br />
                                ИНН: 780537300633
                                <br />
                                <div><EnvelopeAtFill fill="#ffe786" className='me-2' size={20} /> <a href="mailto:order@deltaplanes.ru">order@deltaplanes.ru</a></div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={6}>
                    <div className="shadow rounded">
                        <YMaps className={"rounded"} query={{
                            apikey: '25afe4ef-ff3b-437d-9674-c1c176e1ca14',
                            lang: 'ru_RU'
                        }}>
                            <Map defaultState={{
                                center: [60.062934, 30.318572],
                                zoom: 15
                            }} width={"100%"} height={"50vh"}>
                                <Placemark
                                    options={{
                                        iconShadowLayout: true,
                                        openHintOnHover: true,
                                        iconLayout: 'default#image',
                                        iconImageHref: '/ballon_logo.png',
                                        iconImageSize: [87, 55],
                                        iconImageOffset: [-20, -40],
                                        // Defining a hotspot on top of the image.
                                        iconShape: {
                                            type: 'Circle',
                                            coordinates: [0, 0],
                                            radius: 20
                                        },
                                    }}
                                    properties={{}}
                                    geometry={[60.062934, 30.318572]} />
                            </Map>
                        </YMaps>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}