import { Table } from "react-bootstrap"
import { ThreeDots } from "react-bootstrap-icons"
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"



export const Variations = ({ product, setVarEditShow, setVarState, deleteVariation, refetch }) => {
    return (
        <div>
            <Table striped hover>
                <thead>
                    <tr>
                        <th>
                            Наименование
                        </th>
                        <th>
                            Цена
                        </th>
                        <th>

                        </th>
                    </tr>
                </thead>
                <tbody>
                    {product.variations.map(item =>
                        <tr key={item.id}>
                            <td>{item.name}</td>
                            <td>{item.price} руб.</td>
                            <td xs='auto' style={{
                                verticalAlign: "middle",
                                textAlign: "center"
                            }}>
                                <UncontrolledDropdown>
                                    <DropdownToggle color="light" >
                                        <ThreeDots className='' size='14' />
                                    </DropdownToggle>
                                    <DropdownMenu container="body">
                                        <DropdownItem onClick={() => {
                                            setVarState(item)
                                            setVarEditShow(true)
                                        }}>Изменить</DropdownItem>
                                        <DropdownItem onClick={() => {
                                            // alert()
                                            deleteVariation(item.id).then(()=>{refetch()})
                                        }}>
                                            Удалить
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </td>
                        </tr>
                    )}

                </tbody>
            </Table>

        </div>
    )
}