import { useEffect, useState } from "react"
import { Col, Form, Image, InputGroup, Nav, NavDropdown, Navbar, Offcanvas, Row } from "react-bootstrap"
import { Cart2, House, List, Search } from "react-bootstrap-icons"
import { useNavigate } from "react-router-dom"
import { useGetCategoriesCatalogQuery, useGetProductsCatalogQuery } from "../features/CallApi"


const SearchInput = ({ showSearch, setShowSearch, toNav }) => {

    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [stringFilter, setStringFilter] = useState("");
    const { isLoading, data, refetch, error, fetching } = useGetProductsCatalogQuery({ pageIndex, pageSize, stringFilter });

    useEffect(() => {
        setStringFilter(null)
    }, [showSearch])

    return (
        <>
            {/* <div className="position-absolute" style={{ backgroundColor: "#000000a6", top: 0, bottom: 0, left: 0, right: 0, overflowX: "hidden", overflowY: "auto" }}>

            </div>
            <div className={`fixed-bottom ${!showSearch && "d-none"}`}>
                <div className="position-absolute">

                </div>
                <div className="position-absolute bg-white p-2 m-2 rounded" style={{ bottom: "65px", left: 0, right: 0 }}>
                    <Form>
                        <Form.Label>Поиск по каталогу</Form.Label>
                        <InputGroup>
                            <Form.Control></Form.Control>
                        </InputGroup>
                    </Form>
                </div>
            </div> */}
            <Offcanvas show={showSearch} onHide={() => { setShowSearch(!showSearch) }} placement="bottom"
                className="bg-white p-2 m-2 rounded" style={{ bottom: 65, height: 'auto', maxHeight: "65vh" }}
            >
                <Offcanvas.Body>
                    <Form>
                        <InputGroup>
                            <Form.Control
                                className="mb-2"
                                placeholder="Поиск по каталогу..."
                                onChange={(e) => {
                                    setTimeout(() => {
                                        setStringFilter(e.target.value.toLocaleLowerCase())
                                        setPageIndex(1)
                                    }, 2000)
                                }} />
                        </InputGroup>
                    </Form>
                    {(data && stringFilter) &&
                        <>
                            {data.getProducts?.length > 0 ?
                                <div>
                                    <div className="g-1">
                                        {data.getProducts.map(product =>
                                            <div key={product.id} className="border-bottom mb-2" onClick={() => {
                                                setShowSearch(false)
                                                toNav(`/catalog/product/${product.seoUrl}`)
                                            }}>
                                                <Row>
                                                    <Col xs={"auto"}>
                                                        <div className="me-2" style={{ width: "50px" }}>
                                                            {product.photos.length > 0 ?
                                                                <Image src={`/api/products/photo/${product.photos[0].id}`} fluid style={{ width: "50px" }} />
                                                                :
                                                                <Image src={`/NoPhoto.png`} rounded fluid style={{ width: "50px" }} />
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="text-wrap">
                                                            {product.name}
                                                        </div>
                                                        <div>
                                                            {product.variations.length > 0 ?
                                                                <>{product.variations[0].price} ₽ - {product.variations.at(-1).price} ₽</>
                                                                :
                                                                <>
                                                                    {product.variations.length > 0 ?
                                                                        <>
                                                                            <>{product.variations[0].price} ₽ - {product.variations.at(-1).price} ₽ </>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {product.discount > 0 ?
                                                                                <div className="mt-3">

                                                                                    <span style={{ position: "relative" }}>
                                                                                        <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger">
                                                                                            {((product.discount / product.price) * 100 - 100)} %
                                                                                        </span>
                                                                                        <strike>{product.price} ₽</strike> = </span>
                                                                                    <span style={{ color: "red" }}>{product.discount} ₽</span>
                                                                                    {/* <div><span className="translate-middle badge rounded-pill bg-danger">{(product.discount - product.price)}</span></div> */}
                                                                                </div>
                                                                                :
                                                                                <div>
                                                                                    {product.price} ₽
                                                                                </div>
                                                                            }
                                                                        </>
                                                                    }
                                                                </>
                                                            }
                                                        </div>
                                                        <div className="text-muted fs-6">
                                                            арт. {product.sku}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                :
                                <>
                                    <p className="text-center">По вашему запросу ничего не найдено</p>
                                </>
                            }
                        </>
                    }

                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

const CatalogMenu = ({ show, setShow }) => {

    const handleClose = () => setShow(false);
    const navigate = useNavigate();
    const [selCatId, setSelCatId] = useState()
    // const handleShow = () => setShow(true);
    const { data } = useGetCategoriesCatalogQuery()

    return (
        <Offcanvas show={show} onHide={handleClose} placement="start">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Каталог</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {data &&
                    <Nav activeKey={selCatId} style={{ minWidth: "200px" }} className="flex-column bubbles-v-nav">
                        <Nav.Link
                            onClick={() => {
                                setShow(false)
                                navigate("/catalog")
                                setSelCatId(null)
                            }}
                        >
                            <strong>Весь каталог</strong>
                        </Nav.Link>
                        {data.map(cat =>
                            <Nav.Item key={cat.id}>
                                {cat.categories.length > 0 ?
                                    <NavDropdown id="nav-dropdown-dark-example w-100" title={cat.name}>
                                        <NavDropdown.Item eventKey={cat.id} onClick={() => {
                                            setShow(false)
                                            navigate(`/catalog?selId=${cat.id}`)
                                            setSelCatId(cat.id)
                                        }}>Все <span style={{ textTransform: "lowercase" }} >{cat.name}</span></NavDropdown.Item>
                                        {cat.categories.map(subCat =>
                                            <NavDropdown.Item key={subCat.id} eventKey={subCat.id} onClick={() => {
                                                navigate(`/catalog?selId=${subCat.id}`)
                                                setShow(false)
                                                // setSearchParams({selId: subCat.id})
                                                setSelCatId(subCat.id)
                                            }}>{subCat.name}</NavDropdown.Item>
                                        )}
                                    </NavDropdown>
                                    :
                                    <Nav.Link key={cat.id} eventKey={cat.id} onClick={() => {
                                        setShow(false)
                                        navigate(`/catalog?selId=${cat.id}`)
                                        // setSearchParams({selId: cat.id})
                                        setSelCatId(cat.id)
                                    }}
                                    >{cat.name}</Nav.Link>
                                }
                            </Nav.Item>
                        )}
                    </Nav>
                }
            </Offcanvas.Body>
        </Offcanvas>
    )
}

const NavBarButton = ({ basket, updateBasket, showBasket, setShowBasket, showCatalog, setShowCatalog, summQuantity, toNav, showSearch, setShowSearch }) => {
    return (
        <Navbar fixed="bottom" className={"d-lg-none bg-white pb-3"} variant="white" style={{ borderTop: "1px solid rgb(141 141 141 / 94%)", zIndex: 1041 }}>
            <Nav className={"w-100"} style={{ justifyContent: "space-around" }}>
                <Nav.Link className="text-center p-0" onClick={() => { setShowSearch(!showSearch) }}>
                    <Search className="mobile-icon" size={20} />
                    <div className="mobile-icon-title">Поиск</div>
                </Nav.Link>
                <Nav.Link className="text-center p-0"
                    onClick={() => { setShowCatalog(!showCatalog) }}>
                    <List className="mobile-icon" size={20} />
                    <div className="mobile-icon-title">Каталог</div>
                </Nav.Link>

                <Nav.Link className="text-center p-0"
                    onClick={() => { setShowBasket(!showBasket) }}
                >
                    <div className="position-relative">
                        <Cart2 size={20} />
                        {summQuantity > 0 &&
                            <div className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{summQuantity}</div>
                        }
                        <div className="mobile-icon-title">Корзина</div>
                    </div>
                </Nav.Link>
                {/* <Nav.Link></Nav.Link> */}
                <Nav.Link as="button" className="text-center p-0"
                    onClick={() => { toNav("/") }}
                >
                    <House className="mobile-icon" operator='over'
                        size={20} />
                    <div className="mobile-icon-title">Главная</div>
                </Nav.Link>
            </Nav>
        </Navbar>
    )
}

export const NavBarMobile = ({ basket, updateBasket, showBasket, setShowBasket, showCatalog, setShowCatalog }) => {

    // const [summ, setSumm] = useState(0)
    // const [showCatalog, setShowCatalog] = useState(false);
    const [summQuantity, setSummQuantity] = useState()
    const [showSearch, setShowSearch] = useState(false)
    const navigete = useNavigate()
    const toNav = (link) => {
        window.scrollTo(0, 0)
        navigete(link)
    }
    useEffect(() => {
        // console.log(addItemStatus)
        if (basket) {
            const sum = basket.reduce((summa, item) => {
                return summa + ((item.discount ? parseFloat(item.discount) : parseFloat(item.price)) * item.quantity);
            }, 0);
            // setSumm(sum)
            const quantityBasket = basket.reduce((summQuantity, item) => {
                return summQuantity + item.quantity
            }, 0)
            setSummQuantity(quantityBasket)
            // if (sum >= 1500) {
            //     setSummError(null)
            // }
        }
    }, [basket, updateBasket])

    return (
        <>
            <CatalogMenu show={showCatalog} setShow={setShowCatalog} />
            <SearchInput showSearch={showSearch} setShowSearch={setShowSearch} toNav={toNav} />
            <NavBarButton
                basket={basket}
                updateBasket={updateBasket}
                showBasket={showBasket}
                setShowBasket={setShowBasket}
                showCatalog={showCatalog}
                setShowCatalog={setShowCatalog}
                summQuantity={summQuantity}
                toNav={toNav}
                showSearch={showSearch}
                setShowSearch={setShowSearch} />
        </>
    )
}