import { Container } from "react-bootstrap";
import { useGetProductsCatalogQuery } from "../../features/CallApi";
import { ProductCard } from "../Product/ProductCard";


export const ProductsPromo = ({ addItem }) => {
    const { isLoading, data: dataResponse, refetch, error } = useGetProductsCatalogQuery({ pageIndex: 1, pageSize: 20, selPromo: 1 });


    return (
        <Container>
            {dataResponse &&
                <>
                    {dataResponse.getProducts.length > 0 &&
                        <>
                            <h3>Шары и наборы по акции</h3>
                            <div className="row g-2">

                                {
                                    dataResponse.getProducts.map(product =>
                                        <div key={product.id} className="col-12 col-sm-6 col-lg-6 col-xl-4 col-xxl-3 text-center">
                                            <div>
                                                <ProductCard addItem={addItem} key={product.id} product={product} />
                                            </div>
                                        </div>
                                    )
                                }
                            </div>

                        </>
                    }
                </>
            }
        </Container>
    )
}