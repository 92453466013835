import { Container } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"


export const Footer = () => {

    const navigate = useNavigate()

    return (
        <footer className="border border-top p-mobile-menu">
            <Container className="mt-1">
                <div className="row">
                    <div className="col-md">
                        <img src='/ballon_logo.png' className='img-fluid' style={{ maxHeight: "180px" }} />
                    </div>
                    <div className="col-md">
                        <ul className="nav flex-column bubbles-v-nav">
                            <li className="nav-item">
                                <Link className="nav-link" to={"/catalog"}>
                                    Каталог товаров
                                </Link>
                                {/* <a className="nav-link" style={{ cursor: "pointer" }} onClick={() => {
                                    navigate(("/catalog"))
                                    window.scrollTo(0, 0)
                                }}>Каталог товаров</a> */}
                            </li>
                            {/* <li className="nav-item">
                                <a className="nav-link" href="#">Как заказать</a>
                            </li> */}
                            <li className="nav-item">
                                <Link to={"/delivery"} className="nav-link">
                                    Условия доставки
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={"/warranty-rules"} className="nav-link" >
                                    Гарантия и возврат
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md">
                        <ul className="nav flex-column bubbles-v-nav">
                            <li className="nav-item">
                                <Link to={"/contacts"} className="nav-link">
                                    Контакты
                                </Link>

                            </li>
                            <li className="nav-item">
                                <Link to={"/ppd.pdf"} className="nav-link" target="_blank" >
                                    Политика конфиденциальности
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="text-center mt-3">balloon.spb.ru ©</div>
            </Container>
        </footer >
    )
}