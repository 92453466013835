import { Clock, Telegram, TelephoneFill, Whatsapp } from "react-bootstrap-icons"


export const HeaderContactLine = () => {
    return (
        <div className='sticky-top'>
            <div className='d-flex text-center justify-content-evenly pt-2 pb-2 border-bottom shadow-sm sticky-top bg-light sticky-top'>
                <div className='d-none d-sm-block'> <Clock className='me-1' />10:00 - 22:00</div>
                <div> <a className='text-decoration-none align-self-center header-link' href='tel:+7 (921) 775 2571'><TelephoneFill className='me-1' />+7 (921) 775 2571</a></div>
                <div> <a className='text-decoration-none align-self-center header-link' href='https://wa.me/+79217752571'><Whatsapp className='me-1' /> WhatsApp </a></div>
                <div>
                    <a className='text-decoration-none align-self-center header-link' href='https://t.me/balloon_spb'><Telegram className='me-1' />Telegram
                    </a>
                </div>
            </div>
        </div>
    )
}