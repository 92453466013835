// import axios from "axios"
// import ReactInputMask from "react-input-mask";
import LoadScreen from "../../UI/LoadScreen";
import { useGetProductQuery, useGetCategoriesQuery, usePostProductPhotoMutation, useDeleteProductPhotoMutation, useDeleteVariationMutation, useAddCategoryMutation, useDeleteCategoryMutation, useGetKeywordsQuery, useAddKeywordToProductMutation, useRemoveKeywordToProductMutation } from "../../../features/CallApi";
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { VariationAdd } from './VariationAdd';
import { Variations } from './Variations';
import { VariationEdit } from './VariationEdit';
import { Col, Row, Button, ButtonGroup, Container, Modal } from 'react-bootstrap';
import { X, XCircle } from 'react-bootstrap-icons';
import ReactSelect from "react-select";
import parse from 'html-react-parser';
import imageCompression from 'browser-image-compression';


const Categories = ({ productId, categories, refetch }) => {
    const { isLoading, data, refetch: refetchParams } = useGetCategoriesQuery({ pageSize: 100 });
    const [addCategory, { isLoading: isAddingCategory }] = useAddCategoryMutation()
    const [deleteCategory, { isLoading: isDeleteingCategory }] = useDeleteCategoryMutation()
    const [optionsData, setOptionsData] = useState([])

    useEffect(() => {
        if (data) {
            const cats = []
            data.getCategories.forEach(category => {
                const cat = {
                    value: category.id,
                    label: category.name
                }
                cats.push(cat)
            });
            setOptionsData(cats)

        }
    }, [data])

    const addCat = (categoryId) => {
        const body = {
            productId: productId,
            categoryId: categoryId
        }
        addCategory(body).then(() => {
            refetch()
        })
    }


    return (<div className="mb-2">
        <ReactSelect placeholder={"Категории"} className="mb-3" options={optionsData} onChange={(e) => { addCat(e.value) }} />
        Входит в категории:
        {categories.length > 0 ? <>
            {categories?.map(category => <div key={category.id} className="border-bottom pb-1 pt-1">
                <Row>
                    <Col xs={10} md={7}>
                        {category.name}
                    </Col>
                    <Col xs={'auto'}>
                        <Button onClick={() => {
                            deleteCategory({
                                productId: productId,
                                categoryId: category.id
                            }).then(() => { refetch() })
                        }} size={'sm'} style={{
                            lineHeight: "14px"
                        }} variant="danger"><X size={14} /></Button>
                    </Col>
                </Row>
            </div>)}
        </>
            :
            <div className="text-center">Нет категорий</div>
        }
    </div>);
}

const Keywords = ({ productId, refetch, keywords }) => {

    const [show, setShow] = useState()
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(100);
    const stringFilter = '';
    const { data, isLoading, refetch: refecthKeywords } = useGetKeywordsQuery({ pageIndex, pageSize, stringFilter })
    const [addKeywordToProduct, { isLoading: isAddingKeywords }] = useAddKeywordToProductMutation()
    const [removeKeywordToProduct] = useRemoveKeywordToProductMutation()
    const handeClose = () => {
        setShow(false)
    }
    const addKey = (keywordId) => {
        const body = {
            productId: productId,
            searchKeywordId: keywordId
        }
        addKeywordToProduct(body).then(() => {
            refetch()
        })
    }

    const removeKey = (keywordId) => {
        const body = {
            productId: productId,
            searchKeywordId: keywordId
        }
        removeKeywordToProduct(body).then(() => { refetch() })
    }

    const [searchKeywords, setSearchKeywords] = useState()

    useEffect(() => {
        if (show) {
            refecthKeywords()
        }
    }, [show])
    useEffect(() => {
        if (data?.getSearchKeywords.length > 0) {
            const keys = []
            data.getSearchKeywords.forEach(item => {
                const keywordItem = {
                    value: item.id,
                    label: item.keyword
                }
                keys.push(keywordItem)
            });
            setSearchKeywords(keys)
        }
    }, [data])


    return (
        <>
            <Button onClick={() => { setShow(!show) }} size="sm">
                ключевые слова
            </Button>
            <Modal show={show} onHide={() => { handeClose() }}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <ReactSelect placeholder={"Ключевые слова"} className="mb-3" options={searchKeywords} onChange={(e) => { addKey(e.value) }} />
                    {keywords.map(item =>
                        <Row item={item.id} className="pt-2 pb-2 border-bottom">
                            <Col>{item.keyword}</Col>
                            <Col xs={'auto'}>
                                <Button
                                    onClick={() => { removeKey(item.id) }}
                                    variant="danger"
                                    size={'sm'} style={{
                                        lineHeight: "14px"
                                    }} ><X size={14} /></Button>
                            </Col>
                        </Row>
                    )}
                </Modal.Body>
            </Modal>
        </>
    )
}


export const ProductDetails = () => {
    const { id } = useParams();
    const [varEditShow, setVarEditShow] = useState()
    const [varState, setVarState] = useState()
    const [pageIndex, setPageIndex] = useState(sessionStorage.getItem("lastPageNumProducts"))
    // const [product, setProduct] = useState()
    const navigate = useNavigate();
    const toNav = (link) => {
        window.scrollTo(0, 0)
        navigate(link)
    }
    const { isLoading: productisLoading, data: product, error, refetch, isFetching } = useGetProductQuery(id)
    const [postProductPhoto, { isLoading: postingPhoto }] = usePostProductPhotoMutation();
    const [deleteProductPhoto,] = useDeleteProductPhotoMutation();
    const [deleteVariation] = useDeleteVariationMutation()
    const [changingPhoto, setChangingPhoto] = useState();


    useEffect(() => {
        refetch()
    }, []
    )

    const ImageCompressionExample = async (data) => {
        const imageFile = data;
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        };

        try {
            const compressedFile = await imageCompression(imageFile, options);
            return compressedFile;
        } catch (error) {
            console.error('Error compressing image:', error);
        }

        return;
    };

    const onImageChange = async (event) => {
        setChangingPhoto(true)
        if (event.target.files && event.target.files[0]) {
            const file = await ImageCompressionExample(event.target.files[0])
            if (file === null) {
                return window.alert("Error image upload")
            }
            const reader = new FileReader(file);
            reader.onloadend = () => {
                const body = {
                    id: id,
                    photo: reader.result,
                    photoType: file.type
                }
                postProductPhoto({ id, ...body })
            }
            reader.readAsDataURL(file)
        }
        setTimeout(() => {
            refetch()
            setChangingPhoto(false)
        }, 2000)
    }
    const deletePhoto = (photoId) => {
        setChangingPhoto(true)
        deleteProductPhoto(photoId)
        setTimeout(() => {
            refetch()
            setChangingPhoto(false)
        }, 2000)

    }

    // useEffect(() => {
    //     setProduct(data)
    // }, [data])

    return (
        <Container>
            <div>
                {productisLoading ? <LoadScreen></LoadScreen> : <div>
                    {product && product.length > 0 ? <h1>{error}</h1> : <div>
                        <div className="row">
                            <div className="col-xl">
                                <div className="mb-1">
                                    <label htmlFor="name" className="form-label">Наименование</label>
                                    <input disabled id="name" defaultValue={product.name} name="name" className="form-control" />
                                </div>
                                <div className="mb-1">
                                    <label htmlFor="sku" className="form-label">Артикул</label>
                                    <input disabled id="sku" name="sku" className="form-control" defaultValue={product.sku} />
                                </div>
                                <div className="mb-1">
                                    <label htmlFor="price" className="form-label">Цена</label>
                                    <input disabled id="price" defaultValue={product.price} name="price" step={.01} type={"number"} className="form-control" />
                                </div>
                                <div className="mb-1">
                                    <label htmlFor="description" className="form-label">Описание</label>
                                    {/* <textarea disabled id="description" defaultValue={product.description} name="description" className="form-control"></textarea> */}
                                    {product.description && <>{parse(product.description)}</>}
                                </div>
                                <div className="mb-1">
                                    <label htmlFor="price" className="form-label">Остаток шт.</label>
                                    <input disabled id="price" defaultValue={product.inStock} name="price" step={1} type={"number"} className="form-control" />
                                </div>
                                <div className="mb-2 d-flex">
                                    <div className=" form-check form-switch">
                                        <label className="form-check-label">Отключен</label>
                                        <input disabled defaultChecked={product.inActive} type="checkbox" className="form-check-input check-input-lc" />
                                    </div>
                                </div>
                                <div className="mb-2 d-flex">
                                    <div className=" form-check form-switch">
                                        <label className="form-check-label">Хит</label>
                                        <input disabled defaultChecked={product.hit} type="checkbox" className="form-check-input check-input-lc" />
                                    </div>
                                </div>
                                <div className="mb-2 d-flex">
                                    <div className=" form-check form-switch">
                                        <label className="form-check-label">Скрыть</label>
                                        <input disabled defaultChecked={product.hide} type="checkbox" className="form-check-input check-input-lc" />
                                    </div>
                                </div>
                                <div className="mb-2 d-flex">
                                    <div className=" form-check form-switch">
                                        <label className="form-check-label">Требует серийный номер</label>
                                        <input disabled defaultChecked={product.requiredSN} type="checkbox" className="form-check-input check-input-lc" />
                                    </div>
                                </div>
                                <div className={`mb-1 ${product.requiredSN ? "" : "d-none"}`}>
                                    <label htmlFor="maskSN" className="form-label">Маска серийного номера</label>
                                    <input disabled defaultValue={product.maskSN} id="maskSN" name="maskSN" type="text" className="form-control" />
                                </div>
                                <VariationAdd product={product} refetch={refetch} />
                                <Variations product={product} refetch={refetch} deleteVariation={deleteVariation} setVarState={setVarState} setVarEditShow={setVarEditShow} />
                                <VariationEdit setVarEditShow={setVarEditShow} varEditShow={varEditShow} varState={varState} refetch={refetch} />
                                <Categories productId={product.id} categories={product.categories} refetch={refetch} />
                            </div>
                            <div className="col-xl">
                                <label htmlFor="image" className="form-label">Изображение продукта</label>
                                {/* <div className="card mb-3">
                                                {postingPhoto || changingPhoto ? <LoadScreen /> :
                                                    <div className="mb-3 text-center">
                                                        <img key={Date.now()} src={product.imageType ? `/api/products/photo/${product.id}` : `/NoPhoto.png`} className="img-fluid" alt="Product photo" />
                                                    </div>
                                                }
                                            </div> */}
                                <div className='input-group'>


                                    <input onChange={(e) => onImageChange(e)}
                                        id="photo"
                                        name="photo"
                                        className="form-control form-control-sm"
                                        accept=".jpg, .jpeg, .png" type="file" />
                                </div>
                                {postingPhoto || changingPhoto ? <LoadScreen /> :
                                    <>
                                        {product.photos.length > 0 ?
                                            <>
                                                {product.photos.map(item =>
                                                    <Row key={item.id} className='mt-3 mb-3 border-bottom p-3'>

                                                        <Col>
                                                            <img src={`/api/products/photo/${item.id}`} style={{ maxHeight: "20vh" }} className="img-fluid" alt="Product photo" />
                                                            <Button className='ms-3' size='sm' variant="danger" onClick={() => deletePhoto(item.id)}><XCircle size={24} /></Button>
                                                        </Col>
                                                    </Row>
                                                )}
                                            </> :
                                            <Row>
                                                <Col>
                                                    Нет фото
                                                </Col>
                                            </Row>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                        <ButtonGroup className='mt-3'>
                            <Keywords productId={product.id} keywords={product.searchKeywords} refetch={refetch} />
                            <Button color="warning" onClick={() => toNav(`/admin/products/edit/${id}`)}>
                                Редактировать
                            </Button>
                            <Button color="success" onClick={() => toNav(`/admin/products${pageIndex ? `?pageNum=${pageIndex}` : "" }`)}>
                                Назад
                            </Button>
                        </ButtonGroup>
                    </div>
                    }
                </div>}
            </div>
        </Container>
    )
}