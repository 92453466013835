import { Col, Collapse, Nav, NavDropdown, Row } from "react-bootstrap"
import { Outlet, useNavigate, useSearchParams } from "react-router-dom"
import { Footer } from "./Footer"
import { NavMenu } from "./NavMenu"
import { Container } from "reactstrap"
import { useGetCategoriesCatalogQuery } from "../features/CallApi"
import LoadScreen from "./UI/LoadScreen"
import { useEffect, useState } from "react"
import { CaretDownSquareFill, CaretRightSquareFill, Clock, Telegram, TelephoneFill, Whatsapp } from "react-bootstrap-icons"
import { Basket } from "./Orders/Basket"
import { Metrik } from "../features/Metrik"
import { NavBarMobile } from "./NavBarMobile"
import { HeaderContactLine } from "./UI/HeaderContactLine"


export const LayoutCatalog = ({ basket, updateBasket, updateItem, removeItem, resetBasket, addItemStatus, showBasket, setShowBasket, showCatalog, setShowCatalog }) => {


    const [searchParams, setSearchParams] = useSearchParams();
    const [outCatId, setOutCatId] = useState();
    const navigate = useNavigate();
    const [selCatId, setSelCatId] = useState()
    const [catalogShow, setCatalogShow] = useState()
    const [selPromo, setSelPromo] = useState("");
    const { isLoading, data, refetch } = useGetCategoriesCatalogQuery();

    useEffect(() => {
        setSelCatId(searchParams.get("selId"))
        setSelPromo(searchParams.get("selPromo"))
        // setSelCatId(searchParams.get("selPromo"))
    }, [searchParams])

    useEffect(() => {
        refetch()
        window.scrollTo(0, 0)
        const wSize = window.innerWidth;
        if (wSize > 992) {
            setCatalogShow(true)
        }
    }, [])

    return (
        <>
            <Metrik />
            <HeaderContactLine />
            <NavMenu showCatalog={showCatalog} setShowCatalog={setShowCatalog} />
            <Container>
                {!isLoading ?
                    <Row>
                        <Col xs={12} lg={"auto"} className="d-none d-lg-block">
                            <div className="shadow mt-3 mb-3">
                                <h5 className="pt-3 pb-3 ps-3 pe-3 border-bottom" style={{ cursor: "pointer", minWidth: "302px" }} onClick={() => { setCatalogShow(!catalogShow) }}>Каталог</h5>
                                {/* <Collapse in={catalogShow}> */}
                                <Nav activeKey={selCatId} style={{ minWidth: "200px" }} className="flex-column bubbles-v-nav">
                                    <Nav.Link
                                        onClick={() => {
                                            navigate("/catalog")
                                            setSelCatId(null)
                                        }}
                                    >
                                        <strong>Весь каталог</strong>
                                    </Nav.Link>
                                    {data.map(cat => <Nav.Item key={cat.id}>
                                        {cat.categories.length > 0 ?
                                            <NavDropdown id="nav-dropdown-dark-example w-100" title={cat.name}>
                                                <NavDropdown.Item eventKey={cat.id} onClick={() => {
                                                    navigate(`/catalog?selId=${cat.id}`)
                                                    setSelCatId(cat.id)
                                                }}>Все <span style={{ textTransform: "lowercase" }} >{cat.name}</span></NavDropdown.Item>
                                                {cat.categories.map(subCat =>
                                                    <NavDropdown.Item key={subCat.id} eventKey={subCat.id} onClick={() => {
                                                        navigate(`/catalog?selId=${subCat.id}`)
                                                        // setSearchParams({selId: subCat.id})
                                                        setSelCatId(subCat.id)
                                                    }}>{subCat.name}</NavDropdown.Item>
                                                )}
                                            </NavDropdown>
                                            :
                                            <Nav.Link key={cat.id} eventKey={cat.id} onClick={() => {
                                                navigate(`/catalog?selId=${cat.id}`)
                                                // setSearchParams({selId: cat.id})
                                                setSelCatId(cat.id)
                                            }}
                                            >{cat.name}</Nav.Link>
                                        }
                                    </Nav.Item>
                                    )}
                                </Nav>
                                {/* </Collapse> */}
                            </div>
                        </Col>
                        <Col>
                            <Outlet context={[outCatId, setOutCatId]} />
                        </Col>
                    </Row> :
                    <LoadScreen />
                }
            </Container>
            <Basket basket={basket} updateBasket={updateBasket} updateItem={updateItem} removeItem={removeItem} resetBasket={resetBasket} addItemStatus={addItemStatus}
                showBasket={showBasket}
                setShowBasket={setShowBasket}
            />
            <Footer />
            <NavBarMobile
                removeItem={removeItem}
                basket={basket}
                updateBasket={updateBasket}
                resetBasket={resetBasket}
                addItemStatus={addItemStatus}
                showBasket={showBasket}
                setShowBasket={setShowBasket}
                showCatalog={showCatalog} 
                setShowCatalog={setShowCatalog}
            />
        </>
    )
}