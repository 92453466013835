import { YMInitializer } from 'react-yandex-metrika';
import ReactGA from "react-ga4";

export const Metrik = () => {
    ReactGA.initialize([
        {
            trackingId: "G-186NWT3PLP",
            // gaOptions: { ...}, // optional
            // gtagOptions: { ...}, // optional
        },
        // {
        //     trackingId: "your second GA measurement id",
        // },
    ]);
    return (
        <>
            <div><YMInitializer accounts={[97820140]} options={{ webvisor: true, trackHash: true }} version='2' /></div>
        </>
    )
}