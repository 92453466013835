import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';



export default function useBasket() {
    const [basket, setBasket] = useState()
    const [updateBasket, setUpdateBasket] = useState(false)
    const [addItemStatus, setAddItemStatus] = useState(false)
    const [showBasket, setShowBasket] = useState(false)


    useEffect(() => {
        if (!basket) {
            setUpdateBasket(true)
            setBasket(JSON.parse(sessionStorage.getItem("basket")))
            setUpdateBasket(false)
        }
    }
    )
    const addItemToBasket = async (itemProduct) => {
        const basket = await JSON.parse(sessionStorage.getItem("basket"))
        if (basket != null) {
            if (itemProduct.sn != "") {
                const item = basket.find(item => item.sn === itemProduct.sn)
                if (item != null) {
                    alert("Cерийный номер уже есть в корзине")
                    return;
                }
                basket.push(itemProduct)
                sessionStorage.setItem("basket", JSON.stringify(basket))
            }
            else if (itemProduct.variationsId) {
                const item = basket.find(item => item.variationsId === itemProduct.variationsId)
                if (item) {
                    const indexItem = basket.indexOf(item)
                    let itemQuantity = item.quantity
                    itemQuantity = (itemQuantity + itemProduct.quantity)
                    basket[indexItem].quantity = itemQuantity
                }
                else {
                    basket.push(itemProduct)
                }
                sessionStorage.setItem("basket", JSON.stringify(basket))
            }
            else {
                const item = basket.find(item => item.id === itemProduct.id)
                if (item) {
                    const indexItem = basket.indexOf(item)
                    let itemQuantity = item.quantity
                    itemQuantity = (itemQuantity + itemProduct.quantity)
                    basket[indexItem].quantity = itemQuantity
                }
                else {
                    basket.push(itemProduct)
                }
                sessionStorage.setItem("basket", JSON.stringify(basket))
            }
        }
        else {
            const array = new Array(itemProduct)
            sessionStorage.setItem("basket", JSON.stringify(array))
        }
        setAddItemStatus(true)
        setBasket(await JSON.parse(sessionStorage.getItem("basket")))

    }
    const removeItem = async (e) => {
        const id = parseInt(e.currentTarget.getAttribute("data-id"))
        const sn = e.currentTarget.getAttribute("data-sn")
        const vr = e.currentTarget.getAttribute("data-vr")
        setUpdateBasket(true)
        const basket = await JSON.parse(sessionStorage.getItem("basket"))
        if (sn != null && sn != "") {
            const item = basket.find(item => item.sn === sn)
            const indexItem = basket.indexOf(item)
            // console.log(indexItem)
            basket.splice(indexItem, 1)
        }
        else if (vr != null && vr != "") {
            console.log(vr)
            const item = basket.find(item => item.variationsId === vr)
            const indexItem = basket.indexOf(item)
            // console.log(indexItem)
            basket.splice(indexItem, 1)
        }
        else if (id) {
            const item = basket.find(item => item.id === id)
            const indexItem = basket.indexOf(item)
            basket.splice(indexItem, 1)
        }

        sessionStorage.setItem("basket", JSON.stringify(basket))
        setBasket(JSON.parse(sessionStorage.getItem("basket")))
        setUpdateBasket(false)

    }
    const updateItem = async (e) => {
        setUpdateBasket(true)

        const newQuantity = e.currentTarget.closest("div").querySelector("input").value
        const id = e.currentTarget.getAttribute("data-id")
        const vr = e.currentTarget.getAttribute("data-vr")

        const basket = await JSON.parse(sessionStorage.getItem("basket"))
        if (vr != null && vr != "") {
            const item = basket.find(item => item.variationsId === vr)
            if (item) {
                if (newQuantity <= 0) {
                    const item = basket.find(item => item.id === id)
                    const indexItem = basket.indexOf(item)
                    basket.splice(indexItem, 1)
                } else {
                    const indexItem = basket.indexOf(item)
                    let itemQuantity = item.quantity
                    basket[indexItem].quantity = parseFloat(newQuantity)
                }
            }
        }
        else {
            const item = basket.find(item => item.id == id)
            if (item) {
                if (newQuantity <= 0) {
                    const item = basket.find(item => item.id === id)
                    const indexItem = basket.indexOf(item)
                    basket.splice(indexItem, 1)
                } else {
                    const indexItem = basket.indexOf(item)
                    let itemQuantity = item.quantity
                    basket[indexItem].quantity = parseFloat(newQuantity)
                }
            }
        }

        sessionStorage.setItem("basket", JSON.stringify(basket))
        setBasket(JSON.parse(sessionStorage.getItem("basket")))
        setUpdateBasket(false)
    }
    const addItem = async (data) => {
        setAddItemStatus(false)
        setUpdateBasket(true)
        if (data.quantity == null) {
            data.quantity = 1
        }
        axios.get(`api/products/get-product-data/${data.id}`).then((response) => {
            const product = response.data

            console.log(product.discount)

            // if(product.discount > 0){
            //     product.price = product.discount
            //     console.log(product.price)
            // }
            if (data.variations) {
                const dataVar = product.variations.find(item => item.id == data.variations)

                product.name = product.name + " (" + dataVar.name + ")"
                product.price = dataVar.price
            }
            const itemProduct = {
                id: product.id,
                name: product.name,
                price: product.price,
                photo: product.photos[0]?.id,
                discount: product.discount,
                quantity: parseInt(data.quantity),
                sn: (data.sn ? data.sn : ""),
                variationsId: (data.variations && data.variations)
            }
            addItemToBasket(itemProduct)
            setUpdateBasket(true)
            setUpdateBasket(false)
        }
        )
    }
    const resetBasket = () => {
        sessionStorage.removeItem("basket")
        setBasket([])
    }

    return {
        addItem,
        removeItem,
        updateItem,
        resetBasket,
        basket: basket,
        updateBasket: updateBasket,
        addItemStatus: addItemStatus,
        showBasket: showBasket,
        setShowBasket: setShowBasket
    };
}