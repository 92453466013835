import React, { Component } from 'react';
import { Clock, Telegram, TelephoneFill, Whatsapp } from 'react-bootstrap-icons';
import { NavMenu } from './NavMenu';
import { Outlet } from 'react-router-dom';
import { Footer } from './Footer';
import { Basket } from './Orders/Basket';
import { Metrik } from '../features/Metrik';
import { NavBarMobile } from './NavBarMobile';
import mainCss from './main.module.css'
import { HeaderContactLine } from './UI/HeaderContactLine';

export const Layout = ({ basket, updateBasket, removeItem, resetBasket, addItemStatus, showBasket, setShowBasket, updateItem, showCatalog, setShowCatalog }) => {




  return (
    <>
      <Metrik />
      <div className={mainCss.mainBgColor}>
        <HeaderContactLine />
        <NavMenu
          showCatalog={showCatalog}
          setShowCatalog={setShowCatalog}
           />
        <Outlet />
        <Basket
          removeItem={removeItem}
          basket={basket}
          updateBasket={updateBasket}
          resetBasket={resetBasket}
          addItemStatus={addItemStatus}
          showBasket={showBasket}
          setShowBasket={setShowBasket}
          updateItem={updateItem}
        />
        <Footer />
        <NavBarMobile
          removeItem={removeItem}
          basket={basket}
          updateBasket={updateBasket}
          resetBasket={resetBasket}
          addItemStatus={addItemStatus}
          showBasket={showBasket}
          setShowBasket={setShowBasket}
          showCatalog={showCatalog}
          setShowCatalog={setShowCatalog}
        />
      </div>
    </>
  );
}
