import React from "react";
import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Row,
    Col
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { ThreeDots, BookmarkCheckFill, BookmarkX } from 'react-bootstrap-icons';



const UsersList = ({ users, title, page, isLoading }) => {
    console.log(users);
    const navigate = useNavigate()
    return (
        <div>
            <h3>{title}</h3>

            {users && users.length > 0 ?
                users.map(user =>
                    <Row key={user.id} className='border-bottom'>
                        <Col xs='1' style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        >
                            <div>
                                {user.status ? <div className='link-success'><BookmarkCheckFill /></div> : <div className='link-danger'><BookmarkX /></div>}
                            </div>
                        </Col>
                        <Col xs='1' style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        >#{user.id}</Col>
                        <Col >
                            <div>{user.lastname} {user.firstname} </div>
                            <div className="text-muted">{user.email}</div>
                            <div>{user.role}</div>
                        </Col>

                        <Col xs='auto' style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            <UncontrolledDropdown>
                                <DropdownToggle color="light" >
                                    <ThreeDots className='' size='14' />
                                </DropdownToggle>
                                <DropdownMenu container="body">
                                    <DropdownItem onClick={() => navigate('edit/' + user.id)}>Редактировать</DropdownItem>
                                    <DropdownItem onClick={() => navigate('details/' + user.id)}>Подробнее</DropdownItem>
                                    <DropdownItem onClick={() => navigate('changepassword/' + user.id)}>Сменить пароль</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Col>
                    </Row>
                )
                : <Row>
                    <Col style={{ textAlign: 'center' }}>Список пуст</Col>
                </Row>
            }

        </div >
    );


}
export { UsersList };