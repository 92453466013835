import { useEffect, useState } from "react";
import { Button, ButtonGroup, Card, Col, Container, Image, Row } from "react-bootstrap"
import { useForm } from "react-hook-form";
import LoadScreen from "../UI/LoadScreen";
import { ArrowRepeat, Check2All, TrashFill } from "react-bootstrap-icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";


export const OrderCreate = ({ basket, updateBasket, updateItem, removeItem, resetBasket }) => {
    const [summ, setSumm] = useState(0)
    const [saleSumm, setSaleSumm] = useState(0)
    // const [orderCreated, setStatusCreated] = useState(false)
    const [orderId, setOrderId] = useState();
    const [fetching, setFetching] = useState(false)
    const [summError, setSummError] = useState();
    const [summQuantity, setSummQuantity] = useState();
    const [deliverySelect, setDeliverySelect] = useState(0)
    const { register, handleSubmit, formState: { errors }, getValues, setValue } = useForm();
    const navigate = useNavigate();

    useEffect(() => {
        if (basket) {
            const sum = basket.reduce((summa, item) => {
                return summa + ((item.discount ? parseFloat(item.discount) : parseFloat(item.price)) * item.quantity);
            }, 0);
            setSumm(sum)
            const quantityBasket = basket.reduce((summQuantity, item) => {
                return summQuantity + item.quantity
            }, 0)
            setSummQuantity(quantityBasket)
            if (sum >= 1000) {
                setSummError(null)
            }
            else if (sum < 1000) {
                setSummError("Мнинимальная сумма заказа 1000 руб.")
            }
        }
    }, [basket])

    const create = async (data) => {
        setFetching(true)
        let adress = "";
        console.log(data)
        console.log(data.typeDelivery)
        if (parseInt(data.typeDelivery) === 0) {
            adress = data.city + ", " + data.street + ", д." + data.houseNumber +
                (data.extension ? ", корп. " + data.extension : "") +
                (data.apartment ? ", кв. " + data.apartment : "") +
                (data.intercom ? ", (домофон: " + data.intercom + ")" : "")
        }
        else if (parseInt(data.typeDelivery) === 1) {
            adress = "Самовывоз";
        }
        const deliveryDate = data.dateDelivery + " " + data.timeDelivery;
        const body = {
            client: data,
            basket: basket,
            adress: adress,
            comments: data.comments,
            deliveryDate: deliveryDate
        }

        console.log(body)
        // resetBasket()
        // reset()
        await axios.post("/api/orders/create", {
            client: data,
            basket: basket,
            adress: adress,
            typeOfPay: data.typeOfPay,
            comments: data.comments,
            deliveryDate: deliveryDate
        }).then((r) => {
            if (r.status === 200) {
                resetBasket()
                // console.log(r)
                console.log(r.data)
                setOrderId(r.data)
                setFetching(false)
            }
            else {
                alert("Возникла ошибка при создании заказа, свяжитесь с нами по телефону!")
            }
        })
    }

    useEffect(() => {
        setValue("typeDelivery", 0)
    }, [])
    if (orderId) {
        return (
            <Container>
                <div className="text-center mt-3 mb-3">
                    <div><Check2All size={60} fill={"#006800"} /></div>
                    <h3>Спасибо за Ваш заказ!</h3>
                    <div className="fs-4"><strong>Заказ №{orderId}</strong></div>

                    <p className="fs-5">В ближайшее время мы свяжемся для подтверждения заказа.</p>
                    <p>Заказы обрабатываются с 9:00 - 23:00</p>
                    <Button variant="outline-light" className="mt-3" onClick={() => { navigate("/") }}>Перейти на главную</Button>
                </div>
            </Container>
        )
    }
    return (
        <Container>
            {!fetching ?

                <Row className="mt-3">
                    <Col lg={6} xl={7}>
                        <Card className="shadow">
                            <Card.Body>
                                <div className="ms-3 me-3">
                                    <form onSubmit={handleSubmit(create)}>
                                        <div className="mb-2 d-flex"><h4>Оформление заказа</h4></div>
                                        <div className="mb-2 mt-3 d-flex border-top"><h5>Контактные данные</h5></div>
                                        {summError && <div className="text-danger">{summError}</div>}
                                        <Row className="mb-2 g-2">
                                            <Col md={6}>
                                                <div className="form-floating">
                                                    <input type="text" className={`form-control ${errors.firstName && "is-invalid"}`} id="firstName" placeholder="Имя"
                                                        {...register("firstName", { required: { value: true, message: "Поле \"Имя\" не может быть пустым" } })} />
                                                    <label htmlFor="firstName">Имя <span className="text-danger">*</span></label>
                                                    {errors.firstName && <span className='text-danger'>{errors.firstName?.message}</span>}
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="form-floating">
                                                    <input type="text" className={`form-control ${errors.lastName && "is-invalid"}`} id="lastName" placeholder="Фамилия"
                                                        {...register("lastName")} />
                                                    <label htmlFor="lastName">Фамилия</label>
                                                    {errors.lastName && <span className='text-danger'>{errors.lastName?.message}</span>}
                                                </div>
                                            </Col>
                                            {/* <Col>
                                                <div className="form-floating g-2">
                                                    <input type="text" className={`form-control ${errors.firstName && "is-invalid"}`} id="patronymic" placeholder="Отчество"
                                                        {...register("patronymic",)} />
                                                    <label htmlFor="patronymic">Отчество</label>
                                                </div>
                                            </Col> */}
                                        </Row>
                                        <Row className="mb-2 g-2">
                                            <Col md={6}>
                                                <div className="form-floating">
                                                    <input type="tel" className={`form-control ${errors.firstName && "is-invalid"}`} id="phone" placeholder="Телефон"
                                                        {...register("phone", { required: { value: true, message: "Поле \"Телефон\" не может быть пустым" }, })} />
                                                    <label htmlFor="phone">Телефон <span className="text-danger">*</span></label>
                                                    {errors.phone && <span className='text-danger'>{errors.phone?.message}</span>}
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="form-floating">
                                                    <input type="email" className={`form-control ${errors.firstName && "is-invalid"}`} id="email" placeholder="E-Mail"
                                                        {...register("email", {
                                                            required: { value: true, message: "Поле \"E-Mail\" не может быть пустым" },
                                                            pattern: { value: /^\S+@\S+$/i, message: "Некорректно указан E-Mail" },
                                                        })} />
                                                    <label htmlFor="email">E-Mail <span className="text-danger">*</span></label>
                                                    {errors.email && <span className='text-danger'>{errors.email?.message}</span>}
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="mb-2 mt-3 d-flex border-top"><h5>Условия доставки</h5></div>
                                        <div className='row g-2 mb-2'>
                                            <div className="col">
                                                <div className="form-floating">
                                                    <input type="date" className={`form-control ${errors.firstName && "is-invalid"}`} id="dateDelivery" placeholder="Дата"
                                                        min={new Date().toISOString().split("T")[0]}
                                                        {...register("dateDelivery", { required: { value: true, message: "Укажите дату доставки" } })} />
                                                    <label htmlFor="dateDelivery">Дата <span className="text-danger">*</span></label>
                                                    {errors.dateDelivery && <span className='text-danger'>{errors.dateDelivery?.message}</span>}
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="form-floating">
                                                    <input type="time" className={`form-control ${errors.firstName && "is-invalid"}`} id="timeDelivery" placeholder="Дата"
                                                        min={new Date().toISOString().split("T")[0]}
                                                        {...register("timeDelivery", { required: { value: true, message: "Укажите время доставки" } })} />
                                                    <label htmlFor="timeDelivery">Время <span className="text-danger">*</span></label>
                                                    {errors.timeDelivery && <span className='text-danger'>{errors.timeDelivery?.message}</span>}
                                                </div>
                                            </div>
                                            <span className="col-12 text-muted">*-доставка осуществляется в часовом интервале (+- 30 мин.) от указанного времени.</span>
                                            <div className='col'>
                                                <div className="form-floating">
                                                    <textarea className="form-control" placeholder="Комментарий" id="comments" style={{ height: "100px" }}
                                                        {...register("comments")}></textarea>
                                                    <label htmlFor="comments">Комментарий</label>
                                                </div>
                                            </div>

                                            {/* <div className="form-group col-12">
                                                <div className="form-floating">
                                                    <select {...register("typeDelivery", { onChange: () => { setDeliverySelect(getValues("typeDelivery")) } })} defaultValue={0} className="form-select" id="typeDelivery" aria-label="Условия получения">
                                                        <option value={""}>-- Выберите тип получения --</option>
                                                        <option value={0}>Доставка</option>
                                                        <option value={1}>Самовывоз</option>
                                                    </select>
                                                    <label htmlFor="typeDelivery">Условия получения <span className="text-danger">*</span></label>
                                                </div>
                                            </div> */}
                                            <div className="form-group col-12">
                                                <div className="form-floating">
                                                    <select {...register("typeOfPay", { onChange: () => { getValues("typeOfPay") } })} className="form-select" id="typeOfPay" aria-label="Способ оплаты">
                                                        <option value={0}>Картой на сайте</option>
                                                        <option value={1}>Наличными при получение (Предоплата 50%)</option>
                                                        <option value={2}>Перводом на карту при получение (Предоплата 50%)</option>
                                                    </select>
                                                    <label htmlFor="typeOfPay">Способ оплаты <span className="text-danger">*</span></label>
                                                    <span>Ссылка для оплаты будет доступна после подтверждения заказ</span>
                                                </div>
                                            </div>
                                        </div>

                                        {(deliverySelect == 0) && <div>
                                            <div className='mb-3 row g-2'>
                                                <div className="col d-none">
                                                    <div className="form-floating">
                                                        <input type="text" value={"Санкт-Петербург"} className={`form-control ${errors.city && "is-invalid"}`} id="city" placeholder="Город"
                                                            {...register("city", {})} />
                                                        <label htmlFor="city">Город</label>
                                                        {errors.city && <span className='text-danger'>{errors.city?.message}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-floating">
                                                        <input type="text" className={`form-control ${errors.street && "is-invalid"}`} id="street" placeholder="Город"
                                                            {...register("street", { required: { value: true, message: "Укажите улицу" } })} />
                                                        <label htmlFor="street">Улица</label>
                                                        {errors.street && <span className='text-danger'>{errors.street?.message}</span>}
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-floating">
                                                        <input type="text" className={`form-control ${errors.houseNumber && "is-invalid"}`} id="houseNumber" placeholder="Дом"
                                                            {...register("houseNumber", { required: { value: true, message: "Укажите номер дома" } })} />
                                                        <label htmlFor="houseNumber">Дом</label>
                                                        {errors.houseNumber && <span className='text-danger'>{errors.houseNumber?.message}</span>}
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-floating">
                                                        <input type="text" className={`form-control ${errors.extension && "is-invalid"}`} id="extension" placeholder="Корпус"
                                                            {...register("extension", {})} />
                                                        <label htmlFor="houseNumber">Корпус</label>
                                                        {errors.extension && <span className='text-danger'>{errors.extension?.message}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-floating">
                                                        <input type="text" className={`form-control ${errors.apartment && "is-invalid"}`} id="apartment" placeholder="Квартира"
                                                            {...register("apartment", {})} />
                                                        <label htmlFor="apartment">Квартира</label>
                                                        {errors.apartment && <span className='text-danger'>{errors.apartment?.message}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-floating">
                                                        <input type="text" className={`form-control ${errors.intercom && "is-invalid"}`} id="intercom" placeholder="Домофон"
                                                            {...register("intercom", {})} />
                                                        <label htmlFor="intercom">Домофон</label>
                                                        {errors.intercom && <span className='text-danger'>{errors.intercom?.message}</span>}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        }
                                        <div className="mb-2 d-flex">
                                            <div className=" form-check form-switch">
                                                <label className="form-check-label"> <a href="/doc/ppd.pdf" target="_blank">Согласие на обработку персональных данных</a></label>
                                                <input type="checkbox" className="form-check-input" {...register("policy", { required: { value: true, message: "Необходимо согласие на обработку персональных данных" } })} />
                                                <br />
                                                {errors.policy && <span className='text-danger'>{errors.policy?.message}</span>}
                                            </div>
                                            <div>
                                            </div>
                                        </div>
                                        <div className="mb-2 d-flex">
                                            <div className=" form-check form-switch">
                                                <label className="form-check-label"><a href="/delivery" target="_blank">Согласие с условиями доставки</a> (Стоимость доставки будет включина после подтверждения заказа)</label>
                                                <input type="checkbox" className="form-check-input" {...register("delivery", { required: { value: true, message: "Необходимо согласие с условиями доставки" } })} />
                                                <br />
                                                {errors.policy && <span className='text-danger'>{errors.delivery?.message}</span>}
                                            </div>
                                            <div>
                                            </div>
                                        </div>
                                        <div className="mb-2 d-flex">
                                            <div className=" form-check form-switch">
                                                <label className="form-check-label">Я согласен получать информационные материалы об актуальных новостях сайта Баблс.рф</label>
                                                <input type="checkbox" className="form-check-input" {...register("receiveNewsletter", {})} />
                                            </div>
                                            <div>
                                            </div>
                                        </div>
                                        {summError ? <div className="text-danger">{summError}</div> :
                                            <Button type="submit" id="submit" className="rounded-pill" variant="success">Оформить</Button>}
                                    </form >
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={6} xl={5} className="mt-3 mt-lg-0 mb-3">
                        <Card className="shadow">
                            <Card.Body>
                                {updateBasket ? <LoadScreen /> :
                                    <div>
                                        <div className="mb-2 d-flex"><h5>Ваш заказ</h5></div>
                                        <div>
                                            {basket &&
                                                <div>{basket.length > 0 ? <>
                                                    {basket.map(product =>
                                                        <div className="border-bottom mb-3 p-2 pb-3 show-bg" key={product.sn ? product.sn : product.id}>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="d-flex">
                                                                        <div>
                                                                            <strong>{product.name}</strong>
                                                                        </div>
                                                                        {product.sn &&
                                                                            <div className="ms-3">
                                                                                С/Н: <strong>{product.sn}</strong>
                                                                            </div>
                                                                        }

                                                                    </div>
                                                                    <div className="row g-1 mt-1">
                                                                        {/* <div className="col-2">
                                                                            {product.photos?.length > 0 ?
                                                                                <>
                                                                                    {product.photos?.map(item =>
                                                                                        <Image src={`/api/products/photo/${item.id}`} className="img-fluid rounded" alt={`Product-${product.name}`} />
                                                                                    )}
                                                                                </>
                                                                                :
                                                                                <Image style={{ maxWidth: "50px" }} src={`/NoPhoto.png`} rounded fluid />
                                                                            }
                                                                        </div> */}
                                                                        <div className="col-4 align-self-center text-end text-nowrap">
                                                                            {product.discount > 0 ?
                                                                                <>

                                                                                    <span style={{ position: "relative" }}>
                                                                                        <span className="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-danger">
                                                                                            {((product.discount / product.price) * 100 - 100)} %
                                                                                        </span>
                                                                                        <strike>{product.price} ₽</strike> = </span>
                                                                                    <span style={{ color: "red" }}>{product.discount} ₽</span>
                                                                                    {/* <div><span className="translate-middle badge rounded-pill bg-danger">{(product.discount - product.price)}</span></div> */}
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {product.price} ₽
                                                                                </>
                                                                            }
                                                                        </div>
                                                                        <div className="col-1 align-self-center text-end text-nowrap">X</div>
                                                                        <div className="col align-self-center">
                                                                            {product.sn === null || product.sn === "" ?
                                                                                <div className="input-group input-group-sm">
                                                                                    <input style={{ maxWidth: "60px" }} type="number" min={0} className="form-control form-control-sm text-center"
                                                                                        onChange={(e) => {
                                                                                            e.target.closest("div").querySelector("button").classList.remove("d-none")
                                                                                            e.target.closest("div").querySelector("button").classList.add("btn-warning")
                                                                                            e.target.closest("div").querySelector(`.qBadge`).classList.add("d-none")
                                                                                        }}
                                                                                        defaultValue={product.quantity} />
                                                                                    <span className="input-group-text qBadge" id={`${product.name}`}>шт.</span>
                                                                                    <Button data-id={product.id} data-vr={product.variationsId} onClick={(e) => { updateItem(e) }} className="d-none" color="warning"><ArrowRepeat size={18} /></Button>
                                                                                </div>
                                                                                :
                                                                                <div className="input-group input-group-sm">
                                                                                    <input style={{ maxWidth: "60px" }} type="number" className="form-control form-control-sm text-center" disabled value={product.quantity} />
                                                                                    <span className="input-group-text" id="basic-addon2">шт.</span>
                                                                                </div>

                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="col-2">
                                                                    <Image style={{ maxWidth: "50px" }} src={product.imageType ? `/api/products/photo/${product.id}` : `/NoPhoto.png`} rounded fluid />
                                                                </div> */}
                                                                <div className="col-2">
                                                                    <Button className="p-1 rounded-circle" style={{ width: "32px", height: "32px", lineHeight: 0 }} data-id={product.id} data-sn={product.sn} data-vr={product.variationsId} onClick={(e) => { removeItem(e) }} variant="outline-danger"><TrashFill size={14} /></Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}</>
                                                    : <div className="text-center mt-3 mb-3">Корзина пустая</div>
                                                }</div>
                                            }
                                        </div>
                                        <div className="mb-3 d-flex">
                                            <div className="me-3">Сумма заказа:</div>
                                            <div>
                                                {summ} ₽
                                            </div>
                                        </div>
                                    </div>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row> :
                <LoadScreen />
            }
        </Container>
    )
}