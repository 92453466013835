import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CardHeader, Container, Card, CardBody, CardTitle, Table, CardFooter, Button } from 'reactstrap';
import LoadScreen from "../../UI/LoadScreen";
import { useGetUserByIdQuery } from '../../../features/CallApi';
import { UserRole } from './UserRole';

export const UserDetails = () => {
    const { id } = useParams();
    console.log(id)
    const navigate = useNavigate();
    const { data, isLoading, isError } = useGetUserByIdQuery(id)
    return (
        <div>
            <Container>
                {isLoading ? <LoadScreen></LoadScreen> : <div></div>}
                {isError && <h4>Ошибка запроса {isError}</h4>}
                {data ?
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h4">
                                Пользователей с ID: {data.id}
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Table className='table-borderless'>
                                <tbody>
                                    <tr>
                                        <td className='text-end'>
                                            Ф.И.О.:
                                        </td>
                                        <td className='w-100'>
                                            <strong>{data.lastname} {data.firstname}</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-end'>
                                            E-Mail:
                                        </td>
                                        <td className='w-100'>
                                            <strong>{data.email}</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-end'>
                                            Роль:
                                        </td>
                                        <td className='w-100'>
                                            <UserRole roleId={data.roleId} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='text-end'>
                                            Статус:
                                        </td>
                                        <td className='w-100'>
                                            <strong>
                                                {data.status ? <div className='link-success'>Активен</div> : <div className='link-danger'>Заблокирован</div>}</strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>




                        </CardBody>
                        <CardFooter>
                            <Button onClick={() => navigate('../users')}>Назад</Button>
                        </CardFooter>

                    </Card> : <div></div>}


            </Container>

        </div>
    )
}