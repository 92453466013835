import { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, ButtonGroup } from 'reactstrap';
import { useForm } from "react-hook-form"
import { useAddProductMutation, useGetCategoriesSelectQuery } from "../../../features/CallApi";
import { withMask, withHookFormMask } from 'use-mask-input';
import LoadScreen from '../../UI/LoadScreen';
import { JoditEditorInput } from '../../UI/JoditEditorInput';
import imageCompression from 'browser-image-compression';


export const ProductAdd = ({ refetch, copyProduct, setCopyProduct }) => {

    const ImageCompressionExample = async (data) => {
        const imageFile = data;
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        };

        try {
            const compressedFile = await imageCompression(imageFile, options);
            return compressedFile;
        } catch (error) {
            console.error('Error compressing image:', error);
        }

        return;
    };
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm();
    const [modal, setModal] = useState(false);
    const { isLoading, data: productParams, refetch: refetchParams } = useGetCategoriesSelectQuery({});
    const [photoType, setPhotoType] = useState();
    const [photo, setPhoto] = useState();
    // const [image, setImage] = useState(null)
    const [addProduct, { isLoading: postIsloading }] = useAddProductMutation();
    const [requiredSNInput, setRequiredSNInput] = useState(false);
    const [description, setDescription] = useState()



    const onImageChange = async (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = await ImageCompressionExample(event.target.files[0])
            if (file === null) {
                return window.alert("Error image upload")
            }
            console.log(file)
            const reader = new FileReader(file);
            reader.onloadend = () => {
                setPhoto(reader.result)
                setPhotoType(file.type)
            }
            reader.readAsDataURL(file)
        }
    }

    const toggle = () => {
        setModal(!modal)
        if (!modal) {
            refetchParams();
        }
        setPhoto(null)
        reset()
    }

    useEffect(() => {
        if (modal === false) {
            setCopyProduct(null)
            setDescription(null)
        }
    }, [modal])

    const onRequiredSNChenge = (e) => {
        setRequiredSNInput(e.target.checked)
    }

    const setAddProduct = (data) => {
        data.requiredSN = requiredSNInput
        if (photo) {
            data.photo = photo
            data.photoType = photoType
        }
        data.description = description
        console.log(data)
        addProduct(data).unwrap().then(() => {
            setRequiredSNInput(false)
            setCopyProduct(null)
            setModal(false)
            setPhoto(null)
            reset()
            refetch()
        }).catch((e) => {
        })
    }

    useEffect(() => {
        if (copyProduct != null) {
            setValue("name", copyProduct.name)
            setDescription(copyProduct.description)
            setValue("sku", copyProduct.sku)
            setModal(true)
        }
    }, [copyProduct])

    return (
        <div>
            <Button className='mb-2' color="success" size="sm" onClick={toggle}>
                Создать товар
            </Button>
            <Modal isOpen={modal} toggle={toggle} size={"xl"}>
                <ModalHeader toggle={toggle}>Создать товар</ModalHeader>
                {isLoading ? <LoadScreen /> :
                    <form className="" onSubmit={handleSubmit(setAddProduct)}>
                        <ModalBody>
                            <div className="row">
                                <div className="col-xl">
                                    <div className="mb-1">
                                        <label htmlFor="category" className="form-label">Категория</label>
                                        <select id="category" name="category" defaultValue={null} className="form-select" placeholder={`Выберите категорию`}
                                            {...register("category", { required: { value: true, message: "Выберите категорию" } })} >
                                            {productParams ?
                                                productParams.map(category =>
                                                    <option key={category.id} value={category.id}>{category.name} {category.parentCategoryName && "(" + category.parentCategoryName + ")"}</option>
                                                ) : <option disabled>Нет доступных категорий</option>}
                                        </select>
                                        {errors.category && <span className='text-danger'>{errors.category?.message}</span>}
                                    </div>
                                    <div className="mb-1">
                                        <label htmlFor="name" className="form-label">Наименование</label>
                                        <input id="name" name="name" className="form-control"
                                            {...register("name", { required: { value: true, message: "Укажите наименование" } })} />
                                        {errors.name && <span className='text-danger'>{errors.name?.message}</span>}
                                    </div>
                                    <div className="mb-1">
                                        <label htmlFor="sku" className="form-label">Артикул</label>
                                        <input id="sku" ref={withMask('')} name="sku" className="form-control"
                                            {...withHookFormMask({ ...register("sku", { required: { value: true, message: "Укажите артикул" } }) }
                                                , ['99999999'])} />
                                        {errors.sku && <span className='text-danger'>{errors.sku?.message}</span>}
                                    </div>
                                    <div className="mb-1">
                                        <label htmlFor="price" className="form-label">Цена</label>
                                        <input id="price" name="price" step={.01} type={"number"} className="form-control"
                                            {...register("price", { required: { value: true, message: "Укажите цену" } })} />
                                        {errors.price && <span className='text-danger'>{errors.price?.message}</span>}
                                    </div>
                                    <div className="mb-1">
                                        <label htmlFor="description" className="form-label">Описание</label>
                                        <JoditEditorInput valueEditor={description} setValueEditor={setDescription} />

                                        {/* <textarea id="description" name="description" className="form-control" {...register("description")}></textarea> */}
                                        {errors.price && <span className='text-danger'>{errors.price?.message}</span>}
                                    </div>
                                    <div className="mb-1">
                                        <label htmlFor="inStock" className="form-label">Остаток шт.</label>
                                        <input id="inStock" defaultValue={1} step={1} type={"number"} name="inStock" className="form-control" {...register("inStock")} />
                                        {errors.inStock && <span className='text-danger'>{errors.inStock?.message}</span>}
                                    </div>
                                    {/* <div className="row mb-1">
                                        <div className="col-md">
                                            <div className="mb-1">
                                                <label htmlFor="typeProduct" className="form-label">Тип продукта</label>
                                                <select id="typeProduct" name="typeProduct" defaultValue={""} className="form-select"
                                                    {...register("typeProduct")} >
                                                    {productParams.typeProduct ?
                                                        productParams.typeProduct.map(typeProduct =>
                                                            <option key={typeProduct.id} value={typeProduct.id}>{typeProduct.name}</option>
                                                        ) : <option disabled>Нет доступных категорий</option>}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-1">
                                                <label htmlFor="taxProduct" className="form-label">Налоговая ставка</label>
                                                <select id="taxProduct" name="taxProduct" defaultValue={""} className="form-select"
                                                    {...register("taxProduct")} >
                                                    {productParams.taxProduct ?
                                                        productParams.taxProduct.map(taxProduct =>
                                                            <option key={taxProduct.id} value={taxProduct.id}>{taxProduct.name}</option>
                                                        ) : <option disabled>Нет доступных категорий</option>}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="mb-1">
                                                <label htmlFor="calculationMethod" className="form-label">Метод расчета</label>
                                                <select id="calculationMethod" name="calculationMethod" defaultValue={""} className="form-select"
                                                    {...register("calculationMethod")} >
                                                    {productParams.calculationMethod ?
                                                        productParams.calculationMethod.map(calculationMethod =>
                                                            <option key={calculationMethod.id} value={calculationMethod.id}>{calculationMethod.name}</option>
                                                        ) : <option disabled>Нет доступных категорий</option>}
                                                </select>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="mb-2 d-flex">
                                        <div className=" form-check form-switch">
                                            <label className="form-check-label">Требует серийный номер</label>
                                            <input onClick={(e) => onRequiredSNChenge(e)} type="checkbox"
                                                className="form-check-input check-input-lc" {...register("requiredSN")} />
                                        </div>
                                    </div>
                                    <div className={`mb-1 ${requiredSNInput ? "" : "d-none"}`}>
                                        <label htmlFor="maskSN" className="form-label">Маска серийного номера</label>
                                        <input id="maskSN" name="maskSN" type="text" className="form-control"
                                            {...register("maskSN")} />
                                        {errors.maskSN && <span className='text-danger'>{errors.maskSN?.message}</span>}
                                    </div>
                                </div>
                                <div className="col-xl">
                                    <label htmlFor="photo" className="form-label">Изображение продукта</label>
                                    <div className="card mb-3">
                                        <div className="mb-3 text-center">
                                            <img src={photo ? `${photo}` : `/NoPhoto.png`} className="img-fluid" alt="Product photo" />
                                        </div>
                                    </div>
                                    <div>
                                        <input onChange={onImageChange}
                                            id="photo"
                                            name="photo"
                                            className="form-control form-control-sm"
                                            accept=".jpg, .jpeg, .png" type="file" />
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <ButtonGroup>
                                <Button color="success">
                                    Добавить
                                </Button>
                                <Button color="warning" onClick={toggle}>
                                    Отмена
                                </Button>
                            </ButtonGroup>
                        </ModalFooter>
                    </form>
                }
            </Modal>
        </div>
    )
}