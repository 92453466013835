import { useNavigate } from "react-router-dom";
import { Card, CardBody, Container } from "reactstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { CaruselHome } from "./Home/CaruselHome";
import { ProductsPromo } from "./Home/ProductsPromo";
import { ProductsHit } from "./Home/ProductsHit";
import { useEffect } from "react";
import { CaruselCatalog } from "./Home/CaruselCatalog";
import { Helmet } from "react-helmet";
import { Feedback } from "./UI/Feedback";
import { ContactForm } from "./UI/ContactFom";



export const Home = ({ addItem }) => {

  // const navigate = useNavigate()

  return (
    <>
      <Helmet>
        <title>{`balloon.spb - Воздушные шары на заказ: индивидуальный подход к вашему празднику`}</title>
        <meta name="description" content={`balloon.spb - Воздушные шары на заказ: индивидуальный подход к вашему празднику`} />
        <meta name="og:description" content={`balloon.spb - Воздушные шары на заказ: индивидуальный подход к вашему празднику`} />
        <meta name="keywords" content="воздушные шары,латексныешары,фольгированные шары,гелиевые шары,шары с рисунками,шары с надписями, шары для праздника, шары для оформления, шары на день рождения, шары на свадьбу, шары на корпоратив, доставка шаров, цены на шары" />
      </Helmet>
      {/* <div className="">
        <Container>
        </Container>
      </div> */}
      <Container>
        <CaruselHome />
      </Container>
      <CaruselCatalog />
      <Container className="mt-3">
        <ProductsPromo addItem={addItem} />
        <ProductsHit addItem={addItem} />
        <Feedback />
        <ContactForm />
      </Container>
      <div id="feedback_vk">
      </div>
    </>
  )
}