import { useEffect } from "react";
import { Button, ButtonGroup, Modal } from "react-bootstrap"
import { useForm } from "react-hook-form";
import { usePutVariationMutation } from "../../../features/CallApi";


export const VariationEdit = ({ varState, varEditShow, refetch, setVarEditShow }) => {
    const { register, handleSubmit, reset, formState: { errors }, setValue } = useForm();
    const [putVariation] = usePutVariationMutation()

    useEffect(() => {
        if (varState) {
            setValue("id", varState.id)
            setValue("name", varState.name)
            setValue("price", varState.price)
        }
    }, [varEditShow])
    const put = (data) => {
        console.log(data)
        putVariation(data).unwrap().then((payload) => {
            console.log("fulfilled",payload)
            reset()
            setVarEditShow(false)
        }).catch((e) => {
            console.error(e)
        })

        refetch()
    }




    return (
        <Modal show={varEditShow} animation={true} onHide={() => {
            setVarEditShow(false)
            reset()
        }}>
            <Modal.Header closeButton>{varState?.id}</Modal.Header>
            <form onSubmit={handleSubmit(put)}>
                <input disabled className="d-none" {...register("id")} />
                <Modal.Body >
                    <div className="mb-1">
                        <label htmlFor="name" className="form-label">Наименование</label>
                        <input id="name" placeholder="Наименование" name="name" className="form-control"
                            {...register("name", { required: { value: true, message: "Укажите наименование" } })} />
                        {errors.name && <span className='text-danger'>{errors.name?.message}</span>}
                    </div>
                    <div className="mb-1">
                        <label htmlFor="price" className="form-label">Цена</label>
                        <input id="price" name="price" step={.01} placeholder="Цена" type={"number"} className="form-control"
                            {...register("price", { required: { value: true, message: "Укажите цену" } })} />
                        {errors.price && <span className='text-danger'>{errors.price?.message}</span>}
                    </div>
                    {/* <div className="mb-1">
                        <label htmlFor="description" className="form-label">Описание</label>
                        <textarea id="description" name="description" placeholder="Описание"
                            className="form-control" {...register("description", { required: false })}></textarea>
                        {errors.price && <span className='text-danger'>{errors.description?.message}</span>}
                    </div> */}
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGroup size="sm">
                        <Button type="submit" variant="success">Сохранить</Button>
                    </ButtonGroup>
                </Modal.Footer>
            </form>
        </Modal>
    )
}