import { Card, CardBody, Col, Row, CardHeader, Button, CardFooter, ButtonGroup } from "reactstrap";
import { TrashFill, ArrowRepeat } from "react-bootstrap-icons"
import { useEffect, useState } from "react";
import LoadScreen from "../../UI/LoadScreen";


export const Basket = ({ basket, updateBasket, removeItem, updateItem, setModal }) => {

    const [summ, setSumm] = useState(0)

    
    useEffect(() => {
        if (basket) {
            const sum = basket.reduce((summa, item) => {
                return summa + (parseFloat(item.price) * item.quantity);
            }, 0);
            setSumm(sum)
        }
    }, [basket])


    return (
        <div className="mt-5">
            <Card>
                <CardHeader>
                    <h3>Корзина</h3>
                </CardHeader>
                <CardBody >
                    {updateBasket ? <LoadScreen /> :
                        <div>
                            <div>
                                {basket && basket.length > 0 ? <div>{basket.map(product =>

                                    <div className="border-bottom mb-3 p-2 pb-3 show-bg" key={product.sn ? product.sn : product.id}>
                                        <div className="d-flex ">
                                            <div className="align-self-center me-3">
                                                <ButtonGroup>
                                                    <Button data-id={product.id} data-sn={product.sn} onClick={(e) => { removeItem(e) }} color="danger"><TrashFill size={18} /></Button>
                                                </ButtonGroup>
                                            </div>
                                            <div>
                                                <div className="d-flex">
                                                    <div>
                                                        <strong>{product.name}</strong>
                                                    </div>
                                                    {product.sn &&
                                                        <div className="ms-3">
                                                            С/Н: <strong>{product.sn}</strong>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="d-flex">

                                                    <div className="align-self-center text-end flex-grow-1">
                                                        Цена: {product.price} руб.
                                                    </div>
                                                    <div className="align-self-center pe-2 ps-2">X</div>
                                                    <div className="pe-2 ps-2 align-self-center">
                                                        {product.sn == null || product.sn == "" ?
                                                            <div className="input-group input-group-sm">
                                                                <input style={{ maxWidth: "75px" }} type="number" min={0} className="form-control form-control-sm text-center"
                                                                    onChange={(e) => {
                                                                        e.target.closest("div").querySelector("button").classList.remove("d-none")
                                                                        e.target.closest("div").querySelector("button").classList.add("btn-warning")
                                                                    }}
                                                                    defaultValue={product.quantity} />
                                                                <span className="input-group-text" id="basic-addon2">шт.</span>
                                                                <Button data-id={product.id} onClick={(e) => { updateItem(e) }} className="d-none" color="warning"><ArrowRepeat size={18} /></Button>
                                                            </div>
                                                            :
                                                            <div className="input-group input-group-sm">
                                                                <input style={{ maxWidth: "75px" }} type="number" className="form-control form-control-sm text-center" disabled value={product.quantity} />
                                                                <span className="input-group-text" id="basic-addon2">шт.</span>
                                                            </div>

                                                        }


                                                    </div>
                                                    <div className="align-self-center"> = {(parseFloat(product.price) * product.quantity)} руб.</div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                </div> : <div className="text-center mt-3 mb-3">Корзина пустая</div>}
                            </div>
                            <div>
                                Сумма заказа: {summ} рублей
                            </div>
                        </div>
                    }
                </CardBody>
                {basket && basket.length > 0 &&
                    <CardFooter>
                        <div className="text-center mt-2 mb-2">
                            <Button color="success" onClick={() => { setModal(true) }}>Оформить заказ</Button>
                        </div>
                    </CardFooter>
                }
            </Card>
        </div >
    )

}