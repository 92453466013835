import { Button, Card, CardBody } from "reactstrap"
import { Link, useNavigate } from "react-router-dom";
import { Image, Badge, ToastBody } from "react-bootstrap";
import { Arrow90degDown, Cart2, Cart4 } from "react-bootstrap-icons";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { EffectFade, Navigation, Pagination } from "swiper/modules";
// import { EffectCards } from 'swiper/modules';


export const ProductCard = ({ product, addItem }) => {

    const navigate = useNavigate();
    const toNav = (link) => {
        window.scrollTo(0, 0)
        navigate(link)
    }
    const add = (id) => {
        const data = {
            id: id,
            quantity: 1
        }
        console.log(data)
        addItem(data)
    }

    return (
        // <div style={{ width: "300px", padding: "10px" }}>
        <Card className="mb-3 bg-none border-0">

            <CardBody>
                <Link to={`/catalog/product/${product.seoUrl}`} style={{ textDecoration: 'none' }}>
                    <div className="text-center m-auto box-productBB-card position-relative" >
                        {product.photos.length > 0 ?
                            <Swiper
                                // spaceBetween={30}
                                // effect={'fade'}
                                // navigation={{
                                //     navigation: true,
                                //     // nextEl: '.nextBB',
                                //     prevEl: '.prevBB'
                                // }}
                                pagination={{
                                    clickable: true,
                                }}
                                loop={true}
                                modules={[Navigation, Pagination]}
                            >
                                {product.photos.map(item =>
                                    <SwiperSlide key={item.id}>
                                        <img src={`/api/products/photo/${item.id}`} className="img-productBB-card img-fluid rounded" alt={`Product-${product.name}`} />
                                    </SwiperSlide>
                                )}
                            </Swiper>
                            :
                            <img src={`/NoPhoto.png`} className="img-fluid" alt={`Product-${product.name}`} />
                        }
                        {/* <img src={product.imageType ? `/api/products/photo/${product.id}` : `/NoPhoto.png`} className="img-fluid" alt={`Product-${product.name}`} /> */}

                        {product.hit &&
                            <span className="position-absolute top-0 translate-middle badge rounded-pill bg-danger" style={{ left: "75%", top: "10%", zIndex: "100" }}>
                                ХИТ!
                            </span>
                        }
                        {product.discount > 0 &&
                            <span className="position-absolute top-0 translate-middle badge rounded-pill bg-danger" style={{ left: "75%", top: "10%", zIndex: "100" }}>
                                Акция
                            </span>
                        }
                        {/* <img src="/NoPhoto.png" className="img-fluid border m-auto" /> */}
                    </div>
                </Link>
                <div className="text-center text-wrap fs-6 product-cardBB-title">
                    <strong>{product.name}</strong>
                </div>
                {product.inStock > 0 ?
                    <div>

                        <div className="text-center h5">

                            <strong>
                                {product.variations.length > 0 ?
                                    <>
                                        <>{product.variations[0].price} ₽ - {product.variations.at(-1).price} ₽ </>
                                    </>
                                    :
                                    <>
                                        {product.discount > 0 ?
                                            <div >

                                                <span style={{ position: "relative" }}>
                                                    <span className="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-danger">
                                                        {((product.discount / product.price) * 100 - 100)} %
                                                    </span>
                                                    <strike>{product.price} ₽</strike> = </span>
                                                <span style={{ color: "red" }}>{product.discount} ₽</span>
                                                {/* <div><span className="translate-middle badge rounded-pill bg-danger">{(product.discount - product.price)}</span></div> */}
                                            </div>
                                            :
                                            <div>
                                                {product.price} ₽
                                            </div>
                                        }
                                    </>
                                }

                            </strong>
                        </div>
                        <div className="mt-2 mb-2 text-center">
                            {product.variations.length > 0 ?
                                <>
                                    <Button className="rounded-pill btn-balloon" onClick={() => { toNav(`/catalog/product/${product.seoUrl}`) }}>Выбрать</Button>
                                </>
                                :
                                <Button onClick={() => {
                                    // alert()
                                    // console.log(product.id)
                                    add(product.id)
                                }
                                } className="rounded-pill btn-balloon">Заказать <Cart2 className="pb-1" size={24} />
                                </Button>
                            }
                        </div>
                    </div>
                    :
                    <div className="mt-1 mb-1 text-center text-muted">
                        Нет в наличии
                    </div>
                }
                <div className="text-muted " style={{ fontSize: "14px" }}>арт. {product.sku}</div>
            </CardBody>
        </Card >
        // </div>
    )
}