export const UserRole = ({ roleId }) => {
    let result;
    switch (roleId) {
        case 0: result = <span className="text-info">Администратор системы</span>;
        break;
        case 1: result = <span className="text-warning">Бухгалтер</span>;
        break;
        case 2: result = <span className="text-success">Менеджер</span>;
        break;
        default: result = <span>Некорректные данные</span>
    }
    return result;
}