import { Button, Modal, ModalHeader, ModalBody, Card, CardHeader, CardBody, CardFooter } from 'reactstrap';
import { useForm } from "react-hook-form";
import { Search } from 'react-bootstrap-icons';
import { useCreateOrderMutation, useGetClientsByPhoneMutation } from "../../../features/CallApi";
import { useState } from "react";
import LoadScreen from '../../UI/LoadScreen';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const OrderCreate = ({ modal, setModal, basket, resetBasket }) => {
    const [getClientsByPhone, { isLoading: isPosting }] = useGetClientsByPhoneMutation();
    const [createOrder] = useCreateOrderMutation()
    const [deliverySelect, setDeliverySelect] = useState(0)
    const { register, handleSubmit, formState: { errors }, reset, setValue, getValues } = useForm();
    // const [registred, setRegistred] = useState(false);
    const [client, setClient] = useState();
    // const [postError, setPostError] = useState();
    const navigate = useNavigate();

    const getCliet = async (e) => {
        const phone = e.currentTarget.closest("div").querySelector("input").value
        getClientsByPhone(phone).then((response) => {
            if (response.error?.data) {
                console.log(response)
                setClient(null)
            }
            else {
                setClient(response.data)
            }
        }).catch((e) => {
            console.log(e)
        })
        e.target.closest("button").classList.add("d-none")
        e.target.closest(".card-body").querySelector("[name=firstName]").value = client?.firstName
    }

    useEffect(() => {
        if (client) {
            setValue("firstName", client ? client.firstName : "")
            setValue("lastName", client ? client.lastName : "")
            setValue("patronymic", client ? client.patronymic : "")
            setValue("email", client ? client.email : "")
        }
    }, [client])
    const toggle = () => setModal(!modal);

    const create = (data) => {
        let adress = "";
        console.log(data.typeDelivery)
        if (data.typeDelivery == 0) {
            adress = data.city + ", " + data.street + ", д." + data.houseNumber +
                (data.extension ? ", корп. " + data.extension : "") +
                (data.apartment ? ", кв. " + data.apartment : "") +
                (data.intercom ? ", (домофон: " + data.intercom + ")" : "")
        }
        else if (data.typeDelivery == 1) {
            adress = "Самовывоз";
        }
        const deliveryDate = data.dateDelivery + " " + data.timeDelivery;
        const body = {
            client: data,
            basket: basket,
            adress: adress,
            comments: data.comments,
            deliveryDate: deliveryDate
        }
        console.log(body)
        createOrder(body).unwrap().then((response) => {
            resetBasket()
            reset()
            navigate(`/admin/orders/details/${response}`)
        })
    }

    return (
        <div>
            <Modal isOpen={modal} toggle={toggle} size='lg'>
                <ModalHeader toggle={toggle}>Оформление заказа</ModalHeader>
                <ModalBody>
                    <div>
                        <div>
                            {isPosting ? <LoadScreen /> :
                                <Card className="">
                                    <CardHeader >
                                        <div className={'d-flex justify-content-center'}>
                                            <h3 className={'flex-fill text-center'}>Анкета гостя</h3>
                                        </div>
                                    </CardHeader>
                                    <form onSubmit={handleSubmit(create)}>
                                        <CardBody>
                                            <div className="mb-3 d-flex">
                                                <div className="form-group flex-fill m-1">
                                                    <label htmlFor="lastName" className="form-label">Фамилия</label>
                                                    <input type="text" placeholder="Фамилия" className="form-control"
                                                        {...register("lastName", { required: { value: true, message: "Поле \"Фамилия\" не может быть пустым" } })} />
                                                    {errors.lastName && <span className='text-danger'>{errors.lastName?.message}</span>}
                                                </div>
                                                <div className="form-group flex-fill m-1">
                                                    <label htmlFor="firstName" className="form-label">Имя</label>
                                                    <input type="text" defaultValue={client?.firstName} className="form-control" placeholder="Имя" {...register("firstName",
                                                        { required: { value: true, message: "Поле \"Имя\" не может быть пустым" } })} />
                                                    {errors.firstName && <span className='text-danger'>{errors.firstName?.message}</span>}
                                                </div>
                                                <div className="form-group flex-fill m-1">
                                                    <label htmlFor="patronymic" className="form-label">Отчество</label>
                                                    <input type="text" className="form-control" placeholder="Отчество" {...register("patronymic", {})} />
                                                </div>
                                            </div>
                                            <div className="mb-3 d-flex">
                                                <div className="form-group flex-fill m-1">
                                                    <label className="form-label">Телефон</label>
                                                    <div className='input-group'>
                                                        <input type="tel" className="form-control" placeholder="+79999999999" {...register("phone",
                                                            {
                                                                required: { value: true, message: "Поле \"Телефон\" не может быть пустым" },
                                                                onChange: (e) => {
                                                                    e.target.closest("div").querySelector("button").classList.remove("d-none")
                                                                }
                                                            })} />
                                                        <Button className='d-none' onClick={(e) => {
                                                            getCliet(e)
                                                        }}><Search size={14} /></Button>
                                                    </div>
                                                    {errors.phone && <span className='text-danger'>{errors.phone?.message}</span>}
                                                </div>
                                                <div className="form-group flex-fill m-1">
                                                    <label className="form-label">E-Mail</label>
                                                    <input type="email" className="form-control" placeholder="Email" {...register("email", {
                                                        pattern: { value: /^\S+@\S+$/i, message: "Некорректно указан E-Mail" },
                                                    })}
                                                    />
                                                    {errors.email && <span className='text-danger'>{errors.email?.message}</span>}
                                                </div>
                                            </div>

                                            <div className="mb-1 d-flex border-top"><h5>Доставка</h5></div>
                                            <div className='mb-1 row'>
                                                <div className="col-lg row g-2">
                                                    <label className="form-label">Дата и время доставки</label>
                                                    <div className='col g-2'>
                                                        <input type="date" className="form-control" min={new Date().toISOString().split("T")[0]} placeholder="" {...register("dateDelivery", { required: true, message: "Укажите дату доставки" })} />
                                                    </div>
                                                    <div className='col g-2'>
                                                        <input type="time" className="form-control" placeholder="" {...register("timeDelivery", { required: true, message: "Укажите дату время" })} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mb-1 row'>
                                                <div className='col g-2'>
                                                    <label className='form-label' htmlFor="comments">Комментарий</label>
                                                    <textarea className="form-control"
                                                    {...register("comments")}
                                                    placeholder="Комментарий" id="comments" style={{height: "100px"}}></textarea>
                                                </div>
                                                <label>

                                                </label>
                                            </div>
                                            <div className='mb-1 row'>
                                                <div className="form-group col-md g-2">
                                                    <label className="form-label">Условия получения</label>
                                                    <select className='form-select' {...register("typeDelivery", { onChange: () => { setDeliverySelect(getValues("typeDelivery")) } })}>
                                                        <option value={0}>Доставка</option>
                                                        <option value={1}>Самовывоз</option>
                                                    </select>
                                                </div>
                                            </div>

                                            {(deliverySelect == 0) && <div>
                                                <div className='mb-1 row'>
                                                    <div className="form-group col-lg g-2">
                                                        <label className="form-label">Город</label>
                                                        <input type="text" className="form-control" value={"Санкт-Петербург"} placeholder="Город" {...register("city", {})} />
                                                    </div>
                                                    <div className="form-group col-lg g-2">
                                                        <label className="form-label">Улица</label>
                                                        <input type="text" className="form-control" placeholder="Улица" {...register("street", { required: true, message: "Укажите улицу" })} />
                                                    </div>
                                                </div>
                                                <div className='mb-1 row'>
                                                    <div className="form-group col-sm g-2">
                                                        <label className="form-label">Дом</label>
                                                        <input type="text" className="form-control" placeholder="Дом" {...register("houseNumber", { required: true, message: "Укажите дом" })} />
                                                    </div>
                                                    <div className="form-group col-sm g-2">
                                                        <label className="form-label">Корпус</label>
                                                        <input type="text" className="form-control" placeholder="Корпус" {...register("extension", {})} />
                                                    </div>
                                                    <div className="form-group col-sm g-2">
                                                        <label className="form-label">Квартира</label>
                                                        <input type="text" className="form-control" placeholder="Квартира" {...register("apartment", {})} />
                                                    </div>
                                                    <div className="form-group col-sm g-2">
                                                        <label className="form-label">Домофон</label>
                                                        <input type="text" className="form-control" placeholder="Домофон" {...register("intercom", {})} />
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                        </CardBody>
                                        <CardFooter>
                                            <input type="submit" id="submit" value="Оформить" className="btn btn-info m-1 text-white" />
                                        </CardFooter>
                                    </form >
                                </Card>
                            }
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}