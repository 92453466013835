import { useState, useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Container } from 'reactstrap';
import { useGetCatalogProductsQuery } from '../../../features/CallApi';
import LoadScreen from '../../UI/LoadScreen';
import { Row, Col } from 'reactstrap';
import { ProductCard } from './ProductCard';
import useBasket from '../../../features/useBasket';
import { Basket } from './Basket';
import { OrderCreate } from '../Orders/OrderCreate';


export const ProductCatalog = () => {

    const { data, refetch } = useGetCatalogProductsQuery();
    const { addItem, removeItem, resetBasket, basket, updateBasket, updateItem } = useBasket();
    const [modal, setModal] = useState(false);



    useEffect(() => { refetch() }, [])

    return (
        <Container fluid className='mb-3'>
            <Row className=''>
                <Col lg="6">
                    {data ?
                        <div>
                            {data && data.length > 0 ?
                                <Tabs defaultActiveKey={data[0].id} className="mb-3">
                                    {data.map(Category =>
                                        <Tab key={Category.id} eventKey={Category.id} title={Category.name}>
                                            {Category.products.length === 0 && <div className='text-center mb-5'><strong>Нет продуктов в категории</strong></div>}
                                            {Category.products.map(product =>
                                                <ProductCard key={product.id} product={product} addItem={addItem} />
                                            )}
                                        </Tab>
                                    )}
                                </Tabs> :
                                <div className='text-center'>Каталог товаров пуст</div>
                            }
                        </div>
                        :
                        <LoadScreen />
                    }
                </Col>
                <Col>
                    <Basket basket={basket} updateBasket={updateBasket} removeItem={removeItem} updateItem={updateItem} setModal={setModal} />
                </Col>
            </Row>
            <OrderCreate resetBasket={resetBasket} modal={modal} basket={basket} setModal={setModal} />
        </Container>
    )
}