import { Container } from "react-bootstrap"


export const WarrantyRules = () => {

    return (
        <Container>
            <div>
                <div>
                    <div>
                        <h2>Гарантия и возврат</h2>
                    </div>
                </div>
                <div>
                    <div>
                        <p>
                            Воздушные шары, приобретенные в магазине, нельзя обменять или вернуть (Закон РФ № 2300-1 «О защите прав потребителей», принятый 7 февраля 1992 года (в ред. от 25 октября 2007 года), далее – Закон,
                            Постановление Правительства РФ № 55 от 19 января 1998 года (в ред. от 27 марта 2007 года)).
                            Перечисленные выше документы содержат список непродовольственных товаров, которые не подлежат обмену или возврату.
                        </p>
                        <p>
                            При обнаружении недостатков доставленного заказа, потребитель вправе заменить его на такой же, новый (статья 18 закона РФ от 07.02.1992 N 2300-1 (ред. от 04.06.2018) «О защите прав потребителей»).
                            Претензия принимается в момент передачи заказа потребителю. После передачи ответственность за целостность товара продавец не несет.
                            Если потребитель приобретает товар через интернет-магазин, покупка считается совершенной после того, как служба доставки передаст товар покупателю.
                        </p>
                        <p>
                            Гарантия на воздушные шары не предусмотрена (пункт 7 статья 5 закона РФ от 07.02.1992 N 2300-1 (ред. от 04.06.2018) «О защите прав потребителей»).
                        </p>
                    </div>
                    <h3>
                        Гарантийные случаи
                    </h3>
                    <ul>
                        <li>От 10% шаров упали в течение 2-х часов после доставки клиенту</li>
                        <li>Шары сдулись более чем на 50% в течение указанного времени</li>
                    </ul>
                    <p>
                        В случае возникновения ситуации, требующей возврата средств по гарантийным условиям, просим присылать фото на адрес: order@deltaplanes.ru
                    </p>
                    <p>В случае нашей вины возможны следующие варианты гарантийного возврата:
                        <ul>
                            <li>Замена приобретенных шаров </li>
                            <li>Предоставление скидки на следующий заказ, которая будет равна стоимости бракованных шаров</li>
                            <li>Возврат денежных средств *</li>
                        </ul>
                        * Возврат денежных средств производится в течении 72 часов с момента обращения клиента. Возврат денежных средств осуществляется после разбора и установления гарантийного случая, в случае если замена воздушных шаров ненадлежащего качества не возможна. Возврат денежных средств производится тем же способом, которым воспользовался клиент при оплате заказа. Мы всегда оперативно и максимально объективно пытаемся разобраться в гарантийной ситуации, всегда остаёмся на стороне клиента и добросовестно выполняем свои обязательства!
                    </p>
                </div>
                <div>
                    <div>
                        <h4>
                            Важные рекомендации для продления полёта шаров
                        </h4>
                    </div>
                    <ul style={{ listStyle: 'decimal' }}>
                        <li>
                            Беречь от попадания солнечных лучей
                            Латекс способен разрушаться на солнце, от этого
                            шар будет летать намного меньше.
                        </li>
                        <li>
                            Шары должны находиться в состоянии покоя.
                            Если дергать их за ленточки, играть с ними - шары не
                            будут долго летать.
                        </li>
                        <li>
                            Нахождение шаров на улице неблагоприятно.
                            Солнце, ветер, дождь и пыль разрушают
                            латекс.
                        </li>
                        <li>
                            Шары не переносят влажный воздух.
                            В помещениях с повышенной влажностью шары
                            могут упасть через 2–3  дня или чуть позже. В основном
                            такие случаи бывают, когда в семье есть
                            маленький ребёнок, и в квартире очень душно.
                        </li>
                        <li>
                            Шары не любят сквозняки. Закрывайте окна в комнате,
                            где находятся шары и не включайте увлажнитель.
                        </li>
                        <li>
                            Оставлять шары в машине запрещено!
                        </li>
                        <li>
                            После получения заказа необходимо достать
                            шары из пакета! Длительное нахождение шаров в пакете
                            неблагоприятно сказывается не только на их
                            внешнем виде (могут остаться следы перфорации),
                            но и на полёте.
                        </li>
                    </ul>
                </div>
            </div>
        </Container>
    )
}