import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Grid, Navigation, Pagination } from "swiper/modules";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useGetCategoriesCaruselQuery } from "../../features/CallApi";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react";


export const CaruselCatalog = () => {

    const { data } = useGetCategoriesCaruselQuery()
    const navigate = useNavigate();

    return (
        <Container className="mt-3 mb-3">
            <h3>Каталог</h3>
            {data?.length > 0 &&
                <Row>
                    {data.map(item =>
                        <Fragment key={item.id}>
                            <Col xs={6} md={4} lg={3} xl={2}>
                                <div style={{
                                    backgroundColor: "#887e7e",
                                    borderRadius: 10,
                                    position: 'relative',
                                    cursor: 'pointer',
                                }}
                                    className="mb-2 catalogCard"
                                    onClick={() => {
                                        navigate(`/catalog?selId=${item.id}`)
                                    }}
                                >
                                    <div className="shadow catalogCardImg"
                                        style={{
                                            paddingBottom: "90%",
                                            borderRadius: 10,
                                            // border: "black 2px solid",
                                            backgroundPosition: 'center',
                                            // backgroundSize: 'cover',
                                            backgroundRepeat: 'no-repeat',
                                            opacity: 0.8,
                                            backgroundImage: `url(${item.imageType ? `/api/categories/image/${item.id}` : "/NoPhoto.png"})`
                                        }}
                                    >

                                    </div>

                                </div>
                                <div className="text-center pb-3" style={{
                                    // color: "#fff",
                                    fontSize: '18px',
                                    // textShadow: `1px 1px 4px black`
                                }}
                                >
                                    {item.name}
                                </div>
                            </Col>
                        </Fragment>
                    )}
                </Row>
            }
        </Container >
    )
}