import React, { useEffect } from "react"
import { useParams, useNavigate } from 'react-router-dom';
import { Container } from 'reactstrap'
import { useForm } from "react-hook-form"
import LoadScreen from "../../UI/LoadScreen";
import { useGetUserByIdQuery, usePutUserByIdMutation } from "../../../features/CallApi";

export const UserEdit = () => {
    const { id } = useParams();
    const { data: userData, isError, error, isLoading: getIsLoading, refetch } = useGetUserByIdQuery(id)
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [putUserById, { isLoading: isUpdating }] = usePutUserByIdMutation();
    const navigate = useNavigate();

    useEffect(() => {
        // userData ? refetch()
    }, [])

    const onSubmit = (data) => {


        putUserById({ id, ...data })
            .unwrap()
            .then(() => {
                refetch()
                navigate('/admin/users')
            })
            .catch((e) => alert("Status: " + e.status + " Message:" + e.data.title))

    }


    return (
        <Container>
            {getIsLoading || isUpdating ? <LoadScreen /> : <div>
                {isError && <h4>Ошибка запроса: {error.status} (код: {error.originalStatus})</h4>}

                {userData ?
                    <form className="" onSubmit={handleSubmit(onSubmit)}>
                        <input hidden value={userData.id} {...register("id", {})} />
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">E-Mail</label>
                            <input id="email" name="email" placeholder="Укажите E-Mail" type="string" className="form-control"
                                {...register("email", {
                                    required: { value: true, message: "Поле \"E-Mail\" не может быть пустым" },
                                    pattern: { value: /^\S+@\S+$/i, message: "Некорректно указан E-Mail" }
                                })} defaultValue={userData.email} />
                            {errors.email && <span className='text-danger'>{errors.email?.message}</span>}
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="mb-3">
                                    <label htmlFor="firstName" className="form-label">Имя</label>
                                    <input id="firstname" name="firstname" placeholder="Укажите имя" type="text" className="form-control" defaultValue={userData.firstname}
                                        {...register("firstname", { required: { value: true, message: "Поле \"Имя\" не может быть пустым" } })} />
                                    {errors.firstname && <span className='text-danger'>{errors.firstname?.message}</span>}
                                </div>
                            </div>
                            <div className="col">
                                <div className="mb-3">
                                    <label htmlFor="lastname" className="form-label">Фамилия</label>
                                    <input id="lastname" name="lastname" placeholder="Укажите фамилию" type="text" className="form-control" defaultValue={userData.lastname}
                                        {...register("lastname", { required: { value: true, message: "Поле \"Фамилиия\" не может быть пустым" } })} />
                                    {errors.lastname && <span className='text-danger'>{errors.lastname?.message}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="roleId" className="form-label">Роль</label>
                            <select id="roleid" defaultValue={userData.roleId} name="roleid" className="form-select"
                                {...register("roleid", { required: { value: true, message: "Выберите роль пользователя" } })} >
                                        <option value={0}>Администратор</option>
                                        <option value={1}>Бухгалтер</option>
                                        <option value={2}>Менеджер</option>
                            </select>
                            {errors.lastname && <span className='text-danger'>{errors.lastname?.message}</span>}
                        </div>
                        <div className="mb-2 form-check form-switch">
                            <label className="form-check-label">Активен</label>
                            <input type="checkbox" defaultChecked={userData.status} className="form-check-input" {...register("status", {})} />
                        </div>
                        <div className="mb-3">
                            <div role="group" className="btn-group-sm btn-group">
                                <input className="btn btn-success btn btn-secondary" type="submit" value={'Сохранить'} />
                                {/* <button type="button" className="">Сохранить</button> */}
                                <button type="button" className="btn btn-secondary btn btn-secondary" onClick={() => navigate('/admin/users')}>Назад</button>
                            </div>
                        </div>
                    </form>
                    : <div></div>
                }
            </div>}
        </Container>
    )
}