import { useState } from "react";
import { MasonryPhotoAlbum } from "react-photo-album";
import "react-photo-album/rows.css";
import "react-photo-album/masonry.css";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

export const Feedback = () => {

    const [index, setIndex] = useState(-1);
    // const photos = [
    //     { src: "/feedback/1.jpg", },
    //     { src: "/feedback/1.jpg", },
    //     { src: "/feedback/1.jpg", },
    //     { src: "/feedback/1.jpg", },
    //     { src: "/feedback/1.jpg", },
    //     { src: "/feedback/1.jpg", },
    //     { src: "/feedback/1.jpg", },
    //     { src: "/feedback/1.jpg", },
    //     { src: "/feedback/1.jpg", },
    //     { src: "/feedback/1.jpg", },
    //     { src: "/feedback/1.jpg", },
    //     { src: "/feedback/1.jpg", },
    // ]

    const photos = [
        { src: "/feedback/1.jpg", width: 1150, height: 1280 },
        { src: "/feedback/2.jpg", width: 1280, height: 1070 },
        { src: "/feedback/3.jpg", width: 1253, height: 1202 },
        { src: "/feedback/4.jpg", width: 1280, height: 875 },
        { src: "/feedback/5.jpg", width: 1280, height: 892 },
        { src: "/feedback/6.jpg", width: 1280, height: 874 },
        { src: "/feedback/7.jpg", width: 1280, height: 505 },
        { src: "/feedback/8.jpg", width: 1280, height: 665 },
        { src: "/feedback/9.jpg", width: 1280, height: 510 },
        { src: "/feedback/10.jpg", width: 1280, height: 531 },
        { src: "/feedback/11.jpg", width: 1280, height: 654 },
        { src: "/feedback/12.jpg", width: 1280, height: 524 },
        { src: "/feedback/13.jpg", width: 1254, height: 614 },
    ];

    return (
        <>
            <h3>Отзывы</h3>
            <MasonryPhotoAlbum
                photos={photos}
                // targetRowHeight={150}
                columns={4}

                onClick={({ index }) => setIndex(index)}
            />
            <Lightbox
                slides={photos}
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                // enable optional lightbox plugins
                plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
            />
        </>
    )
}