import { useState } from "react"
import { Button, ButtonGroup, Modal } from "react-bootstrap"
import { useForm } from "react-hook-form";
import { useAddVariationMutation } from "../../../features/CallApi";



export const VariationAdd = ({ product, refetch }) => {

    const [show, setShow] = useState(false)
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const add = (data) => {
        data.productId = product.id
        console.log(data)
        addVariation(data).unwrap().then(() => {
            refetch()
            reset()
            setShow(false)
        }).catch((e) => {
            console.error(e)
        })

    }
    const [addVariation, { isLoading: postIsloading }] = useAddVariationMutation()

    return (
        <div className="border-top mt-3">
            <h4>Вариации</h4>
            <Button onClick={() => setShow(true)} variant="success" size="sm" className="mt-3 mb-3">
                Добавить
            </Button>
            <Modal show={show} animation={true} onHide={() => {
                setShow(!show)
                reset()
            }}>
                <Modal.Header closeButton>
                    Добавление вариации
                </Modal.Header>
                <form className="" onSubmit={handleSubmit(add)}>
                    <Modal.Body>
                        <div className="mb-1">
                            <label htmlFor="name" className="form-label">Наименование</label>
                            <input id="name" placeholder="Наименование" name="name" className="form-control"
                                {...register("name", { required: { value: true, message: "Укажите наименование" } })} />
                            {errors.name && <span className='text-danger'>{errors.name?.message}</span>}
                        </div>
                        <div className="mb-1">
                            <label htmlFor="price" className="form-label">Цена</label>
                            <input id="price" name="price" step={.01} placeholder="Цена" type={"number"} className="form-control"
                                {...register("price", { required: { value: true, message: "Укажите цену" } })} />
                            {errors.price && <span className='text-danger'>{errors.price?.message}</span>}
                        </div>
                        <div className="mb-1">
                            <label htmlFor="description" className="form-label">Описание</label>
                            <textarea id="description" name="description" placeholder="Описание"
                                className="form-control" {...register("description", { required: false })}></textarea>
                            {errors.price && <span className='text-danger'>{errors.description?.message}</span>}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonGroup size="sm">
                            <Button type="submit" variant="success">Добавить</Button>
                            <Button variant="warning" onClick={() => {
                                reset()
                                setShow(false)
                            }}>
                                Закрыть
                            </Button>
                        </ButtonGroup>
                    </Modal.Footer>
                </form>
            </Modal>


        </div>
    )
}