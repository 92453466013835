import { useEffect, useState } from 'react';
import { Button, ButtonGroup, Container } from 'reactstrap';
import LoadScreen from "../../UI/LoadScreen";
import { useForm } from "react-hook-form"
import {
    useDeleteProductPhotoMutation,
    useGetCategoriesSelectQuery,
    useGetProductQuery,
    usePostProductPhotoMutation,
    usePutProductMutation
} from "../../../features/CallApi";
import { useParams, useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { JoditEditorInput } from '../../UI/JoditEditorInput';
// import { useEffect } from "react";
// import { withMask, withHookFormMask } from 'use-mask-input';

export const ProductEdit = ({ refetch }) => {

    const { register, handleSubmit, reset, formState: { errors }, setValue } = useForm();
    const { id } = useParams();
    const navigate = useNavigate();
    const toNav = (link) => {
        window.scrollTo(0, 0)
        navigate(link)
    }
    const { isLoading: productisLoading, data: product, error, refetch: refetchProduct } = useGetProductQuery(id)
    const [putProduct, { isLoading: putIsloading }] = usePutProductMutation();
    const [requiredSNInput, setRequiredSNInput] = useState(false);
    const [inActive, setInActive] = useState();
    const [hit, setHit] = useState();
    const [hide, setHide] = useState();
    const [description, setDescription] = useState()


    const onRequiredSNChenge = (e) => {
        setRequiredSNInput(e.target.checked)
    }
    const onInActiveChenge = (e) => {
        setInActive(e.target.checked)
    }

    const onHitChenge = (e) => {
        setHit(e.target.checked)
    }
    const onHideChenge = (e) => {
        setHide(e.target.checked)
    }

    useEffect(() => {
        refetchProduct()
    }, [])

    useEffect(() => {
        { product && setRequiredSNInput(product.requiredSN) }
        { product && setInActive(product.inActive) }
        if (product) {
            setDescription(product.description)
        }
        // { product && setValue("categoryId", product.category.id) }

    }, [product])



    const setPutProduct = (data) => {
        data.requiredSN = requiredSNInput
        data.inActive = inActive
        data.hit = hit
        data.hide = hide
        data.description = description
        data.category =
            putProduct(data).unwrap().then((e) => {
                setTimeout(() => { toNav(`/admin/products/details/${id}`) }, 100)
            }
            ).catch((e) => {
                console.log(e)
            })
    }

    return (
        <Container>
            <div>
                {productisLoading || putIsloading ? <LoadScreen></LoadScreen> :
                    <div>
                        {product &&
                            <form onSubmit={handleSubmit(setPutProduct)}>
                                <input hidden value={product.id} {...register("id", {})} />
                                <div className="row">
                                    <div className="col-xl">
                                        <div className="mb-1">
                                            <label htmlFor="name" className="form-label">Наименование</label>
                                            <input id="name" defaultValue={product.name} name="name" className="form-control"
                                                {...register("name", { required: { value: true, message: "Укажите наименование" } })} />
                                            {errors.name && <span className='text-danger'>{errors.name?.message}</span>}
                                        </div>
                                        <div className="mb-1">
                                            <label htmlFor="sku" className="form-label">Артикул</label>
                                            <input id="sku" name="sku" className="form-control" defaultValue={product.sku}
                                                {...register("sku", { required: { value: true, message: "Укажите артикул" } })} />
                                            {errors.sku && <span className='text-danger'>{errors.sku?.message}</span>}
                                        </div>
                                        <div className="mb-1">
                                            <label htmlFor="price" className="form-label">Цена</label>
                                            <input id="price" defaultValue={product.price} name="price" step={.01} type={"number"} className="form-control"
                                                {...register("price", { required: { value: true, message: "Укажите цену" } })} />
                                            {errors.price && <span className='text-danger'>{errors.price?.message}</span>}
                                        </div>
                                        <div className="mb-1">
                                            <label htmlFor="discount" className="form-label">Цена по акции</label>
                                            <input id="discount" defaultValue={product.discount} name="discount" step={.01} type={"number"} className="form-control"
                                                {...register("discount", { required: { value: true, message: "Укажите цену или 0" } })} />
                                            {errors.discount && <span className='text-danger'>{errors.discount?.message}</span>}
                                        </div>
                                        <div className="mb-1">
                                            <label htmlFor="description" className="form-label">Описание</label>
                                            <JoditEditorInput valueEditor={description} setValueEditor={setDescription} />
                                            {/* <textarea id="description" defaultValue={product.description} name="description" className="form-control" {...register("description")}></textarea> */}
                                            {errors.price && <span className='text-danger'>{errors.description?.message}</span>}
                                        </div>
                                        <div className="mb-1">
                                            <label htmlFor="inStock" className="form-label">Остаток шт.</label>
                                            <input id="inStock" defaultValue={product.inStock} name="inStock" step={1} type={"number"} className="form-control"
                                                {...register("inStock", { required: { value: true, message: "Укажите остаток" } })} />
                                            {errors.inStock && <span className='text-danger'>{errors.inStock?.message}</span>}
                                        </div>
                                        <div className="mb-2 d-flex">
                                            <div className=" form-check form-switch">
                                                <label className="form-check-label">Отключен</label>
                                                <input defaultChecked={product.inActive} onClick={(e) => onInActiveChenge(e)} type="checkbox"
                                                    className="form-check-input check-input-lc" {...register("inActive")} />
                                            </div>
                                        </div>
                                        <div className="mb-2 d-flex">
                                            <div className=" form-check form-switch">
                                                <label className="form-check-label">Хит</label>
                                                <input defaultChecked={product.hit} onClick={(e) => onHitChenge(e)} type="checkbox"
                                                    className="form-check-input check-input-lc" {...register("hit")} />
                                            </div>
                                        </div>
                                        <div className="mb-2 d-flex">
                                            <div className=" form-check form-switch">
                                                <label className="form-check-label">Скрыть</label>
                                                <input defaultChecked={product.hide} onClick={(e) => onHideChenge(e)} type="checkbox"
                                                    className="form-check-input check-input-lc" {...register("hide")} />
                                            </div>
                                        </div>
                                        <div className="mb-2 d-flex">
                                            <div className=" form-check form-switch">
                                                <label className="form-check-label">Требует серийный номер</label>
                                                <input defaultChecked={product.requiredSN} onClick={(e) => onRequiredSNChenge(e)} type="checkbox"
                                                    className="form-check-input check-input-lc" {...register("requiredSN")} />
                                            </div>
                                        </div>
                                        <div className={`mb-1 ${requiredSNInput ? "" : "d-none"}`}>
                                            <label htmlFor="maskSN" className="form-label">Маска серийного номера</label>
                                            <input defaultValue={product.maskSN} id="maskSN" name="maskSN" type="text" className="form-control"
                                                {...register("maskSN")} />
                                            {errors.maskSN && <span className='text-danger'>{errors.maskSN?.message}</span>}
                                        </div>
                                    </div>
                                </div>
                                {product.photos.length > 0 ?
                                    <>
                                        {product.photos.map(item =>
                                            <Row key={item.id} className='mt-3 mb-3 border-bottom p-3'>
                                                <Col xs={'auto'}>
                                                    <img src={`/api/products/photo/${item.id}`} style={{ maxHeight: "40vh" }} className="img-fluid" alt="Product photo" />
                                                </Col>
                                            </Row>
                                        )}
                                    </> :
                                    <Row>
                                        <Col>
                                            Нет фото
                                        </Col>
                                    </Row>
                                }
                                <ButtonGroup className='mt-3'>
                                    <Button color="success">
                                        Сохранить
                                    </Button>
                                    <Button color="warning" onClick={() => window.history.back()}>
                                        Отмена
                                    </Button>
                                </ButtonGroup>
                            </form>
                        }
                    </div>}
            </div>
        </Container>
    )
}