import { Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";

export const CaruselHome = () => {
    const navigate = useNavigate();

    return (
        <Swiper
            spaceBetween={30}
            effect={"fade"}
            // navigation={true}
            loop={true}
            zoom={true}
            // autoplay={false}
            autoplay={{ delay: 5000, disableOnInteraction: false, pauseOnMouseEnter: true }}
            pagination={{
                clickable: true,
            }}
            modules={[EffectFade, Navigation, Pagination, Autoplay]}
            className="mySwiper"
        >
            <SwiperSlide>
                <div className="swiperSlideBB">
                    <Image src="/front-slider/slide-003.jpg" fluid />
                    {/* <div className="text-center text-slide-h text-white d-flex" style={{ justifyContent: 'center', alignItems: 'flex-end' }}>
                        <Link className="btn btn-secondary rounded-pill btn-balloon btn-lg pt-lg-3 pb-lg-3 ps-lg-5 pe-lg-5" to={"/catalog?selId=1031"}>Посмотреть</Link>
                    </div> */}
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="swiperSlideBB">
                    <Image src="/front-slider/slide-004.jpg" fluid />
                    {/* <div className="text-center text-slide-h text-white d-flex" style={{ justifyContent: 'center', alignItems: 'flex-end' }}>
                        <Link className="btn btn-secondary rounded-pill btn-balloon btn-lg pt-lg-3 pb-lg-3 ps-lg-5 pe-lg-5" to={"/catalog?selId=1031"}>Посмотреть</Link>
                    </div> */}
                </div>
            </SwiperSlide>
            {/* <SwiperSlide>
                <div className="swiperSlideBB">
                    <Image src="/front-slider/slide-3.jpg" fluid />
                    <div className="text-center text-slide-h text-white d-flex" style={{ justifyContent: 'center', alignItems: 'flex-end' }}>
                        <Link className="btn btn-secondary rounded-pill btn-balloon btn-lg pt-lg-3 pb-lg-3 ps-lg-5 pe-lg-5" to={"/catalog?selId=1031"}>Посмотреть</Link>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="swiperSlideBB" style={{ backgroundColor: "#000" }}>
                    <Image src="/front-slider/slide-1.jpg" fluid />
                    <div className="text-center text-slide-h text-white d-flex" style={{ justifyContent: 'center', alignItems: 'flex-end' }}>
                        <Link className="btn btn-secondary rounded-pill btn-balloon btn-lg pt-lg-3 pb-lg-3 ps-lg-5 pe-lg-5" to={"/catalog"}>Заказать</Link>
                    </div>
                </div>
            </SwiperSlide> */}
            {/* <SwiperSlide>
                <div className="swiperSlideBB">
                    <Image src="/front-slider/slide-2.jpg" fluid />
                    <div className="text-center text-slide-h text-white d-flex" style={{ justifyContent: 'center', alignItems: 'flex-end' }}>
                        <Link className="btn btn-secondary rounded-pill btn-balloon btn-lg pt-lg-3 pb-lg-3 ps-lg-5 pe-lg-5" to={"/catalog?selId=12"}>Выбрать</Link>
                    </div>
                </div>
            </SwiperSlide> */}
        </Swiper>
    )
}