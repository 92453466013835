import { useEffect, useState } from "react"
import { useDeleteKeywordMutation, useGetKeywordsQuery, usePutKeywordMutation } from "../../features/CallApi"
import { Button, Col, Container, Dropdown, Form, InputGroup, Row, Spinner } from "react-bootstrap"
import { Check, ThreeDots, X } from "react-bootstrap-icons"
import { AddKeyword } from "./AddKeyword"
import LoadScreen from "../UI/LoadScreen"
import { useForm } from "react-hook-form"

const EditFormItem = ({ showEdit, setShowEdit, item, refetch }) => {

    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm()
    const [putKeyword, { isLoading }] = usePutKeywordMutation()
    const submit = (data) => {
        // console.log(data)
        putKeyword(data).then(() => {
            refetch()
            handelHide()
        })
    }

    const handelHide = () => {
        reset()
        setShowEdit(!showEdit)
    }
    useEffect(() => {
        setValue("id", item.id)
        setValue("keyword", item.keyword)
    }, [showEdit])

    return (
        <Form onSubmit={handleSubmit(submit)}>
            <InputGroup>
                <Form.Control size="sm"
                    placeholder=""
                    {...register("keyword", { required: { value: true, message: "Укажите ключевое слово" } })}
                />
                <Button size="sm" variant="outline-success" disabled={isLoading} type="submit">{!isLoading ? <Check /> : <Spinner size="sm" />} </Button>
                <Button size="sm" variant="outline-danger" onClick={() => handelHide()}><X /></Button>
            </InputGroup>
        </Form>
    )
}

const KeywordItem = ({ item, deleteKeyword, isDeleteing, refetch }) => {

    const [showEdit, setShowEdit] = useState()
    const deleteItem = () => {
        if (window.confirm("Удалить ключевое слово?")) {
            deleteKeyword(item.id).then(() => {
                refetch()
            })
        }
    }

    return (
        <Row key={item.id} className="pb-2 pt-2 border-bottom">
            <Col xs={'auto'}>
                # {item.id}
            </Col>
            <Col>
                {!showEdit ?
                    <>
                        {item.keyword}
                    </>
                    :
                    <EditFormItem showEdit={showEdit} setShowEdit={setShowEdit} item={item} refetch={refetch} />
                }
            </Col>
            <Col xs={'auto'}>
                <Dropdown>
                    <Dropdown.Toggle split='false' type="button" title="werwer" variant="light" id="dropdown-basic" bsPrefix="btn">
                        <ThreeDots className='' size='14' />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setShowEdit(!showEdit)}>Изменить</Dropdown.Item>
                        <Dropdown.Item onClick={() => deleteItem()}>Удалить</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
        </Row>
    )
}

export const KeywordsList = () => {

    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [stringFilter, setStringFilter] = useState("");
    const { data, isLoading, refetch } = useGetKeywordsQuery({ pageIndex, pageSize, stringFilter })
    const [deleteKeyword, { isLoading: isDeleteing }] = useDeleteKeywordMutation();



    useEffect(() => {
        refetch()
    }, [])

    return (
        <Container>
            <AddKeyword refetch={refetch} />
            <div className="d-flex">
                <input type={`text`} id="stringFilter" onChange={
                    (e) => setTimeout(() => { setStringFilter(e.target.value) }, 2000)}
                    placeholder="Поиск.." name="stringFilter" className="form-control" />
            </div>
            {!isLoading ? <>

                {data &&
                    <>
                        {data.getSearchKeywords.map(item =>
                            <KeywordItem key={item.id} item={item} deleteKeyword={deleteKeyword} isDeleteing={isDeleteing} refetch={refetch} />
                        )}
                        <div className="d-flex flex-row justify-content-center align-content-center mt-3 mb-3">
                            <div className="btn-group">
                                <button
                                    className={pageIndex == 1 ? `btn btn-primary disabled` : `btn btn-primary`}
                                    onClick={() => {
                                        if (pageIndex > 1) {
                                            setPageIndex(pageIndex - 1)
                                        }
                                    }}>Назад</button>
                                <button
                                    className={(pageIndex == data.totalPages || data.totalPages == 0) ? `btn btn-primary disabled` : `btn btn-primary`}
                                    onClick={
                                        () => {
                                            if (pageIndex <= data.totalPages) {
                                                setPageIndex(pageIndex + 1)
                                            }
                                        }}
                                >Вперед</button>
                            </div>
                        </div>
                    </>
                }
            </> :
                <>
                    <LoadScreen height={30} />
                </>}

        </Container>
    )
}