import { Row, Col, Card } from 'reactstrap';
import { useForm } from "react-hook-form"
import { withHookFormMask } from 'use-mask-input';




export const ProductCard = ({ product, addItem }) => {

    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const add = (data) => {
        addItem(data)
        reset()
    }

    return (
        <form onSubmit={handleSubmit(add)}>
            <input {...register("id")} hidden value={product.id} />
            <Card className='mb-1'>
                <Row className={"p-2"}>
                    <Col xs="2">
                        <img src={product.imageType ? `/api/products/photo/${product.id}` : `/NoPhoto.png`} className="img-fluid img-thumbnail" alt="Product" />
                    </Col>
                    <Col>
                        <div className='mb-1'>
                            <div><strong>{product.name}</strong></div>
                            <div>арт.: {product.sku}</div>
                            <div>{product.price} руб.</div>
                        </div>
                        {product.requiredSN &&
                            <div>
                                <div className="form-floating">
                                    <input name="sn" id='sn' type='text' className={`form-control ${errors.sn ? "is-invalid" : ""}`} placeholder='Серийный номер'
                                        {...withHookFormMask({ ...register("sn", { required: { value: true, message: "Серийный номер" } }) }, [product.maskSN])} />
                                    <label htmlFor="sn" className={`${errors.sn ? "is-invalid" : ""}`}>Серийный номер</label>
                                </div>
                            </div>
                        }
                    </Col>
                    <Col xs="3">
                        <div>
                        <button type='submit' className='btn btn-success mt-1 mb-2 w-100'>Добавить</button>
                        </div>
                        {product.requiredSN == false &&
                            <div>
                                <div className="form-floating">
                                    <input min={1} type='number' name='quantity' defaultValue={1} className={`form-control ${errors.quantity && "is-invalid"}`}
                                        {...register("quantity", { required: { value: true } })}
                                    />
                                    <label htmlFor={`quantity`}>шт.</label>
                                </div>
                            </div>
                        }
                    </Col>
                </Row>
            </Card>
        </form>
    )
}