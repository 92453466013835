import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { ThreeDots } from 'react-bootstrap-icons';
import { CategoriesAdd } from "./CategoriesAdd";
import { useGetCategoriesQuery, useGetCategoriesSelectQuery } from "../../../../features/CallApi";
import LoadScreen from "../../../UI/LoadScreen";

export const Categories = ({ token }) => {
    const navigate = useNavigate();
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [stringFilter, setStringFilter] = useState("");
    const { isLoading, data, refetch, error } = useGetCategoriesQuery({ pageIndex, pageSize, stringFilter });
    const { data: category, isLoading: isLoadingCatSel } = useGetCategoriesSelectQuery();
    useEffect(() => {
        refetch()
    }, [])

    return (
        <Container>
            <h3>Категории товаров</h3>
            {error ?
                <div>{error && error.data}</div>
                :
                <div>
                    {isLoading ?
                        <LoadScreen />
                        :
                        <div>
                            {category &&
                                <CategoriesAdd category={category} token={token} refetch={refetch} />}
                            <div className="d-flex">
                                <input type={`text`} id="stringFilter" onChange={
                                    (e) => setTimeout(() => {
                                        setPageIndex(1)
                                        setStringFilter(e.target.value)
                                    }, 2000)}
                                    placeholder="Поиск.." name="stringFilter" className="form-control" />
                            </div>
                            <table className="w-100 table table-hover">
                                <thead>
                                    <tr>
                                        <th className="pe-3 ps-3">Id</th>
                                        <th>Название</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.getCategories && data.getCategories.length > 0 ?
                                        data.getCategories.map(categorie =>
                                            <tr key={categorie.id}>
                                                <td className="pe-3 ps-3">{categorie.id}</td>
                                                <td className="w-100">
                                                    <div>{categorie.name}</div>
                                                    {categorie?.parentCategoryName &&
                                                        <div className="text-muted ms-2">- Родительская категория: {categorie.parentCategoryName}</div>
                                                    }
                                                </td>
                                                <td>
                                                    <UncontrolledDropdown>
                                                        <DropdownToggle color="light" >
                                                            <ThreeDots className='' size='14' />
                                                        </DropdownToggle>
                                                        <DropdownMenu container="body">
                                                            <DropdownItem onClick={() => navigate('edit/' + categorie.id)}>Изменить</DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </td>
                                            </tr>
                                        ) :
                                        <tr>
                                            <td colSpan={3} className={"text-center"}>Список пуст</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                            <div className="d-flex flex-row justify-content-center align-content-center mt-3 mb-3">
                                <div className="btn-group">
                                    <button
                                        className={pageIndex == 1 ? `btn btn-primary disabled` : `btn btn-primary`}
                                        onClick={() => {
                                            if (pageIndex > 1) {
                                                setPageIndex(pageIndex - 1)
                                            }
                                        }}>Назад</button>
                                    <button
                                        className={(pageIndex == data.totalPages || data.totalPages == 0) ? `btn btn-primary disabled` : `btn btn-primary`}
                                        onClick={
                                            () => {
                                                if (pageIndex <= data.totalPages) {
                                                    setPageIndex(pageIndex + 1)
                                                }
                                            }}
                                    >Вперед</button>
                                </div>
                            </div>

                        </div>
                    }
                </div>
            }
        </Container>
    )
}