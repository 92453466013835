import { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, ButtonGroup } from 'reactstrap';
import { useForm } from "react-hook-form"
import axios from 'axios';


export const CategoriesAdd = ({ refetch, token, category }) => {
    const [modal, setModal] = useState(false);
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [imageType, setImageType] = useState();
    const [image, setImage] = useState(null)
    const [showInIcons, setShowInIcons] = useState(false);




    const toggle = () => {
        console.log(category)
        setImage(null)
        setImageType(null)
        setModal(!modal);
    }

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader(event.target.files[0]);
            reader.onloadend = () => {
                setImage(reader.result)
                setImageType(event.target.files[0].type)
            }
            reader.readAsDataURL(event.target.files[0])
        }
    }


    const onShowInIconsChenge = (e) => {
        setShowInIcons(e.target.checked)
    }

    const addCategories = async (data) => {
        if (image) {
            data.image = image
            data.imageType = imageType
        }
        data.showInIcons = showInIcons
        data.parentCategory = parseInt(data.parentCategory)
        console.log(data)
        const response = await axios.post(`/api/categories`, data, {
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                Authorization: "Bearer " + token
            }
        })

        if (response.status >= 200 || response.status <= 204) {
            console.log(data)
            setModal(false)
            refetch()
            reset()
            console.log(response.data)
        }
    }

    return (
        <div>
            <Button className='mb-2' color="success" size="sm" onClick={toggle}>
                Добавить
            </Button>
            <Modal isOpen={modal} toggle={toggle}>
                <form onSubmit={handleSubmit(addCategories)}>
                    <ModalHeader toggle={toggle}>Создать пользователя</ModalHeader>
                    <ModalBody>
                        <div className="mb-3">
                            <div className='form-floating'>
                                <input id="name" name="name" placeholder="Укажите Название" type="string" className={`form-control ${errors.name && "is-invalid"}`}
                                    {...register("name", {
                                        required: { value: true, message: "Поле \"Название\" не может быть пустым" }
                                    })} />
                                <label htmlFor="name" className="">Название</label>
                            </div>
                            {errors.name && <span className='text-danger'>{errors.name?.message}</span>}
                        </div>
                        <div className='mb-3'>
                            <div className='form-floating'>
                                <select id={"parentCategory"} className='form-select' {...register("parentCategory")}>
                                    {category.length > 0 ?
                                        <>
                                            <option value="0">-- Не выбрана --</option>
                                            {category.map(item =>
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            )}
                                        </>
                                        : <option value="0">-- Нет доступных категорий --</option>}
                                </select>
                                <label htmlFor="parentCategory">Родительская категория</label>

                            </div>
                        </div>
                        <div className="mb-2 d-flex">
                            <div className=" form-check form-switch">
                                <label className="form-check-label">Показать в быстром каталоге</label>
                                <input onClick={(e) => onShowInIconsChenge(e)} type="checkbox"
                                    className="form-check-input check-input-lc" {...register("showInIcons")} />
                            </div>
                        </div>
                        <div className='mb-3'>
                            <label htmlFor="image" className="form-label">Изображение продукта</label>
                            <div className="card mb-3">
                                <div className="mb-3 text-center">
                                    <img src={image ? `${image}` : `/NoPhoto.png`} className="img-fluid" alt="Product photo" />
                                </div>
                            </div>
                            <div>
                                <input onChange={onImageChange}
                                    id="image"
                                    name="image"
                                    className="form-control form-control-sm"
                                    accept=".jpg, .jpeg, .png" type="file" />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <ButtonGroup>
                            <Button color="success">
                                Добавить
                            </Button>
                            <Button color="warning" onClick={() => {
                                toggle()
                                reset()
                            }}>
                                Отмена
                            </Button>
                        </ButtonGroup>
                    </ModalFooter>
                </form>
            </Modal>
        </div>
    )

}