import React, { Component, useEffect, useState } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './NavMenu.css';

export const NavMenu = ({ showCatalog, setShowCatalog }) => {

  const [open, setOpen] = useState(false)
  // const navigate = useNavigate();

  const location = useLocation()
  useEffect(() => {
    setOpen(false)
    // setShowCatalog(false)
  }, [location])

  return (
    <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow bg-white" container light>
      <Link className='navbar-brand' to={"/"}><img style={{ maxHeight: "55px" }} src='/ballon_logo.png' className='img-fluid' /> </Link>
      <NavbarToggler onClick={() => { setOpen(!open) }} className="mr-2" />
      <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={open} navbar>
        <ul className="navbar-nav flex-grow bubbles-nav">
          <NavItem className={'d-none d-lg-block'}>
            <Link to={'/catalog'} className="text-dark nav-link" style={{ textDecoration: 'none' }}>
              Каталог
            </Link>
          </NavItem>
          <NavItem className={'d-lg-none'}>
            <NavLink onClick={() => {
              setOpen(false)
              setShowCatalog(!showCatalog)
            }} className="text-dark" style={{ textDecoration: 'none', cursor: 'pointer' }}>
              Каталог
            </NavLink>
          </NavItem>
          <NavItem >
            <Link to={'/catalog?selPromo=1'} className="text-dark nav-link" style={{ textDecoration: 'none' }}>
              Акции
            </Link>
          </NavItem>
          <NavItem>
            <Link to={'/delivery'} className="text-dark nav-link" style={{ textDecoration: 'none' }}>
              Оплата и доставка
            </Link>
          </NavItem>
          {/* <NavItem>
            <NavLink className="text-dark" style={{ cursor: "pointer" }} onClick={() => { navigate("/catalog") }}>О нас</NavLink>
          </NavItem> */}
          <NavItem>
            <Link to={'/contacts'} className="text-dark nav-link" style={{ textDecoration: 'none' }}>
              Контакты
            </Link>
          </NavItem>
        </ul>
      </Collapse>
    </Navbar>
  );

}
