import { useParams } from "react-router-dom";
import { useGetProductCardQuery } from "../../features/CallApi"
import LoadScreen from "../UI/LoadScreen";
import { Button, Container } from "reactstrap";
import { Image } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Pagination } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Helmet } from "react-helmet";
import parse from 'html-react-parser';
import { Cart2 } from "react-bootstrap-icons";


export const Product = ({ addItem }) => {
    const { seoUrl } = useParams()
    const { isLoading: productisLoading, data: product, error, refetch } = useGetProductCardQuery(seoUrl)

    useEffect(() => { refetch() }, [])
    const add = (e) => {

        const data = {
            id: product.id,
            quantity: 1
        }
        // console.log(e.currentTarget.closest("div").querySelector('#varsel').value)
        if (product.variations.length > 0) {
            // const variationsSelected = e.currentTarget.closest("div").querySelector("select").value
            const variationsSelected = e.currentTarget.closest("div").querySelector('#varsel').value
            // console.log(variationsSelected)
            data.variations = variationsSelected
        }
        addItem(data)
    }

    return (
        <>
            {!productisLoading ?
                <>
                    {product ?
                        <div className="row shadow mt-3 mb-3 pt-md-3">
                            <Helmet>
                                <title>{`${product.name} - balloon.spb`}</title>
                                <meta name="title" content={`${product.name} - balloon.spb`} />
                                <meta name="og:title" content={`${product.name} - balloon.spb`} />
                                <meta name="description" content={`${product.description} - balloon.spb Воздушные шары на заказ`} />
                                <meta name="og:description" content={`${product.description} - balloon.spb Воздушные шары на заказ`} />
                                <meta name="keywords" content="воздушные шары,латексныешары,фольгированные шары,гелиевые шары,шары с рисунками,шары с надписями, шары для праздника, шары для оформления, шары на день рождения, шары на свадьбу, шары на корпоратив, доставка шаров, цены на шары" />
                            </Helmet>
                            <div className="col-md-4 ps-3 pe-3 mb-3 position-relative text-center">
                                {product.photos.length > 0 ?
                                    <Swiper
                                        // spaceBetween={30}
                                        // effect={'fade'}
                                        // navigation={{
                                        //     navigation: true,
                                        //     // nextEl: '.nextBB',
                                        //     prevEl: '.prevBB'
                                        // }}
                                        pagination={{
                                            clickable: true,
                                        }}
                                        loop={true}
                                        modules={[Pagination]}
                                    >
                                        {product.photos.map(item =>
                                            <SwiperSlide key={item.id}>
                                                <img src={`/api/products/photo/${item.id}`} className="img-fluid rounded" alt={`Product-${product.name}`} />
                                            </SwiperSlide>
                                        )}
                                    </Swiper>
                                    :
                                    <Image src={`/NoPhoto.png`} rounded fluid />

                                }
                                {product.hit &&
                                    <span className="position-absolute top-0 translate-middle badge rounded-pill bg-danger" style={{ left: "75%", top: "10%", zIndex: "100" }}>
                                        ХИТ!
                                    </span>
                                }
                                {product.discount > 0 &&
                                    <span className="position-absolute top-0 translate-middle badge rounded-pill bg-danger" style={{ left: "75%", top: "10%", zIndex: "100" }}>
                                        Акция
                                    </span>
                                }
                            </div>
                            <div className="col-md">
                                <div className="text-muted">арт. {product.sku}</div>

                                {/* <div className="text-muted">{product.category.name}</div> */}
                                <h3>{product.name}</h3>
                                <div className="mb-3 h3">
                                    <strong>
                                        {product.variations.length > 0 ?
                                            <>{product.variations[0].price} ₽ - {product.variations.at(-1).price} ₽</>
                                            :
                                            <>
                                                {product.variations.length > 0 ?
                                                    <>
                                                        <>{product.variations[0].price} ₽ - {product.variations.at(-1).price} ₽ </>
                                                    </>
                                                    :
                                                    <>
                                                        {product.discount > 0 ?
                                                            <div className="h5 mt-3">

                                                                <span style={{ position: "relative" }}>
                                                                    <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger">
                                                                        {((product.discount / product.price) * 100 - 100)} %
                                                                    </span>
                                                                    <strike>{product.price} ₽</strike> = </span>
                                                                <span style={{ color: "red" }}>{product.discount} ₽</span>
                                                                {/* <div><span className="translate-middle badge rounded-pill bg-danger">{(product.discount - product.price)}</span></div> */}
                                                            </div>
                                                            :
                                                            <div>
                                                                {product.price} ₽
                                                            </div>
                                                        }
                                                    </>
                                                }
                                            </>
                                        }</strong>
                                </div>
                                {product.variations.length > 0 &&
                                    <div className="form-floating mt-3 mb-3">
                                        <select id={'varsel'} className="form-select" onChange={(e) => { console.log(e.target.value) }} aria-label="Выберите состав">
                                            {product.variations.map(item =>
                                                <option key={item.id} value={item.id}>{item.name} ({item.price} ₽)</option>
                                            )}
                                        </select>
                                        <label htmlFor="floatingSelect">Выберите состав</label>
                                    </div>
                                }

                                <Button className="rounded-pill" onClick={(e) => { add(e) }} color="success btn-balloon">Заказать <Cart2 className="pb-1" size={24} /></Button>
                                {/* <Button className="rounded-pill" onClick={(e) => { add(e) }} color="success">В корзину</Button> */}
                                <div className="border-top p-1 mt-3">
                                    <div>Описание:</div>
                                    <div style={{ whiteSpace: "pre-line" }}>
                                        {product.description && parse(product.description)}
                                    </div>
                                    {/* <div className="mt-3">Цвет: </div>
                                    <div>Состав: </div> */}
                                </div>
                            </div>
                        </div> : <h3>Not Found</h3>}
                </>
                :
                <LoadScreen />
            }
        </>
    )

}