import { Button, ButtonGroup, Card, Container } from "react-bootstrap"
import { useParams, useSearchParams } from "react-router-dom"
import { useGetClientOrderQuery } from "../../features/CallApi"
import { format } from "date-fns"
import { TypeOfPay } from "../Admin/Orders/OrderTypeOfPay"
import { useEffect } from "react"
import { useState } from "react"
import { OrderStatus } from "../Admin/Orders/OrderStatus"
import LoadScreen from "../UI/LoadScreen"
import axios from "axios"


export const OrderClientDetails = () => {

    const { id } = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const [summ, setSumm] = useState(0)
    const [saleSumm, setSaleSumm] = useState(0)
    const { data, refetch, isLoading, isFetching, error } = useGetClientOrderQuery({ id, tokenId: searchParams.get("tokenId") })

    useEffect(() => {
        if (data?.items) {
            const sum = data.items.reduce((summa, item) => {
                return summa + (parseFloat(item.price) * item.quantity);
            }, 0);
            setSumm(sum)
            const saleSum = data.items.reduce((summa, item) => {
                return summa + ((item.discount ? parseFloat(item.discount) : parseFloat(item.price)) * item.quantity);
            }, 0);
            setSaleSumm(saleSum)
        }
    }, [data])

    if (searchParams.get("tokenId") === null || searchParams.get("tokenId") === '') {
        return (
            <Container>
                <div className="mt-3 mb-3"><h3>Ошибка авторизации</h3></div>
            </Container>
        )
    }

    const toPayOrder = () =>{
        
        axios.get(`/api/orders/pay/${id}?tokenId=${searchParams.get("tokenId")}`).then((r) =>{
            window.location.href = r.data
        })
    }

    return (
        <Container>
            {(!isLoading && !isFetching) ?
                <>
                    <h3>{error && `${error.data.title} (${error.data.status})`}</h3>
                    {console.log(error)}
                    {data &&
                        <Card>
                            <Card.Header>
                                <h2>Заказ №{data.id}</h2>
                                <div className="d-flex">
                                    <div className="me-1">Покупатель:</div>
                                    <div style={{ fontWeight: "600" }}>Скрыто</div>
                                </div>
                                <div className="d-flex">
                                    <div className="me-1">Статус:</div>
                                    <div><OrderStatus status={data.status} /></div>

                                </div>
                                {/* 
                                <div className="d-flex">
                                    <div className="me-1">Менеджер:</div>
                                    {data.user ?
                                        <div style={{ fontWeight: "600" }}>{data.user?.lastName} {data.user?.firstName}</div>
                                        :
                                        <div>Не назначен</div>
                                    }
                                </div> */}
                                <div className="d-flex">
                                    <div className="me-1">Сумма заказа:</div>
                                    <div style={{ fontWeight: "600" }}>{summ} руб.</div>
                                </div>
                                <div className="d-flex">
                                    <div className="me-1">Способ оплаты:</div>
                                    <div style={{ fontWeight: "600" }}><TypeOfPay typeOfPay={data.typeOfPay} /> </div>
                                </div>
                                <div className="d-flex">
                                    <div className="me-1">Дата доставки: </div>
                                    <div style={{ fontWeight: "600" }}> {format(new Date(data.deliveryDate), 'dd.MM.yy HH:mm')}</div>
                                </div>
                                <div className="d-flex">
                                    <div className="me-1">Адрес доставки: </div>
                                    <div style={{ fontWeight: "600" }}> {data.addres}</div>
                                </div>
                                <div className="d-flex">
                                    <div className="me-1">Комментарий: </div>
                                    <div style={{ fontWeight: "600" }}> {data.comments}</div>
                                </div>
                                {console.log(data.typeOfPay)}
                                {(data.typeOfPay === 0 && (data.status === 7 || data.status === 1)) &&
                                    <div className="d-flex mt-3">
                                        <Button className="rounded" onClick={() => { toPayOrder() }} variant="success" size="lg">оплатить</Button>
                                    </div>
                                }
                            </Card.Header>
                            <Card.Body>
                                <div className="table-responsive">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col-1">#</th>
                                                <th scope="col">Продукт</th>
                                                <th scope="col">Цена</th>
                                                <th scope="col">Количество</th>
                                                <th scope="col">Сумма</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.items.map((item, index) =>
                                                <tr key={item.id}>
                                                    <td>
                                                        {index + 1}
                                                    </td>
                                                    <td style={{ verticalAlign: "middle" }}>
                                                        {item.product.name} {item.productVariationId && "(" + item.productVariation.name + ")"}
                                                    </td>
                                                    <td>
                                                        {item.discount > 0 ?
                                                            <>
                                                                <div><strike>{item.price} ₽</strike> {item.discount - item.price} ₽</div>
                                                                <div>{item.discount} ₽</div>
                                                            </> :
                                                            <>{item.price} ₽</>
                                                        }

                                                    </td>
                                                    <td>{item.quantity} шт.</td>
                                                    <td>
                                                        {item.discount > 0 ?
                                                            <>{(parseFloat(item.discount) * item.quantity)} ₽</>
                                                            :
                                                            <>{(parseFloat(item.price) * item.quantity)} ₽</>}
                                                    </td>
                                                </tr>
                                            )}
                                            <tr>
                                                <td colSpan={3}></td>
                                                <td>Сумма заказа:</td>
                                                <td>
                                                    {summ > saleSumm ?
                                                        <>
                                                            <div>
                                                                <strike>{summ} ₽</strike> {saleSumm - summ} ₽
                                                            </div>
                                                            <div>
                                                                <strong>{saleSumm} ₽</strong>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <strong>{summ}</strong> ₽
                                                        </>
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Card.Body>
                        </Card>
                    }
                </>
                : <LoadScreen />}
        </Container>
    )
}