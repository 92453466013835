import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { ThreeDots } from 'react-bootstrap-icons';
import { Badge, Button } from "react-bootstrap";

export const ProductList = ({ products, navigate, pageIndex, setPageIndex, totalPages, setCopyProduct }) => {

    return (
        <div>
            <div className="table-responsive">
                <table className="w-100 table table-hover">
                    <thead>
                        <tr>
                            {/* <th>Категория</th> */}
                            {/* <th>Агент</th> */}
                            <th>Наименование</th>
                            <th>Артикул</th>
                            <th>Цена (руб.)</th>
                            <th>Остаток</th>
                            <th>Картинка</th>
                            <th>Статус</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {products && products.length > 0 ?
                            products.map(product =>
                                <tr key={product.id}>
                                    {/* <td>
                                        {product.agent ? product.agent.name : `Нет агента`}
                                    </td> */}
                                    <td>
                                        {product.name}

                                        {product.hit && <Badge bg="danger">Хит</Badge>}
                                    </td>
                                    <td>
                                        {product.sku}
                                    </td>
                                    <td>
                                        {product.variations.length > 0 ?
                                            <>{product.variations[0].price} ₽ - {product.variations.at(-1).price} ₽</>
                                            :
                                            <>
                                                {product.discount === 0 ?
                                                    <div>{product.price} ₽</div>
                                                    :
                                                    <>
                                                        <div><strike>{product.price} ₽</strike></div>
                                                        <div className="text-danger"><strong>{product.discount} ₽</strong></div>
                                                    </>
                                                }

                                            </>
                                        }
                                    </td>
                                    <td>
                                        {product.inStock}
                                    </td>
                                    <td>
                                        <div style={{ maxWidth: '75px' }}>
                                            {product.photos.length > 0 ?
                                                <img src={`/api/products/photo/${product.photos[0].id}`} className="img-fluid" alt={`Product-${product.name}`} />
                                                :
                                                <img src={`/NoPhoto.png`} className="img-fluid" alt={`Product-${product.name}`} />
                                            }
                                            {/* <img src={product.imageType ? `/api/products/photo/${product.id}` : `/NoPhoto.png`} className="img-fluid" alt={`Product-${product.name}`} /> */}
                                        </div>
                                    </td>
                                    <td>
                                        {product.inActive ?
                                            <span>Неактивен</span> : <span>Активен</span>
                                        }
                                    </td>
                                    <td xs='auto' style={{
                                        verticalAlign: "middle",
                                        textAlign: "center"
                                    }}>
                                        <UncontrolledDropdown>
                                            <DropdownToggle color="light" >
                                                <ThreeDots className='' size='14' />
                                            </DropdownToggle>
                                            <DropdownMenu container="body">
                                                <DropdownItem onClick={() => navigate('details/' + product.id)}>Подробнее</DropdownItem>
                                                <DropdownItem onClick={() => navigate('edit/' + product.id)}>Изменить</DropdownItem>
                                                <DropdownItem onClick={() => { setCopyProduct(product) }}>Создать копию</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </td>
                                </tr>
                            )
                            : <tr><td colSpan={8} className={"text-center"}>Список пуст</td></tr>
                        }
                    </tbody>
                </table>
            </div>
            <div className="d-flex flex-row justify-content-center align-content-center mt-3 mb-3">
                <div className="btn-group">
                    <Button
                        className={(pageIndex === 1) ? `btn btn-primary disabled` : `btn btn-primary`}
                        onClick={() => {
                            if (pageIndex !== 1) {
                                setPageIndex(1)
                            }
                        }}>Первая</Button>
                    <button
                        className={pageIndex == 1 ? `btn btn-primary disabled` : `btn btn-primary`}
                        onClick={() => {
                            if (pageIndex > 1) {
                                setPageIndex(pageIndex - 1)
                            }
                        }}>Назад</button>
                    <button
                        className={(pageIndex == totalPages || totalPages == 0) ? `btn btn-primary disabled` : `btn btn-primary`}
                        onClick={
                            () => {
                                if (pageIndex <= totalPages) {
                                    setPageIndex(pageIndex + 1)
                                }
                            }}
                    >Вперед</button>
                    <Button
                        className={(pageIndex == totalPages || totalPages == 0) ? `btn btn-primary disabled` : `btn btn-primary`}
                        onClick={() => {
                            if (pageIndex < + totalPages) {
                                setPageIndex(totalPages)
                            }
                        }}>Последняя</Button>
                </div>
            </div>
        </div>
    )
}