import { useEffect, useState } from "react";
import { ProductCard } from "./ProductCard"
import { useOutletContext, useSearchParams } from "react-router-dom";
import { useGetProductsCatalogQuery } from "../../features/CallApi";
import LoadScreen from "../UI/LoadScreen";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { Helmet } from "react-helmet";


export const Catalog = ({ addItem }) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [fetching, setFetching] = useState(false);
    const [outCatId, setOutCatId] = useOutletContext();
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [stringFilter, setStringFilter] = useState("");
    const [filtering, setFiltering] = useState();
    const [selCat, setSelCat] = useState("");
    const [selPromo, setSelPromo] = useState("");
    const [data, setData] = useState();
    const { isLoading, data: dataResponse, refetch, error } = useGetProductsCatalogQuery({ pageIndex, pageSize, stringFilter, selCat, selPromo });

    // const { data, isLoading } = useGetProductsQuery({ pageIndex, pageSize, stringFilter, selCat })


    useEffect(() => {
        setFetching(isLoading)
    }, [isLoading])

    useEffect(() => {
        refetch()
    }, [])
    useEffect(() => {
        setSelCat(searchParams.get("selId"))
        setSelPromo(searchParams.get("selPromo"))
        setStringFilter(null)
        setData([])
        setPageIndex(1)
    }, [searchParams])

    useEffect(() => {
        if (dataResponse && !data) {
            // console.log("Add new data")
            // setData([])
            // setData(dataResponse.getProducts)
        }
        else if (data) {
            setData([...data, ...dataResponse.getProducts])
        }
        setFetching(false)
        setFiltering(false)
    }, [dataResponse])

    useEffect(() => {
        if (dataResponse && !fetching) {
            const scrollHandeler = (e) => {
                if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 150 && pageIndex < dataResponse.totalPages) {
                    // console.log("loading...")
                    setFetching(true)
                    setPageIndex(pageIndex => pageIndex + 1)
                }
            }
            document.addEventListener('scroll', scrollHandeler)
            return function () {
                document.removeEventListener('scroll', scrollHandeler)
            }

        }
    }, [dataResponse, fetching])

    return (
        <div>
            <Helmet>
                <title>{`Каталог шаров - balloon.spb`}</title>
                <meta name="og:title" content={`Каталог шаров - balloon.spb`} />
                <meta name="description" content={`Каталог - balloon.spb Воздушные шары на заказ`} />
                <meta name="og:description" content={`Каталог - balloon.spb Воздушные шары на заказ`} />
                <meta name="keywords" content="воздушные шары,латексныешары,фольгированные шары,гелиевые шары,шары с рисунками,шары с надписями, шары для праздника, шары для оформления, шары на день рождения, шары на свадьбу, шары на корпоратив, доставка шаров, цены на шары" />
            </Helmet>
            <FloatingLabel controlId="floatingInput" label="Поиск.." className="mb-3 mt-3 shadow">
                <Form.Control onChange={
                    (e) => {
                        setFetching(true)
                        setFiltering(true)
                        setSelCat(null)
                        setData([])
                        setSearchParams("selId", undefined)
                        setTimeout(() => {
                            setStringFilter(e.target.value.toLocaleLowerCase())
                            setPageIndex(1)
                        }, 2000)
                    }} type="text" placeholder="Поиск.." />
            </FloatingLabel>
            {data &&
                <>
                    {data.length > 0 ?
                        <div>
                            <div className="row g-1">
                                {data.map(product =>
                                    <div key={product.id} className="col-6 col-sm-6 col-lg-6 col-xl-4 col-xxl-3 text-center">
                                        <div>
                                            <ProductCard addItem={addItem} key={product.id} product={product} />
                                        </div>
                                    </div>
                                )}
                            </div>
                            {pageIndex < dataResponse.totalPages &&
                                <div className="text-center">
                                    <Button variant="success" style={{ background: "#aa5991" }} className="btn-balloon mt-3 mb-3" onClick={() => { setPageIndex(pageIndex => pageIndex + 1) }}>Показать еще</Button>
                                </div>
                            }
                        </div>
                        :
                        <>
                            {!fetching &&
                                <h4 className="text-center">По вашему запросу ничего не найдено</h4>
                            }
                        </>
                    }
                </>
            }
            {!fetching ? <>


            </> : <LoadScreen props={{ height: "100px;" }} />
            }
        </div>
    )
}