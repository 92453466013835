import { Container, Button, ButtonGroup } from 'reactstrap';
import { useForm } from "react-hook-form"
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDeleteCategoryImageMutation, useGetCategoriesQuery, useGetCategoriesSelectQuery, useGetCategoryQuery, usePostCategoryImageMutation } from '../../../../features/CallApi';
import LoadScreen from '../../../UI/LoadScreen';


export const CategoriesEdit = ({ token }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();
    const [loading, setLoading] = useState(false);
    const { isLoading, data, refetch, error, isFetching } = useGetCategoryQuery(id);
    const { data: category, isLoading: categoryLoad, refetch: refetchCategories } = useGetCategoriesSelectQuery({});
    const [listCategories, setListCategories] = useState([])
    const [hide, setHide] = useState();
    const [changingImage, setChangingImage] = useState();
    const [deleteCategoryImage] = useDeleteCategoryImageMutation();
    const [postCategoryImage, { isLoading: postingImage }] = usePostCategoryImageMutation()
    const [showInIcons, setShowInIcons] = useState();




    const onHideChenge = (e) => {
        setHide(e.target.checked)
    }

    const onImageChange = (event) => {
        setChangingImage(true)
        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader(event.target.files[0]);
            reader.onloadend = () => {
                const body = {
                    id: id,
                    image: reader.result,
                    imageType: event.target.files[0].type
                }
                postCategoryImage({ id, ...body })
            }
            reader.readAsDataURL(event.target.files[0])
        }
        setTimeout(() => {
            refetch()
            setChangingImage(false)
        }, 2000)
    }

    const deletePhoto = () => {
        setChangingImage(true)
        deleteCategoryImage(id)
        setTimeout(() => {
            refetch()
            setChangingImage(false)
        }, 2000)

    }
    
    const onShowInIconsChenge = (e) => {
        setShowInIcons(e.target.checked)
    }

    const putCategories = async (data) => {
        setLoading(true)
        console.log(data)
        const response = await axios.put(`/api/categories/${id}`, data, {
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                Authorization: "Bearer " + token
            }
        })
        setLoading(false)
        if (response.status >= 200 || response.status <= 204) {
            console.log(data)
            refetch()
            navigate('/admin/categories')
        }
    }
    useEffect(() => {
        refetch()
        if (data) {
            // console.log(data.showInIcons)
            setValue("name", data.name)
            setValue("sortNumber", data.sortNumber)
            setValue("showInIcons", data.showInIcons)
            setShowInIcons(data.showInIcons)
        }
    }, [data])
    useEffect(() => {
        if (category) {
            // console.log(category)
            // const dataItem = category.filter((item) => !id.includes(item.id.toString()))
            setListCategories(category)
        }
    }, [category])

    return (
        <Container>
            <div>
                {isLoading || loading ? <LoadScreen /> :
                    <div>
                        {error ? <div>
                            <h2>{error.status}</h2>
                            <p>{error.data.title}</p>
                        </div> : <div>
                            {data && data.length > 0 ? <h1>{error}</h1> :
                                <form onSubmit={handleSubmit(putCategories)} key={data.id}>
                                    <div className="mb-3">
                                        <div className='form-floating'>
                                            <input id="name" name="name" placeholder="Укажите Название" type="string" className={`form-control ${errors.name && "is-invalid"}`}
                                                {...register("name", {
                                                    required: { value: true, message: "Поле \"Название\" не может быть пустым" }
                                                })} />
                                            <label htmlFor="name" className="">Название</label>
                                        </div>
                                        {errors.name && <span className='text-danger'>{errors.name?.message}</span>}
                                    </div>
                                    <div className='mb-3'>
                                        {!categoryLoad ?
                                            <div className='form-floating'>
                                                <select id={"parentCategory"} defaultValue={data.subcategoryId} className='form-select' {...register("parentCategory")}>
                                                    {!listCategories || listCategories.length > 0 ?
                                                        <>
                                                            {setValue("parentCategory", data.subcategoryId ? data.subcategoryId : 0)}
                                                            <option value="0">-- Не выбрана --</option>
                                                            {listCategories.map(item =>
                                                                <option key={item.id} value={item.id}>{item.name}</option>
                                                            )}
                                                        </>
                                                        : <option value="0">-- Нет доступных категорий --</option>}
                                                </select>
                                                <label htmlFor="parentCategory">Родительская категория</label>
                                            </div>
                                            : <LoadScreen props={{ height: 10 }} />
                                        }
                                    </div>
                                    <div className="mb-3">
                                        <div className='form-floating'>
                                            <input id="sortNumber" name="sortNumber" placeholder="Укажите Название" type="number" className={`form-control ${errors.name && "is-invalid"}`}
                                                {...register("sortNumber", {
                                                    // required: { value: true, message: "Поле \"Название\" не может быть пустым" }
                                                })} />
                                            <label htmlFor="name" className="">Порядковый номер</label>
                                        </div>
                                        {errors.sortNumber && <span className='text-danger'>{errors.sortNumber?.message}</span>}
                                    </div>
                                    <div className="mb-3 d-flex">
                                        <div className=" form-check form-switch">
                                            <label className="form-check-label">Скрыть</label>
                                            <input defaultChecked={data.hide} onClick={(e) => onHideChenge(e)} type="checkbox"
                                                className="form-check-input check-input-lc" {...register("hide")} />
                                        </div>
                                    </div>
                                    <div className="mb-2 d-flex">
                                        <div className=" form-check form-switch">
                                            <label className="form-check-label">Показать в быстром каталоге</label>
                                            <input defaultChecked={showInIcons} onClick={(e) => onShowInIconsChenge(e)} type="checkbox"
                                                className="form-check-input check-input-lc" {...register("showInIcons")} />
                                        </div>
                                    </div>
                                    
                                    <div className='mb-3'>
                                        <label htmlFor="image" className="form-label">Изображение продукта</label>
                                        <div className="card mb-3">
                                            {postingImage || changingImage ? <LoadScreen height={10} /> :
                                                <div className="mb-3 text-center">
                                                    <img key={Date.now()} src={data.imageType ? `/api/categories/image/${data.id}` : `/NoPhoto.png`} className="img-fluid" alt="Category photo" />
                                                </div>
                                            }
                                        </div>
                                        <div className='input-group'>

                                            {data.imageType &&
                                                <Button size='sm' color='danger' onClick={() => deletePhoto()}>Удалить Изображение</Button>
                                            }
                                            <input onChange={(e) => onImageChange(e)}
                                                id="photo"
                                                name="photo"
                                                className="form-control form-control-sm"
                                                accept=".jpg, .jpeg, .png" type="file" />
                                        </div>
                                    </div>
                                    <ButtonGroup>
                                        <Button color="success">
                                            Сохранить
                                        </Button>
                                        <Button color="warning" onClick={() => navigate(`/admin/categories`)}>
                                            Отмена
                                        </Button>
                                    </ButtonGroup>
                                </form>
                            }
                        </div>
                        }
                    </div>
                }
            </div>
        </Container>
    )

}