import React, { useState } from 'react';
import { Person, BoxArrowLeft, List } from 'react-bootstrap-icons';
import {
    Collapse,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem, NavLink,
    ButtonGroup,
    Button,
    DropdownMenu,
    DropdownToggle,
    DropdownItem,
    UncontrolledDropdown
} from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import '../NavMenu.css';

function AdminNavMenu({ offcanvasIsOpen, setOffcanvasIsOpen, role }) {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const navigate = useNavigate();
    const toNav = (url) => {
        setIsOpen(false)
        navigate(url)
    }

    return (
        <header>
            <Navbar className="navbar-expand-lg navbar-toggleable-lg ng-white border-bottom box-shadow mb-3" light>

                <NavbarBrand onClick={() => {
                    navigate("/admin")
                    setIsOpen(false)
                }
                }
                ><img style={{ maxHeight: "55px" }} src='/ballon_logo.png' className='img-fluid' /> </NavbarBrand>
                <NavbarToggler className="mr-2" onClick={toggle} />
                <Collapse className="d-lg-inline-flex flex-lg-row-reverse" isOpen={isOpen} navbar>
                    <ul className="navbar-nav">
                        <NavItem>
                            <NavLink tag={Link} className="text-dark" onClick={() => {
                                setIsOpen(false)
                            }} to="/admin">Главная</NavLink>
                        </NavItem>
                        {(role == "Admins" || role == "Accountings" || role == "Managers") &&
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret>
                                    CRM
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => {
                                        toNav('catalog')
                                    }}>Создать заказ</DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={() => {
                                        // navigate("orders")
                                        toNav("orders")
                                    }}>Заказы</DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem disabled>Создать клиента</DropdownItem>
                                    <DropdownItem onClick={() => {
                                        setIsOpen(false)
                                        navigate("clients")
                                    }}>
                                        Клиенты
                                    </DropdownItem>
                                    {/* <DropdownItem disabled>Action</DropdownItem> */}
                                    {/* <DropdownItem>Another Action</DropdownItem> */}
                                    {/* <DropdownItem divider /> */}
                                </DropdownMenu>
                            </UncontrolledDropdown>}
                        {(role == "Admins" || role == "Buh") &&

                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret>
                                    Товары
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => toNav("products")}>
                                        Товаров
                                    </DropdownItem>
                                    <DropdownItem onClick={() => toNav("categories")}>
                                        Категории товаров
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={() => navigate("keywords")} >
                                        Ключевые слова
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        }
                        {/* <NavItem>
                            <NavLink tag={Link} className="text-dark" onClick={() => {
                                setIsOpen(false)
                            }} to='sessionevents'>Списки</NavLink>
                        </NavItem> */}

                        <NavItem className='d-flex ms-sm-3'>
                            <ButtonGroup className='flex-fill'>
                                {(role == "Admins") &&
                                    <Button size='sm' color='light' onClick={
                                        () => {
                                            setOffcanvasIsOpen(!offcanvasIsOpen)
                                            toggle()
                                        }}>
                                        <List className='me-1' size='14' />Меню
                                    </Button>
                                }
                                <Button color="info" size='sm' className='' onClick={() => toNav("/")}>
                                    <Person className='me-1' size='14' />Профиль
                                </Button>
                                <Button color="danger" size='sm' outline onClick={() => navigate("/logout")}>
                                    <BoxArrowLeft className='me-1' size='14' />Выход
                                </Button>
                            </ButtonGroup>
                        </NavItem>
                    </ul>
                </Collapse>
            </Navbar>
        </header >
    )
}
export { AdminNavMenu };