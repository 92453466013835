import { useParams } from "react-router-dom"
import { useAddShippingToOrderMutation, useGetOrderQuery, useGetShipingListQuery } from "../../../features/CallApi"
import { Card, CardBody, CardHeader, Container } from "reactstrap"
import { OrderStatus } from "./OrderStatus"
import { useEffect, useRef } from "react"
import LoadScreen from "../../UI/LoadScreen"
import { useForm } from "react-hook-form"
import { useState } from "react"
import { format } from "date-fns"
import { Button, ButtonGroup, Col, Overlay, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import { TypeOfPay } from "./OrderTypeOfPay"
import axios from "axios"


export const OrderDetails = ({ token }) => {
    const { id } = useParams()
    const { data, refetch, isLoading, isFetching } = useGetOrderQuery(id)
    // const [payOrder, { isLoading: isPaying }] = usePayOrderMutation()
    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm()
    const [summ, setSumm] = useState(0)
    const [showTooltipCopy, setShowTooltipCopy] = useState(true)
    const target = useRef(null);
    const { data: shipingList, refetch: refecthShipingList } = useGetShipingListQuery()
    const [addShippingToOrder, { isFetching: fetchAddShipping }] = useAddShippingToOrderMutation()

    useEffect(() => {
        refetch()
        refecthShipingList()
    }, [])

    useEffect(() => {
        if (data?.items) {
            const sum = data.items.reduce((summa, item) => {
                return summa + ((item.discount ? parseFloat(item.discount) : parseFloat(item.price)) * item.quantity);
            }, 0);
            setSumm(sum)
        }
    }, [data])

    // useEffect(() => {
    //     setValue("typeOfPay", data?.typeOfPay ? data.typeOfPay : null)
    // }, [data])

    // const pay = async (data) => {
    //     try {
    //         const response = await payOrder(data).unwrap()
    //         refetch()
    //     }
    //     catch (error) {
    //         alert("Ошибка закрытия заказа, повторте оплату")
    //         console.error(error)
    //     }
    // }

    const confirmOrder = async () => {
        console.log(token)
        await axios.get(`/api/orders/new-status/${id}?selStatus=7`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(() => {
            refetch()
        })
    }

    const complete = async () => {
        await axios.get(`/api/orders/new-status/${id}?selStatus=8`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(() => {
            refetch()
        })
    }

    const cancel = async () => {
        await axios.get(`/api/orders/new-status/${id}?selStatus=3`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(() => {
            refetch()
        })

    }

    const addShiping = (data) => {
        console.log(data)
        addShippingToOrder({ id, productId: data.shipingId, price: data.price }).then(
            (r) => {
                console.log(r)
                refetch()
            }
        )
    }

    return (
        <Container>
            {(!isLoading && !isFetching) ?
                <>
                    {data &&
                        <Card>
                            <CardHeader>
                                <h2>Заказ №{data.id}</h2>
                                <div className="d-flex">
                                    <div className="me-1">Покупатель:</div>
                                    <div style={{ fontWeight: "600" }}>{data.client.lastName} {data.client.firstName} {data.client.patronymic}</div>
                                </div>
                                <div className="d-flex">
                                    <div className="me-1">Статус:</div>
                                    <div><OrderStatus status={data.status} /></div>

                                </div>
                                {(data.status !== 3 && data.status !== 8 && data.status !== 1) &&

                                    <div className="d-flex mt-3 mb-3">
                                        <ButtonGroup size="sm">
                                            {data.status !== 0 &&
                                                <>
                                                    <Button variant="success" onClick={() => { if (window.confirm("Завершить заказ?")) { complete() } }}>Завершить</Button>
                                                    <Button variant="success" ref={target} onClick={
                                                        () => {
                                                            navigator.clipboard.writeText(`https://balloon.spb.ru/orders/details/${id}?tokenId=${data.tokenId}`)
                                                            // alert("Ссылка скопирована")
                                                        }}
                                                    >Ссылка на заказ</Button>
                                                </>
                                            }
                                            {data.status === 0 &&
                                                <Button variant="success" onClick={() => { confirmOrder() }}>Подтвердить</Button>
                                            }
                                            {data.status === 7 &&
                                                <>
                                                    <Button variant="success" onClick={() => { alert("Ссылка будет направлена на E-Mail") }}>Отправить на почту</Button>
                                                </>

                                            }
                                            <Button variant="warning" onClick={() => { if (window.confirm("Вы уверены, что хотите отменить заказ?")) { cancel() } }}>Отменить</Button>
                                        </ButtonGroup>
                                    </div>
                                }
                                <div className="d-flex">
                                    <div className="me-1">Менеджер:</div>
                                    {data.user ?
                                        <div style={{ fontWeight: "600" }}>{data.user?.lastName} {data.user?.firstName}</div>
                                        :
                                        <div>Не назначен</div>
                                    }
                                </div>
                                <div className="d-flex">
                                    <div className="me-1">Сумма заказа:</div>
                                    <div style={{ fontWeight: "600" }}>{summ} руб.</div>
                                </div>
                                <div className="d-flex">
                                    <div className="me-1">Способ оплаты:</div>
                                    <div style={{ fontWeight: "600" }}><TypeOfPay typeOfPay={data.typeOfPay} /> </div>
                                </div>
                                <div className="d-flex">
                                    <div className="me-1">Дата доставки: </div>
                                    <div style={{ fontWeight: "600" }}> {format(new Date(data.deliveryDate), 'dd.MM.yy HH:mm')}</div>
                                </div>
                                <div className="d-flex">
                                    <div className="me-1">Адрес доставки: </div>
                                    <div style={{ fontWeight: "600" }}> {data.addres}</div>
                                </div>
                                <div className="d-flex">
                                    <div className="me-1">Комментарий: </div>
                                    <div style={{ fontWeight: "600" }}> {data.comments}</div>
                                </div>
                            </CardHeader>
                            <CardBody>
                                {data.status === 0 &&
                                    <form onSubmit={handleSubmit(addShiping)}>
                                        <Row>
                                            <Col>
                                                <div className="form-floating">
                                                    <select className={`form-select ${errors.shipingId && "is-invalid"}`} defaultValue={""} {...register("shipingId", { required: { value: true } })} onChange={(e) => { }} id="status" aria-label="Услуга доставки">
                                                        {(shipingList?.length > 0) ?
                                                            <>
                                                                {shipingList.map(item =>
                                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                                )}
                                                            </> :
                                                            <option disabled>Нет доступных доставок</option>
                                                        }
                                                    </select>
                                                    <label htmlFor="typeDelivery">Услуга доставки</label>
                                                    {errors.shipingId && <span className="text-danger">Выберите услугу</span>}
                                                </div>
                                            </Col>
                                            <Col md={4} lg={3}>
                                                <div className="form-floating">
                                                    <input type="number" placeholder="Стоимость доставки ₽" className={`form-control ${errors.price && "is-invalid"}`} step={1} {...register("price", { required: { value: true, message: "Укажите стоимость доставки" }, min: { value: 1, message: "Минимум 1 ₽" } })} />
                                                    <label htmlFor="typeDelivery">Стоимость доставки ₽</label>
                                                    {errors.price && <span className="text-danger">{errors.price?.message}</span>}
                                                </div>
                                            </Col>
                                            <Col col={12} md={3}>
                                                <Button type="submit" disabled={fetchAddShipping} ref={target} className="w-100 mt-1" size="lg" variant="success">Добавить</Button>
                                            </Col>
                                        </Row>
                                    </form>
                                }
                                <div className="table-responsive">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col-1">#</th>
                                                <th scope="col">Продукт</th>
                                                <th scope="col">Цена</th>
                                                <th scope="col">Количество</th>
                                                <th scope="col">Сумма</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.items.map((item, index) =>
                                                <tr key={item.id}>
                                                    <td>
                                                        {index + 1}
                                                    </td>
                                                    <td style={{ verticalAlign: "middle" }}>
                                                        {item.product.name} {item.productVariationId && "(" + item.productVariation.name + ")"}
                                                    </td>
                                                    <td>
                                                        {item.discount > 0 ?
                                                            <>
                                                                <div><strike>{item.price} ₽</strike> {item.discount - item.price} ₽</div>
                                                                <div>{item.discount} ₽</div>
                                                            </> :
                                                            <>{item.price} ₽</>
                                                        }

                                                    </td>
                                                    <td>{item.quantity} шт.</td>
                                                    <td>
                                                        {item.discount > 0 ?
                                                            <>{(parseFloat(item.discount) * item.quantity)} ₽</>
                                                            :
                                                            <>{(parseFloat(item.price) * item.quantity)} ₽</>}
                                                    </td>
                                                </tr>
                                            )}
                                            <tr>
                                                <td colSpan={3}></td>
                                                <td>Сумма заказа:</td>
                                                <td>
                                                    <>
                                                        <strong>{summ}</strong> ₽
                                                    </>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </CardBody>
                        </Card>
                    }
                </>
                : <LoadScreen />}
        </Container>
    )
}