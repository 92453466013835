import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const callApi = createApi({
    reducerPath: 'callApi',
    baseQuery: fetchBaseQuery({
        baseUrl: 'api',
        prepareHeaders: (headers, { getState }) => {
            const token = getState().token.value;
            if (token) {
                headers.set("Authorization", `Bearer ${token.Token}`);
            }
            return headers;
        },
    }),
    keepUnusedDataFor: 0,
    tagTypes: ['User', 'Product', 'Category', 'SearchKeyword'],
    endpoints: (builder) => ({
        refreshToken: builder.mutation({
            query: (refTokrn) => ({
                url: '/api/login/refreshtoken',
                method: `POST`,
                body: refTokrn
            })
        }),
        checkToken: builder.query({
            query: () => 'users/checktoken'
        }),
        getUsers: builder.query({
            query: ({ pageIndex, pageSize, stringFilter }) => `users/getusers?pageIndex=${pageIndex}&pageSize=${pageSize}&stringFilter=${stringFilter}`,

            // query: ({pageIndex, pageSize, stringFilter}) => `users/getusers?pageIndex=${pageIndex}&pageSize=${pageSize}&stringFilter=${stringFilter}`,
        }),
        addUser: builder.query({
            query: () => ({
                url: 'adduser',
                method: 'POST',
                body: {},
            })
        }),
        getUserById: builder.query({
            query: (user_id) => `users/${user_id}`,
        }),
        getClientsByPhone: builder.mutation({
            query: (phone) => `clients/phone?phone=${phone}`,
        }),
        putUserById: builder.mutation({
            query: ({ id, ...form }) => ({
                url: `users/${id}`,
                method: 'PUT',
                body: form,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
                providesTags: ['User']
            }),

        }),
        chengePasswordUser: builder.mutation({
            query: (data) => (
                {
                    url: `users/chengePassword`,
                    method: `PUT`,
                    body: data,
                })
        }),
        getOrders: builder.query({
            query: ({ pageIndex, pageSize, stringFilter, startDate, endDate, selStatus }) => `orders/?startDate=${startDate}&endDate=${endDate}&pageIndex=${pageIndex}&pageSize=${pageSize}&stringFilter=${stringFilter}&selStatus=${selStatus}`
        }),
        getOrder: builder.query({ query: (id) => `orders/${id}` }),
        getClientOrder: builder.query({ query: ({ id, tokenId }) => `orders/client-order/${id}?tokenId=${tokenId}` }),
        payOrder: builder.mutation({
            query: ({ ...body }) => ({
                url: `orders/payorder/${body.id}?typeOfPay=${body.typeOfPay}`,
                method: `GET`,
            })
        }),
        cancelOrder: builder.mutation({
            query: (id) => ({
                url: `orders/${id}/cancel`,
                method: `GET`,
            })
        }),
        getCategories: builder.query({ query: ({ pageIndex, pageSize, stringFilter }) => `categories/?${pageIndex && "&pageIndex=" + pageIndex}${pageSize && "&pageSize=" + pageSize}${stringFilter ? "&stringFilter=" + stringFilter : ""}` }),
        getCategory: builder.query({ query: (id) => (`categories/${id}`), providesTags: 'Category' }),
        getCategoriesCarusel: builder.query({ query: (id) => `categories/categories-carusel` }),

        putCategory: builder.mutation({
            query: ({ id, ...data }) => ({
                url: `categories/${id}`,
                method: 'PUT',
                body: data,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
        }),
        postCategoryImage: builder.mutation(
            {
                query: ({ id, ...form }) => ({
                    url: `categories/image/${id}`,
                    method: `POST`,
                    body: form,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    }
                }),
                invalidatesTags: 'Category'
            }
        ),
        deleteCategoryImage: builder.mutation(
            {
                query: (id) => ({
                    url: `categories/deleteimage/${id}`,
                    method: `PUT`
                })
            }
        ),
        getCategoriesCatalog: builder.query({ query: () => `categories/categories-catalog` }),
        getCategoriesSelect: builder.query({ query: () => `categories/categories-select` }),
        getAgents: builder.query({ query: () => `agents` }),
        getAgent: builder.query({ query: (id) => `agents/${id}` }),
        getProducts: builder.query({
            query: ({ pageIndex, pageSize, stringFilter, selCat }) => `products/?pageIndex=${pageIndex}&pageSize=${pageSize}&stringFilter=${stringFilter}${selCat ? `&selCatId=` + selCat : ""}`
        }),
        getProductsCatalog: builder.query({
            query: ({ pageIndex, pageSize, stringFilter, selCat, selPromo, selHit }) => `products/get-catalog-produts/?${pageIndex ? "pageIndex=" + pageIndex : ""}${pageSize ? "&pageSize=" + pageSize : ""}${stringFilter ? "&stringFilter=" + stringFilter : ""}${selCat ? `&selCatId=` + selCat : ""}${selPromo ? `&selPromo=` + selPromo : ""}${selHit ? `&selHit=` + selHit : ""}`
        }),
        getShipingList: builder.query({ query: () => `products/shiping-list` }),
        getProductParams: builder.query({ query: () => `products/product-prams` }),
        addProduct: builder.mutation(
            {
                query: ({ ...form }) => ({
                    url: `products`,
                    method: `POST`,
                    body: form,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    }
                })
            }
        ),
        addVariation: builder.mutation({
            query: ({ ...data }) => ({
                url: `products/add-variation`,
                method: `post`,
                body: data,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            }),
            invalidatesTags: 'Product'
        }),
        putVariation: builder.mutation({
            query: ({ ...data }) => ({
                url: `products/put-variation`,
                method: 'put',
                body: data,
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            }),
            invalidatesTags: 'Product'
        }),
        deleteVariation: builder.mutation({
            query: (id) => ({
                url: `products/delete-variation/${id}`
            }),
            invalidatesTags: 'Product'
        }),
        getProduct: builder.query({ query: (id) => `products/${id}`, providesTags: 'Product' }),
        getProductCard: builder.query({ query: (id) => `products/get-product-card/${id}` }),
        putProduct: builder.mutation(
            {
                query: ({ ...form }) => ({
                    url: `products/${form.id}`,
                    method: `PUT`,
                    body: form,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    }
                })
            }
        ),
        postProductPhoto: builder.mutation(
            {
                query: ({ id, ...form }) => ({
                    url: `products/photo/${id}`,
                    method: `POST`,
                    body: form,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    }
                })
            }
        ),
        deleteProductPhoto: builder.mutation(
            {
                query: (id) => ({
                    url: `products/deletephoto/${id}`,
                    method: `PUT`
                })
            }
        ),
        getCatalogProducts: builder.query({ query: (id) => `products/catalog${id ? "?selCatId=" + id : ""}` }),
        createOrder: builder.mutation({
            query: (body) => ({
                url: `orders`,
                method: `POST`,
                body: body
            })
        }),
        getAgreementOrder: builder.mutation({
            query: (id) => ({
                url: `orders/agreement/${id}`,
                method: `GET`,
            })
        }),
        getClients: builder.query({ query: ({ pageIndex, pageSize, stringFilter }) => `clients/?pageIndex=${pageIndex}&pageSize=${pageSize}&stringFilter=${stringFilter}` }),
        getClientById: builder.query({ query: (id) => `clients/${id}` }),
        putClient: builder.mutation({
            query: ({ ...body }) => ({
                url: `clients/${body.id}`,
                method: "PUT",
                body: body
            })
        }),
        createRentRequest: builder.mutation({
            query: ({ ...body }) => ({
                url: `requests/rent`,
                method: `POST`,
                body: body,
            })
        }),
        addShippingToOrder: builder.mutation({
            query: ({ id, productId, price }) => ({
                url: `orders/add-shiping/${id}?productId=${productId}&price=${price}`,
                method: `GET`
            })
        }),
        deleteCategory: builder.mutation({
            query: (data) => (
                {
                    url: `products/deletecategory`,
                    method: 'POST',
                    body: data
                })
        }),
        addCategory: builder.mutation({
            query: (data) => (
                {
                    url: `products/addcategory`,
                    method: 'POST',
                    body: data
                })
        }),
        getKeywords: builder.query({ query: ({ pageIndex, pageSize, stringFilter }) => `searchkeywords/?${pageIndex && `pageIndex=${pageIndex}`}${pageSize && `&pageSize=${pageSize}`}${stringFilter && `&stringFilter=${stringFilter}`}`, providesTags: 'SearchKeyword' | 'Product' }),
        getKeyword: builder.query({ query: (id) => `searchkeywords/${id}`, providesTags: 'SearchKeyword' | 'Product' }),
        addKeyword: builder.mutation({
            query: (data) => ({
                url: `searchkeywords`,
                method: 'POST',
                body: data
            }), invalidatesTags: 'SearchKeyword' | 'Product'
        }),
        putKeyword: builder.mutation({
            query: (data) => ({
                url: `searchkeywords`,
                method: 'PUT',
                body: data
            }), invalidatesTags: 'SearchKeyword' | 'Product'
        }),
        deleteKeyword: builder.mutation({
            query: (id) => ({
                url: `searchkeywords/${id}`,
                method: 'DELETE',
            }), invalidatesTags: 'SearchKeyword' | 'Product'
        }),
        addKeywordToProduct: builder.mutation({
            query: (data) => ({
                url: 'searchkeywords/add-keyword-to-product',
                method: 'POST',
                body: data
            }), invalidatesTags: 'SearchKeyword' | 'Product'
        }),
        removeKeywordToProduct: builder.mutation({
            query: (data) => ({
                url: 'searchkeywords/remove-keyword-to-product',
                method: 'POST',
                body: data
            }), invalidatesTags: 'SearchKeyword' | 'Product'
        }),
        callBackSendForm: builder.mutation({
            query: (data) => (
                {
                    url: 'clients/callback',
                    method: 'POST',
                    body: data
                }
            )
        })
    }),

}

)

export const {
    useRefreshTokenMutation,
    useGetUsersQuery,
    useCheckTokenQuery,
    useGetUserByIdQuery,
    usePutUserByIdMutation,
    useChengePasswordUserMutation,
    useGetOrdersQuery,
    useGetOrderQuery,
    useGetClientOrderQuery,
    useGetCategoriesQuery,
    useGetCategoryQuery,
    usePutCategoryMutation,
    useGetAgentsQuery,
    useGetAgentQuery,
    useGetProductsQuery,
    useGetProductParamsQuery,
    useAddProductMutation,
    useGetProductQuery,
    usePutProductMutation,
    usePostProductPhotoMutation,
    useDeleteProductPhotoMutation,
    useGetCatalogProductsQuery,
    useGetClientsByPhoneMutation,
    useCreateOrderMutation,
    useGetAgreementOrderMutation,
    useCancelOrderMutation,
    usePayOrderMutation,
    useGetClientsQuery,
    useGetClientByIdQuery,
    usePutClientMutation,
    useCreateRentRequestMutation,
    useGetCategoriesCatalogQuery,
    useGetProductsCatalogQuery,
    useGetProductCardQuery,
    useGetCategoriesSelectQuery,
    useAddVariationMutation,
    useGetShipingListQuery,
    useAddShippingToOrderMutation,
    useDeleteCategoryImageMutation,
    usePostCategoryImageMutation,
    useGetCategoriesCaruselQuery,
    usePutVariationMutation,
    useDeleteVariationMutation,
    useAddCategoryMutation,
    useDeleteCategoryMutation,
    useGetKeywordsQuery,
    useGetKeywordQuery,
    useAddKeywordMutation,
    usePutKeywordMutation,
    useDeleteKeywordMutation,
    useAddKeywordToProductMutation,
    useRemoveKeywordToProductMutation,
    useCallBackSendFormMutation,
} = callApi