import { ArrowRepeat, Cart, Cart2, TrashFill } from "react-bootstrap-icons"
import { useEffect, useState } from "react"
import { Button, ButtonGroup, Image, Offcanvas } from "react-bootstrap"
import LoadScreen from "../UI/LoadScreen"
import { useNavigate } from "react-router-dom"



export const Basket = ({ basket, updateBasket, updateItem, removeItem, resetBasket, addItemStatus, showBasket, setShowBasket }) => {

    const [summ, setSumm] = useState(0)
    const [summQuantity, setSummQuantity] = useState()
    const [basketBtnShow, setBasketBtnShow] = useState()
    const [summError, setSummError] = useState();
    const navigate = useNavigate()


    useEffect(() => {
        // console.log(addItemStatus)
        if (basket) {
            setBasketBtnShow(true)
            const sum = basket.reduce((summa, item) => {
                return summa + ((item.discount ? parseFloat(item.discount) : parseFloat(item.price)) * item.quantity);
            }, 0);
            setSumm(sum)
            const quantityBasket = basket.reduce((summQuantity, item) => {
                return summQuantity + item.quantity
            }, 0)
            setSummQuantity(quantityBasket)
            if (sum >= 1500) {
                setSummError(null)
            }
        }
    }, [basket, updateBasket])

    return (
        <>
            {basketBtnShow &&
                <>
                    {basket.length > 0 &&
                        <div className="basketBB-button p-3 shadow d-none d-lg-block" onClick={() => { setShowBasket(true) }}>
                            {addItemStatus &&
                                <div className="tooltip-BB">
                                    <div role="tooltip" x-placement="left"
                                        className="fade show tooltip bs-tooltip-start shadow" id="overlay-example" data-popper-reference-hidden="false"
                                        data-popper-escaped="true"
                                        data-popper-placement="left"
                                        style={{ position: "absolute", inset: "0px 0px auto auto", transform: "translate3d(-67px, 15px, 0px)" }}>
                                        {/* <div className="tooltip-arrow" style={{ position: "absolute", top: "0", transform: "translate3d(0px, 10px, 0px)", background: "c" }}>
                                        </div> */}
                                        <div className="tooltip-inner" style={{ width: "150px", background: "#198754" }}>Позиция добавлена</div>
                                    </div>
                                </div>
                            }
                            <div style={{ position: 'relative' }}>
                                <Cart2 size={30} />
                                <div className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{summQuantity}</div>
                            </div>
                        </div>
                    }
                </>
            }
            <Offcanvas show={showBasket} placement={"end"} onHide={() => {
                setShowBasket(!showBasket)
            }}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Корзина</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {updateBasket ? <LoadScreen /> :
                        <div>
                            <div>
                                {basket &&
                                    <div>{basket.length > 0 ? <>
                                        {basket.map(product =>
                                            <div className="border-bottom mb-3 p-2 pb-3 show-bg" key={product.name} >
                                                <div className="row g-1">


                                                    <div className="col">
                                                        <div className="d-flex">
                                                            <div className="flex-grow-1">
                                                                <strong>{product.name}</strong>
                                                            </div>
                                                            {product.sn &&
                                                                <div className="ms-3">
                                                                    С/Н: <strong>{product.sn}</strong>
                                                                </div>
                                                            }
                                                            <div>
                                                                <Button className="p-1 rounded-circle" style={{ width: "32px", height: "32px" }} data-id={product.id} data-sn={product.sn} data-vr={product.variationsId} onClick={(e) => { removeItem(e) }} variant="outline-danger"><TrashFill size={10} /></Button>
                                                            </div>
                                                        </div>
                                                        <div className="row g-1 mt-1">
                                                            <div className="col-2">
                                                                <Image style={{ maxWidth: "50px" }} src={product.photo ? `/api/products/photo/${product.photo}` : `/NoPhoto.png`} rounded fluid />
                                                            </div>
                                                            <div className="col-4 align-self-center text-end text-nowrap">
                                                                {product.discount > 0 ?
                                                                    <div >

                                                                        <span style={{ position: "relative" }}>
                                                                            <span className="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-danger">
                                                                                {((product.discount / product.price) * 100 - 100)} %
                                                                            </span>
                                                                            <strike>{product.price} ₽</strike> = </span>
                                                                        <span style={{ color: "red" }}>{product.discount} ₽</span>
                                                                        {/* <div><span className="translate-middle badge rounded-pill bg-danger">{(product.discount - product.price)}</span></div> */}
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        {product.price} ₽
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="col-1 align-self-center text-end text-nowrap">X</div>
                                                            <div className="col align-self-center">
                                                                {product.sn === null || product.sn === "" ?
                                                                    <div className="input-group input-group-sm">
                                                                        <input style={{ maxWidth: "60px" }} type="number" min={0} className="form-control form-control-sm text-center"
                                                                            onChange={(e) => {

                                                                                e.target.closest("div").querySelector("button").classList.remove("d-none")
                                                                                e.target.closest("div").querySelector("button").classList.add("btn-warning")
                                                                                e.target.closest("div").querySelector(`.qBadge`).classList.add("d-none")
                                                                            }}
                                                                            defaultValue={product.quantity} />
                                                                        <span className="input-group-text qBadge" id={`${product.name}`}>шт.</span>
                                                                        <Button data-id={product.id} data-vr={product.variationsId} onClick={(e) => { updateItem(e) }} className="d-none" color="warning"><ArrowRepeat size={18} /></Button>
                                                                    </div>
                                                                    :
                                                                    <div className="input-group input-group-sm">
                                                                        <input style={{ maxWidth: "60px" }} type="number" className="form-control form-control-sm text-center" disabled value={product.quantity} />
                                                                        <span className="input-group-text" id="basic-addon2">шт.</span>
                                                                    </div>

                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-1">
                                                    </div> */}
                                                </div>
                                            </div>
                                        )}</>
                                        : <div className="text-center mt-3 mb-3">Корзина пустая</div>
                                    }</div>
                                }
                            </div>
                            <div className="mb-3 d-flex">
                                <div className="me-3">Сумма заказа:</div>

                                <div>
                                    {summ} ₽
                                </div>
                            </div>
                            <div>
                                <Button onClick={() => {
                                    if (summ >= 1500) {
                                        setShowBasket(false)
                                        navigate("/client/create-order")
                                    }
                                    else {
                                        setSummError("Мнинимальная сумма заказа 1500 ₽")
                                    }
                                }} className="w-100" variant="success">
                                    Оформить заказ
                                </Button>
                                <div className="mt-3 mb-3">
                                    {summError && <span className='text-danger'>{summError}</span>}
                                </div>
                            </div>
                        </div>
                    }
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}