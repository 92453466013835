import { useRef, useState } from "react"
import { Button, Card, Form } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { useCallBackSendFormMutation } from "../../features/CallApi"
import LoadScreen from "./LoadScreen"
import { Check2All } from "react-bootstrap-icons"



export const ContactForm = () => {

    const [checked, setChecked] = useState(false)
    const refSwitch = useRef()
    const { register, handleSubmit, formState: { errors } } = useForm()
    const [callBackSendForm, { isLoading }] = useCallBackSendFormMutation()
    const [sended, setSended] = useState(false)

    const send = (data) => {
        // console.log(data)
        callBackSendForm(data).then(() => {
            setSended(true)
        }).catch(() => {
            window.alert("Ошибка отправки заявки")
        })
    }

    return (
        <>
            <div style={{ maxWidth: "480px" }} className="m-auto mt-3 mb-3">

                <Card>
                    <Card.Body>
                        {!sended ?

                            <>
                                {!isLoading ? <>
                                    <p className="fs-4 text-center">Не смогли найти подходящий вариант?</p>
                                    <p className="text-center">Оставьте заявку, и поможем подобрать вам композицию</p>

                                    <Form onSubmit={handleSubmit(send)}>
                                        <Form.Floating className="mb-3">
                                            <Form.Control
                                                id="floatingInputCustom"
                                                type="text"
                                                placeholder="Имя"
                                                {...register("name", { required: { value: true, message: "Как к вам обращаться?" } })}
                                            />
                                            <label htmlFor="floatingInputCustom">Имя <span className="text-warning">*</span></label>
                                            {errors.name && <span className='text-danger'>{errors.name?.message}</span>}                            </Form.Floating>
                                        {/* {errors.name && <Form.Control.Feedback type="invalid">{errors.name.message}</Form.Control.Feedback>} */}

                                        <Form.Floating className="mb-3">
                                            <Form.Control
                                                id="floatingPhonedCustom"
                                                type="phone"
                                                placeholder="Телефон"
                                                {...register("phone", { required: { value: true, message: "Как с вами связаться?" } })}
                                            />
                                            <label htmlFor="floatingPhonedCustom">Телефон <span className="text-warning">*</span></label>
                                            {errors.phone && <span className='text-danger'>{errors.phone?.message}</span>}
                                        </Form.Floating>
                                        <Form.Check // prettier-ignore
                                            ref={refSwitch}
                                            type="switch"
                                            id="custom-switch"
                                            label="Согласие на обработку персональных данных"
                                            className="mb-3"
                                            onChange={() => { setChecked(refSwitch.current.checked) }}
                                        />
                                        <Button
                                            disabled={!checked}
                                            className="w-100 btn-balloon" type="submit">Отправить</Button>
                                    </Form>
                                </> : <LoadScreen />}
                            </>
                            : <>
                                <div className="text-center">
                                    <div><Check2All size={60} fill={"#006800"} /></div>
                                    <h3>Спасибо за Ваше обращение!</h3>
                                    <p className="fs-5">В ближайшее время мы свяжемся с Вами.</p>
                                    <p>Запросы обрабатываются с 10:00 - 22:00</p>
                                </div>
                            </>
                        }
                    </Card.Body>
                </Card>
            </div>
        </>
    )
}