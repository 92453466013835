import { useState } from "react"
import { Button, ButtonGroup, FloatingLabel, Form, Modal } from "react-bootstrap"
import { useForm } from "react-hook-form";
import { useAddKeywordMutation } from "../../features/CallApi";


export const AddKeyword = ({ refetch }) => {

    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [addKeyword, { isLoading, isError }] = useAddKeywordMutation()
    const [show, setShow] = useState(false)
    const handeClose = () => {
        reset()
        setShow(false)
        // refecth()
    }
    const submit = (data) => {
        console.log(data)
        addKeyword(data)
            .then(() => {
                handeClose()
                refetch()
            })
            .catch((e) => {
                console.error(e)
            })
    }

    return (
        <>
            <Button variant="success" size="sm" className="mb-3" onClick={() => { setShow(!show) }}>Добавить</Button>
            <Modal show={show} onHide={() => { handeClose() }}>
                <Modal.Header closeButton>Добавление ключевого слова</Modal.Header>
                <Form onSubmit={handleSubmit(submit)}>
                    <Modal.Body>
                        <FloatingLabel
                            controlId=""
                            label="Ключевое слово"
                            className=""
                        >
                            <Form.Control type="text" placeholder="Ключевое слово" className={`${errors.keyword && "is-invalid"}`}
                                {...register("keyword", { required: { value: true, message: 'Укажите ключевое слово' } })}
                            />
                            <Form.Control.Feedback type="invalid">{errors?.keyword?.message}</Form.Control.Feedback>
                        </FloatingLabel>
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonGroup size="sm">
                            <Button variant="warning" onClick={() => { handeClose() }}>Закрыть</Button>
                            <Button variant="success" type='submit'>Добавить</Button>
                        </ButtonGroup>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}