import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, ButtonGroup } from 'reactstrap';
import { useForm } from "react-hook-form"
import axios from 'axios';
import LoadScreen from '../../UI/LoadScreen';


function FormAddUser({ refeach, token }) {

    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    // const { isLoading, data: roleList } = useGetUserRoleQuery();
    const [modal, setModal] = useState(false);

    const toggle = () => {
        setModal(!modal);
    }

    const addUser = async (data) => {
        console.log(data)
        const response = await axios.post('/api/users/adduser', data, {
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                Authorization: "Bearer " + token
            }
        })
        console.log(response)

        if (response.status >= 200 || response.status <= 204) {
            reset();
            toggle();
            refeach();
        }
    }
    return (
        <div>
            <Button className='mb-2' color="success" size="sm" onClick={toggle}>
                Создать пользователя
            </Button>
            <Modal isOpen={modal} toggle={toggle} >
                <ModalHeader toggle={toggle}>Создать пользователя</ModalHeader>
                <ModalBody>
                    <form className="" onSubmit={handleSubmit(addUser)}>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">E-Mail</label>
                            <input id="email" name="email" placeholder="Укажите E-Mail" type="string" className="form-control"
                                {...register("email", {
                                    required: { value: true, message: "Поле \"E-Mail\" не может быть пустым" },
                                    pattern: { value: /^\S+@\S+$/i, message: "Некорректно указан E-Mail" }
                                })} />
                            {errors.email && <span className='text-danger'>{errors.email?.message}</span>}
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="mb-3">
                                    <label htmlFor="firstName" className="form-label">Имя</label>
                                    <input id="firstname" name="firstname" placeholder="Укажите имя" type="text" className="form-control"
                                        {...register("firstname", { required: { value: true, message: "Поле \"Имя\" не может быть пустым" } })} />
                                    {errors.firstname && <span className='text-danger'>{errors.firstname?.message}</span>}
                                </div>
                            </div>
                            <div className="col">
                                <div className="mb-3">
                                    <label htmlFor="lastname" className="form-label">Фамилия</label>
                                    <input id="lastname" name="lastname" placeholder="Укажите фамилию" type="text" className="form-control"
                                        {...register("lastname", { required: { value: true, message: "Поле \"Фамилиия\" не может быть пустым" } })} />
                                    {errors.lastname && <span className='text-danger'>{errors.lastname?.message}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="role" className="form-label">Роль</label>
                            <select id="role" name="role" defaultValue={null} className="form-select"
                                {...register("role", { required: { value: true, message: "Выберите роль пользователя" } })} >
                                <option value={0}>Администратор системы</option>
                                <option value={1}>Бухгалтер</option>
                                <option value={2}>Менеджер</option>
                            </select>
                            {errors.roleId && <span className='text-danger'>{errors.roleId?.message}</span>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">Пароль</label>
                            <input id="password" name="password" placeholder="Укажите Пароль" type="password" className="form-control"
                                {...register("password", {
                                    required: { value: true, message: "Поле \"Пароль\" не может быть пустым" },
                                    minLength: { value: 8, message: "Длина пароля должна быть не менее 8 символов" }
                                })} autoComplete="off" />
                            {errors.password && <span className='text-danger'>{errors.password?.message}</span>}
                        </div>
                        <div className="mb-2 form-check form-switch">
                            <label className="form-check-label">Активен</label>
                            <input type="checkbox" className="form-check-input" {...register("status", {})} />
                        </div>
                        <div className="mb-3">
                            <div role="group" className="btn-group-sm btn-group">
                                <input className="btn btn-success btn btn-secondary" type="submit" value={'Добавить'} />
                            </div>
                        </div>
                    </form>

                </ModalBody>
                <ModalFooter>
                    <ButtonGroup>
                        <Button color="success" onClick={addUser}>
                            Добавить
                        </Button>
                        <Button color="warning" onClick={toggle}>
                            Отмена
                        </Button>
                    </ButtonGroup>
                </ModalFooter>
            </Modal>
        </div>

    );
}
export { FormAddUser };