import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Row, Col, CardBody } from 'reactstrap';
import Axios from "axios";
import LoadScreen from "../../UI/LoadScreen";

const UserLogin = ({ setTokenData }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [isDataLoading, setIsDataLoading] = useState(false)
    const navigate = useNavigate();
    const [storeToken, setStroreToken] = useState();

    const onSubmit = async (data) => {
        setIsDataLoading(true)
        const postLogin = async (userData) => {
            const response = await Axios.get('api/login/basic-token')
            const tResponse = await Axios.post('/api/login/userlogin', userData
                , {
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8',
                        Authorization: "Bearer " + response.data.token,
                    }
                }).catch((error) => {
                    console.log(error)
                    setIsDataLoading(false)
                    alert('Ошибка авторизации')
                }
                )
            return tResponse.data;
        }
        const token = await postLogin(data)
        setTokenData(token)
        if (token.ExpiresIn < Date) {
            const returnUrl = sessionStorage.getItem("returnUrl")
            if (returnUrl) {
                setTimeout(() => {
                    window.location.pathname = returnUrl;
                    setIsDataLoading(false)
                }, 2000)
            }
            else {
                window.location.pathname = "/admin"
                setIsDataLoading(false)
            }
        }
    };

    useEffect(() => {
        setIsDataLoading(true)
        if (storeToken) {
            const token = JSON.parse(storeToken);
            console.log(token)
            if (token) {
                if (token?.ExpiresIn < Date) {
                    const returnUrl = sessionStorage.getItem("returnUrl")
                    if (returnUrl) {
                        setTimeout(() => {
                            navigate(returnUrl)
                            // window.location.pathname = returnUrl;
                            setIsDataLoading(false)
                        }, 2000)
                    }
                    else {
                        navigate(returnUrl)
                        // window.location.pathname = "/admin"
                        setIsDataLoading(false)
                    }
                }
            }
        }
        setIsDataLoading(false)
    }, [storeToken])
    useEffect(() => {
        setStroreToken(sessionStorage.getItem("token"))
    }, []
    )
    // console.log(errors);


    return (
        <div className="container" style={{minHeight: "78vh"}}>
            {isDataLoading ? <LoadScreen className="m-5"></LoadScreen> :
                <CardBody className="py-5 px-md-5">
                    <div className="text-center">
                        <img src="5-2_generated.png" alt="баблс.рф" className="img-fluid mb-3" style={{ maxHeight: "75px" }} />
                    </div>
                    <Row className="d-flex justify-content-center">
                        <Col md="6">
                            <form className="form-group" onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-floating mb-2">
                                    <input type="string" className='form-control' name="email" id='email' placeholder='mail@leningradcenter.ru' {...register("email", { required: { value: true, message: "Поле \"E-Mail\" не может быть пустым" }, pattern: { value: /^\S+@\S+$/i, message: "Некорректно указан E-Mail" } })} />
                                    <label htmlFor='email'>E-Mail</label>
                                    {errors.email && <span className='text-danger'>{errors.email?.message}</span>}
                                </div>
                                <div className="form-floating mb-2">
                                    <input type="password" className='form-control' placeholder="Пароль" id='password' autoComplete="off" {...register("password", { required: { value: true, message: "Поле \"Пароль\" не может быть пустым" } })} />
                                    <label htmlFor="password">Пароль</label>
                                    {errors.password && <span className='text-danger'>{errors.password?.message}</span>}
                                </div>
                                <div className="form-check form-switch mb-2">
                                    <label className="form-check-label">Запомнить меня на этом устройстве</label>
                                    <input type="checkbox" className="form-check-input" {...register("rememberMe", {})} />
                                </div>
                                <input className="btn btn-primary btn-block mt-3 w-100" type="submit" value={'Войти'} />
                            </form>
                        </Col>
                    </Row>
                </CardBody>
            }
        </div>
    );
}
export { UserLogin }