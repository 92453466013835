export const OrderStatus = ({ status }) => {
    let result;
    switch (status) {
        case 0: result = <span className="text-info">Новый заказ</span>;
        break;
        case 1: result = <span className="text-warning">Ожидает оплаты</span>;
        break;
        case 2: result = <span className="text-success">Оплачено</span>;
        break;
        case 3: result = <span className="text-warning">Отменен</span>;
        break;
        case 4: result = <span className="text-warning">Отменен банком</span>;
        break;
        case 5: result = <span className="text-warning">Возврат в очереди</span>;
        break;
        case 6: result = <span className="text-danger">Возврат выполнен</span>;
        break;
        case 7: result = <span className="text-warning">Подтвержден</span>;
        break;
        case 8: result = <span className="text-success">Завершен</span>;
        break;
        default: result = <span>Некорректные данные</span>
    }
    return result;
}