import { Container } from "react-bootstrap"


export const About = () => {
    return (
        <Container>
            <div>
                <p className="fs-4">
                    Мы команда «Баблс», доставляем воздушные шары на любое мероприятие!
                </p>
                <p>
                    Выбирайте и заказывайте воздушные шары с доставкой на нашем сайте или через социальные сети,а если не найдёте нужного, мастер составит индивидуально для Вас любую стильную композицию, исходя из пожеланий.  В ассортименте магазина представлены воздушные шары, товары для праздника,подарки и цветы. Используем только безопасные и качественные материалы, в том числе латексные биоразлагаемые шары.
                    Каждый шарик обрабатываем  средством Hi-float для увеличения продолжительности полета. Наша доставка быстрая, надежная и удобная. Вы можете выбрать интервал доставки в утреннее,дневное и вечернее время. По договоренности возим заказы ночью. Мы всегда рады ответить на ваши вопросы и помочь выбрать лучшее оформление для Вашего праздника.
                </p>
            </div>
        </Container>
    )

}